import React, { useEffect } from "react";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";


const Contactus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cardHeight = "270px"; // Height for cards
  const mapHeight = "560px"; // Full height for map

  return (
    <div>
      <LandingHeader />
      <div data-bs-spy="scroll" className="scrollspy-example pt-10 mt-10">
        <section
          id="landingContact"
          className="section bg-body pb-0 landing-contact"
        >
          <div className="container-xxl bg-icon-left position-relative pb-0">
            <h6 className="text-center d-flex justify-content-center align-items-center">
              <span className="fs-3 mb-6">Contact Us</span>
            </h6>
            <div className="row mb-12">
              {/* Left Side */}
              <div className="col-12 col-md-6 d-flex flex-column">
                {/* Address Card */}
                <div
                  className="card shadow-md d-flex justify-content-center align-items-center rounded-lg mb-5"
                  style={{ height: cardHeight }}
                >
                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <i className="ri-map-pin-2-fill text-danger fs-2"></i>
                    <h3 className="fw-bold mb-0 fun-facts-text mb-2">
                      Address
                    </h3>
                    <p className="mb-0 text-body text-center">
                      Shekru labs India Office no 6, 2nd Floor Manogat Appt Near
                      Muktangan, Sahakar Nagar, Pune Maharashtra-411037
                    </p>
                  </div>
                </div>

                {/* Email Card */}
                <div
                  className="card shadow-md d-flex justify-content-center align-items-center rounded-lg mb-5"
                  style={{ height: cardHeight }}
                >
                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <i className="ri-mail-fill text-danger fs-2"></i>
                    <h3 className="fw-bold mb-0 fun-facts-text mb-2">Email</h3>
                    <p className="mb-0 text-body text-center">
                      shekrulabs@gmail.com
                    </p>
                  </div>
                </div>
              </div>
              {/* Right Side */}
              <div className="col-12 col-md-6">
                <div className="card mb-6" style={{ height: mapHeight }}>
                  <div
                    className="card-body p-0 d-flex justify-content-center align-items-center "
                    style={{ height: "100%" }}
                  >
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.8688660750863!2d73.85137567491716!3d18.489598170120143!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1982877f07b%3A0x1627e42ae3e8b0c2!2sShekru%20labs%20India%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1722611764680!5m2!1sen!2sin"
                      className="w-100 h-100 "
                      style={{ borderRadius: "10px" }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <LandingFooter />
    </div>
  );
};

export default Contactus;
