import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import axios from "axios";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import { Toast } from "primereact/toast";
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";

const AdminHolding = () => {
  const [data, setData] = useState([]);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const fetchData = async () => {
    const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId || !authToken) {
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/get_all_holdings`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data.st === 1 && response.data.data) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId || !authToken) {
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/get_all_holdings`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        const errorMsg = response.data.msg || "Success";
        setData(response.data.data); // Assuming response.data.data is an array to set in DataTable
  
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && response.data.st === 2) {
        const errorMsg = response.data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    }catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  
  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  return (
    <>
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y">
        <Toast ref={toast} />
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-secondary">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Holding
            </li>
          </ol>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button
              onClick={handleBack}
              className="btn rounded-pill btn-outline-secondary btn-xs"
            >
              <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
            </button>

            <h5 className="mb-0 mx-auto">Holding</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
           
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <i className="custom-target-icon ri-loader-2-line ri-lg ms-3 p-text-secondary mt-3"></i>
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"
                
                ></i>
              </div>
            )}
          </div>
          <DataTable
          
              className="text-center border border-1"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column
              align="center"
          className="border border-1"
              field="tradingsymbol"
              header="Symbols"
            ></Column>
            <Column
              align="center"
               className="border border-1"
              field="quantity"
              header="Quantity"
            ></Column>
            <Column
              align="center"
              className="border border-1"
                            field="exchange"
              header="Exchange"
            ></Column>
            <Column
              align="center"
               className="border border-1"
              field="ltp"
              header="LTP"
            ></Column>
            <Column
              align="center"
              className="border border-1"
              field="averageprice"
              header="Average Price"
            ></Column>
            <Column
              align="center"
               className="border border-1"
              field="profitandloss"
              header="Profit and Loss"
            ></Column>
            <Column
              align="center"
               className="border border-1"
              field="pnlpercentage"
              header="P&L Percentage"
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default AdminHolding;
