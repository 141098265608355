import React, { useEffect, useState } from "react";
import axios from "axios";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import { Link } from "react-router-dom";
import MetaTags from "./MetaTags";

function Pricing() {
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.post(
          "https://ghanish.in/api/website/view_all_subscription_plans",
          {
            subscription_id: "1",
          }
        );
        if (response.data.st === 1) {
          setPlans(response.data.all_subscription_plans_list);
        }
      } catch (error) {
        console.error("Error fetching subscription plans:", error);
      }
    };

    fetchPlans();
  }, []);

  return (
    <>
      <MetaTags />
      <LandingHeader />
      <div className="container-xxl mt-10 pt-10 mb-5">
        <div className="text-center mb-5 mt-10">
          <h4>Pricing Plans</h4>
          <p className="mb-1">
            All plans include 40+ advanced tools and features to boost your
            products.
          </p>
          <p className="mt-1 mb-10">Choose the best plan to fit your needs.</p>
        </div>
      </div>
      <div className="container-xxl">
        <div className="row justify-content-center">
          {plans.map((plan) => (
            <div
              key={plan.subscription_plan_id}
              className="col-lg-3 col-md-6 col-sm-12 mb-4"
            >
              <div className="card h-100 p-3">
                <div className="card-body text-center">
                  <h3 className="card-title">
                    {plan.plan_name
                      .split(" ")
                      .map(
                        (word) =>
                          word.charAt(0).toUpperCase() +
                          word.slice(1).toLowerCase()
                      )
                      .join(" ")}
                  </h3>
                  <h4 className="card-text" style={{ color: "#8c57ff" }}>
                    <span style={{ fontSize: "1.5rem" }}>₹</span>
                    <span style={{ fontSize: "2.5rem" }}>{plan.pricing}</span>/
                    month
                  </h4>
                  <p>
                    {plan.plan_description.charAt(0).toUpperCase() +
                      plan.plan_description.slice(1)}
                  </p>
                  <ul className="text-start p-1 ps-4">
                    <li className="p-1">
                      Copy trades on{" "}
                      <span className="fw-bold">{plan.max_user_limit}</span>{" "}
                      student account(s). (Excluding Master)
                    </li>
                    <li className="p-1">
                      <span className="fw-bold">
                        {plan.service_period} Days
                      </span>{" "}
                      service
                    </li>
                    <li className="p-1">Reporting: Daily, Weekly, Monthly</li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
          <div
            className="col-lg-3 col-md-6 col-sm-12 mb-4"
            style={{ height: "382px", width: "330px" }}
          >
            <div className="card h-100 p-lg-0">
              <div className="card-body text-center">
                <h3 className="card-title">Custom</h3>
                <p className="card-text">
                  For all your custom needs please connect with our team. .....
                </p>
                <Link to="/" className="btn btn-primary mt-sm-8">
                  support@shekruweb.com{" "}
                  <i className="ri-external-link-fill"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter />
    </>
  );
}

export default Pricing;
