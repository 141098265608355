import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import config from "../../../component/config";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer"
import AdminSubHeader from "./AdminSubHeader";
import CardLinks from "../../../component/CardLinks";

const AdminMyReportDetails = () => {
  const location = useLocation();
  const [backClicked, setBackClicked] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authToken = localStorage.getItem("authToken");
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  useEffect(() => {
    const { state } = location;

    // Check if required data is present in location state
    if (!state || !state.userId || !state.month) {
      // Handle the case where location state is not properly set
      // You can navigate back or handle it based on your application flow
      navigate(-1); // Navigate back
      return;
    }

    fetchData(state.userId, state.month);
  }, [location, navigate]);

  const fetchData = async (userId, month) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/trade_details`,
        {
          user_id: userId,
          sell_month: month,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`, // Include the JWT token in the headers
            'Content-Type': 'application/json',
          },
        }

      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/commonlogin"); // Redirect to common login screen on 401 error
      } else {
        console.error("Unauthorized access, redirecting to common login screen");

      }
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };


  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">Price: <strong>{rowData.buy_price} </strong> | Lot:<strong>  {rowData.buy_lotsize} ({rowData.buy_stock_quantity})</strong></div>
        <div className="mb-1">{formatDateDay(rowData.buy_datetime)}-{formatDateMonth(rowData.buy_datetime)}-{formatDateYear(rowData.buy_datetime)} {formatDateTime(rowData.buy_datetime)} 
        </div>
        <div > {rowData.buy_orderid}</div>
      </div>
    );
  };
  
  const sellColumnTemplate = (rowData) => {
    return (
      <div >
        <div className="mb-1">Price: <strong>{rowData.sell_price} </strong>  | Lot:<strong> {rowData.sell_lotsize} ({rowData.sell_stock_quantity})</strong></div>
        <div className="mb-1">{formatDateDay(rowData.sell_datetime)}-{formatDateMonth(rowData.sell_datetime)}-{formatDateYear(rowData.sell_datetime)} {formatDateTime(rowData.sell_datetime)} </div>
        <div > {rowData.sell_orderid}</div>
      </div>
    );
  };
 

  const formatDateTime = (datetime) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString('en-US', options);
  };
  
  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };
  
  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString('default', { month: 'short' });
  };
  
  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  return (
    <>
 <AdminHeader />
 <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
              <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/profile" className="text-black">
                Profile
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/my_report" className="text-black">
                My Report
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              My Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5 ">
            <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-start mb-5">
              <h5 className="mb-0">My Report Details</h5>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3">
              <h4>{summary.total_trades_count}</h4>
              <p>Total Trades</p>
            </div>
            <div className="col-md-3">
              <h4>{summary.total_profitable_trades}</h4>
              <p>Profitable Trades</p>
            </div>
            <div className="col-md-3">
              <h4>{summary.total_losing_trades}</h4>
              <p>Losing Trades</p>
            </div>
            <div className="col-md-3">
              <h4>{summary.total_commission} Rs.</h4>
              <p>Commission</p>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3">
     
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </IconField>
            {loading ? (
                                        <i className=" custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary"></i>

            ) : (
              <i
                className=" ri ri-refresh-line ri-lg mt-3 ms-3"
                onClick={() => fetchData(location.state.userId, location.state.month)}
              />
            )}
          </div>
          <DataTable
                         className="custom-column-border"

            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
                         <Column
    align="left"
      className="custom-column-border"
    header="Buy"
    body={buyColumnTemplate}
  ></Column>

  <Column
    align="left"
     className="custom-column-border"
    header="Sell"
    body={sellColumnTemplate}
  ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl_total"
              header="P&L Total"
              body={ PLTotalBodyTemplate} 
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission "
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
    </>
  );
};

export default AdminMyReportDetails;
