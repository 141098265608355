import React from "react";
import LandingFooter from "./LandingFooter";
import LandingHeader from "./LandingHeader";


const CancellationPolicy = () => {
  return (
    <div>
    
      <LandingHeader></LandingHeader>
      <section
        id="landingPricing"
        class=" bg-body landing-pricing container-p-y pt-10 mt-10"
      >
        <div class="container-xxl bg-icon-left position-relative ">
          <h2 class="h3 ">Cancellation Policy</h2>
          <p>Last updated on May / 21 / 2024</p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            aspernatur enim accusantium impedit unde necessitatibus, inventore
            possimus ullam voluptatem, expedita cupiditate! Aliquam iste sint
            eos alias error ducimus asperiores quo, dignissimos officia placeat
            natus quam provident assumenda ad tempore deleniti perspiciatis,
            delectus velit? Voluptas doloribus quidem optio obcaecati
            repudiandae ratione sapiente similique fuga dolorem. Dolor voluptas
            placeat doloribus accusamus saepe?Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Odit consectetur pariatur sequi totam
            sed laudantium ex nihil unde, sapiente nemo, atque dicta doloremque?
            Minus necessitatibus perspiciatis voluptate ad officiis dolore
            doloribus sed dolores quibusdam, illum possimus quae voluptas? Error
            qui aliquid beatae mollitia animi iste voluptatibus ab, eum facilis
            quasi neque libero architecto delectus, doloremque, molestiae
            commodi. Consectetur fugiat fuga odio debitis velit. Fugiat id minus
            dolores vel, sunt quisquam eligendi aspernatur similique fugit nemo
            inventore distinctio ad necessitatibus voluptatem nesciunt! Culpa
            sapiente, exercitationem molestias velit aspernatur aut a quod
            asperiores similique adipisci et omnis assumenda accusantium totam
            delectus explicabo deleniti iste quos eos cupiditate, perspiciatis
            ullam? Corporis, praesentium? Labore hic delectus excepturi sapiente
            odit repellat ipsa dolorem ratione laborum voluptas optio, dicta
            sint quo adipisci error aperiam neque corrupti amet impedit cumque
            aspernatur earum repellendus nulla. Quidem repellendus pariatur
            obcaecati explicabo, nihil, error sequi quod impedit rem ipsam
            accusantium expedita iure accusamus et! Omnis velit deleniti
            similique neque commodi, impedit repellendus, architecto unde veniam
            sapiente vitae quos harum sunt culpa error suscipit delectus
            tempora. Praesentium quos eligendi unde facilis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            aspernatur enim accusantium impedit unde necessitatibus, inventore
            possimus ullam voluptatem, expedita cupiditate! Aliquam iste sint
            eos alias error ducimus asperiores quo, dignissimos officia placeat
            natus quam provident assumenda ad tempore deleniti perspiciatis,
            delectus velit? Voluptas doloribus quidem optio obcaecati
            repudiandae ratione sapiente similique fuga dolorem. Dolor voluptas
            placeat doloribus accusamus saepe?Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Odit consectetur pariatur sequi totam
            sed laudantium ex nihil unde, sapiente nemo, atque dicta doloremque?
            Minus necessitatibus perspiciatis voluptate ad officiis dolore
            doloribus sed dolores quibusdam, illum possimus quae voluptas? Error
            qui aliquid beatae mollitia animi iste voluptatibus ab, eum facilis
            quasi neque libero architecto delectus, doloremque, molestiae
            commodi. Consectetur fugiat fuga odio debitis velit. Fugiat id minus
            dolores vel, sunt quisquam eligendi aspernatur similique fugit nemo
            inventore distinctio ad necessitatibus voluptatem nesciunt! Culpa
            sapiente, exercitationem molestias velit aspernatur aut a quod
            asperiores similique adipisci et omnis assumenda accusantium totam
            delectus explicabo deleniti iste quos eos cupiditate, perspiciatis
            ullam? Corporis, praesentium? Labore hic delectus excepturi sapiente
            odit repellat ipsa dolorem ratione laborum voluptas optio, dicta
            sint quo adipisci error aperiam neque corrupti amet impedit cumque
            aspernatur earum repellendus nulla. Quidem repellendus pariatur
            obcaecati explicabo, nihil, error sequi quod impedit rem ipsam
            accusantium expedita iure accusamus et! Omnis velit deleniti
            similique neque commodi, impedit repellendus, architecto unde veniam
            sapiente vitae quos harum sunt culpa error suscipit delectus
            tempora. Praesentium quos eligendi unde facilis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            aspernatur enim accusantium impedit unde necessitatibus, inventore
            possimus ullam voluptatem, expedita cupiditate! Aliquam iste sint
            eos alias error ducimus asperiores quo, dignissimos officia placeat
            natus quam provident assumenda ad tempore deleniti perspiciatis,
            delectus velit? Voluptas doloribus quidem optio obcaecati
            repudiandae ratione sapiente similique fuga dolorem. Dolor voluptas
            placeat doloribus accusamus saepe?Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Odit consectetur pariatur sequi totam
            sed laudantium ex nihil unde, sapiente nemo, atque dicta doloremque?
            Minus necessitatibus perspiciatis voluptate ad officiis dolore
            doloribus sed dolores quibusdam, illum possimus quae voluptas? Error
            qui aliquid beatae mollitia animi iste voluptatibus ab, eum facilis
            quasi neque libero architecto delectus, doloremque, molestiae
            commodi. Consectetur fugiat fuga odio debitis velit. Fugiat id minus
            dolores vel, sunt quisquam eligendi aspernatur similique fugit nemo
            inventore distinctio ad necessitatibus voluptatem nesciunt! Culpa
            sapiente, exercitationem molestias velit aspernatur aut a quod
            asperiores similique adipisci et omnis assumenda accusantium totam
            delectus explicabo deleniti iste quos eos cupiditate, perspiciatis
            ullam? Corporis, praesentium? Labore hic delectus excepturi sapiente
            odit repellat ipsa dolorem ratione laborum voluptas optio, dicta
            sint quo adipisci error aperiam neque corrupti amet impedit cumque
            aspernatur earum repellendus nulla. Quidem repellendus pariatur
            obcaecati explicabo, nihil, error sequi quod impedit rem ipsam
            accusantium expedita iure accusamus et! Omnis velit deleniti
            similique neque commodi, impedit repellendus, architecto unde veniam
            sapiente vitae quos harum sunt culpa error suscipit delectus
            tempora. Praesentium quos eligendi unde facilis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            aspernatur enim accusantium impedit unde necessitatibus, inventore
            possimus ullam voluptatem, expedita cupiditate! Aliquam iste sint
            eos alias error ducimus asperiores quo, dignissimos officia placeat
            natus quam provident assumenda ad tempore deleniti perspiciatis,
            delectus velit? Voluptas doloribus quidem optio obcaecati
            repudiandae ratione sapiente similique fuga dolorem. Dolor voluptas
            placeat doloribus accusamus saepe?Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Odit consectetur pariatur sequi totam
            sed laudantium ex nihil unde, sapiente nemo, atque dicta doloremque?
            Minus necessitatibus perspiciatis voluptate ad officiis dolore
            doloribus sed dolores quibusdam, illum possimus quae voluptas? Error
            qui aliquid beatae mollitia animi iste voluptatibus ab, eum facilis
            quasi neque libero architecto delectus, doloremque, molestiae
            commodi. Consectetur fugiat fuga odio debitis velit. Fugiat id minus
            dolores vel, sunt quisquam eligendi aspernatur similique fugit nemo
            inventore distinctio ad necessitatibus voluptatem nesciunt! Culpa
            sapiente, exercitationem molestias velit aspernatur aut a quod
            asperiores similique adipisci et omnis assumenda accusantium totam
            delectus explicabo deleniti iste quos eos cupiditate, perspiciatis
            ullam? Corporis, praesentium? Labore hic delectus excepturi sapiente
            odit repellat ipsa dolorem ratione laborum voluptas optio, dicta
            sint quo adipisci error aperiam neque corrupti amet impedit cumque
            aspernatur earum repellendus nulla. Quidem repellendus pariatur
            obcaecati explicabo, nihil, error sequi quod impedit rem ipsam
            accusantium expedita iure accusamus et! Omnis velit deleniti
            similique neque commodi, impedit repellendus, architecto unde veniam
            sapiente vitae quos harum sunt culpa error suscipit delectus
            tempora. Praesentium quos eligendi unde facilis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            aspernatur enim accusantium impedit unde necessitatibus, inventore
            possimus ullam voluptatem, expedita cupiditate! Aliquam iste sint
            eos alias error ducimus asperiores quo, dignissimos officia placeat
            natus quam provident assumenda ad tempore deleniti perspiciatis,
            delectus velit? Voluptas doloribus quidem optio obcaecati
            repudiandae ratione sapiente similique fuga dolorem. Dolor voluptas
            placeat doloribus accusamus saepe?Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Odit consectetur pariatur sequi totam
            sed laudantium ex nihil unde, sapiente nemo, atque dicta doloremque?
            Minus necessitatibus perspiciatis voluptate ad officiis dolore
            doloribus sed dolores quibusdam, illum possimus quae voluptas? Error
            qui aliquid beatae mollitia animi iste voluptatibus ab, eum facilis
            quasi neque libero architecto delectus, doloremque, molestiae
            commodi. Consectetur fugiat fuga odio debitis velit. Fugiat id minus
            dolores vel, sunt quisquam eligendi aspernatur similique fugit nemo
            inventore distinctio ad necessitatibus voluptatem nesciunt! Culpa
            sapiente, exercitationem molestias velit aspernatur aut a quod
            asperiores similique adipisci et omnis assumenda accusantium totam
            delectus explicabo deleniti iste quos eos cupiditate, perspiciatis
            ullam? Corporis, praesentium? Labore hic delectus excepturi sapiente
            odit repellat ipsa dolorem ratione laborum voluptas optio, dicta
            sint quo adipisci error aperiam neque corrupti amet impedit cumque
            aspernatur earum repellendus nulla. Quidem repellendus pariatur
            obcaecati explicabo, nihil, error sequi quod impedit rem ipsam
            accusantium expedita iure accusamus et! Omnis velit deleniti
            similique neque commodi, impedit repellendus, architecto unde veniam
            sapiente vitae quos harum sunt culpa error suscipit delectus
            tempora. Praesentium quos eligendi unde facilis.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Magni
            aspernatur enim accusantium impedit unde necessitatibus, inventore
            possimus ullam voluptatem, expedita cupiditate! Aliquam iste sint
            eos alias error ducimus asperiores quo, dignissimos officia placeat
            natus quam provident assumenda ad tempore deleniti perspiciatis,
            delectus velit? Voluptas doloribus quidem optio obcaecati
            repudiandae ratione sapiente similique fuga dolorem. Dolor voluptas
            placeat doloribus accusamus saepe?Lorem ipsum dolor, sit amet
            consectetur adipisicing elit. Odit consectetur pariatur sequi totam
            sed laudantium ex nihil unde, sapiente nemo, atque dicta doloremque?
            Minus necessitatibus perspiciatis voluptate ad officiis dolore
            doloribus sed dolores quibusdam, illum possimus quae voluptas? Error
            qui aliquid beatae mollitia animi iste voluptatibus ab, eum facilis
            quasi neque libero architecto delectus, doloremque, molestiae
            commodi. Consectetur fugiat fuga odio debitis velit. Fugiat id minus
            dolores vel, sunt quisquam eligendi aspernatur similique fugit nemo
            inventore distinctio ad necessitatibus voluptatem nesciunt! Culpa
            sapiente, exercitationem molestias velit aspernatur aut a quod
            asperiores similique adipisci et omnis assumenda accusantium totam
            delectus explicabo deleniti iste quos eos cupiditate, perspiciatis
            ullam? Corporis, praesentium? Labore hic delectus excepturi sapiente
            odit repellat ipsa dolorem ratione laborum voluptas optio, dicta
            sint quo adipisci error aperiam neque corrupti amet impedit cumque
            aspernatur earum repellendus nulla. Quidem repellendus pariatur
            obcaecati explicabo, nihil, error sequi quod impedit rem ipsam
            accusantium expedita iure accusamus et! Omnis velit deleniti
            similique neque commodi, impedit repellendus, architecto unde veniam
            sapiente vitae quos harum sunt culpa error suscipit delectus
            tempora. Praesentium quos eligendi unde facilis.
          </p>
        </div>
      </section>
      <LandingFooter></LandingFooter>
    </div>
  );
};

export default CancellationPolicy;
