// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import img from "../../../assets/img/illustrations/tree.png";
// import background from "../../../assets/img/illustrations/auth-basic-mask-light.png";
// import { Link } from "react-router-dom";
// import config from "../../../component/config";
// import { Toast } from "primereact/toast";

// const Signup = () => {
//   const [step, setStep] = useState(1); // 1 for signup, 2 for OTP input
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [mobile, setMobile] = useState("");
//   const [otp, setOtp] = useState(["", "", "", ""]);
//   const [serverOtp, setServerOtp] = useState(null); // To store OTP from server
//   const [countdown, setCountdown] = useState(30);
//   const [isOtpComplete, setIsOtpComplete] = useState(false);
//   const [isFormValid, setIsFormValid] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const toast = useRef(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (step === 2 && countdown > 0) {
//       const timer = setTimeout(() => {
//         setCountdown(countdown - 1);
//       }, 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [step, countdown]);

//   const validateEmail = (email) => {
//     const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return re.test(String(email).toLowerCase());
//   };

//   const validateMobile = (mobile) => {
//     const re = /^[0-9]{10}$/;
//     return re.test(String(mobile));
//   };

//   const validateForm = () => {
//     let tempErrors = {};
//     let isValid = true;

//     if (!name) {
//       tempErrors.name = "Name is required";
//       isValid = false;
//     } else if (/\d/.test(name)) {
//       tempErrors.name = "Name cannot contain digits";
//       isValid = false;
//     } else if (/[^a-zA-Z\s]/.test(name)) {
//       tempErrors.name = "Name cannot contain special characters";
//       isValid = false;
//     } else if (name.length < 3) {
//       tempErrors.name = "Name must be at least 3 characters";
//       isValid = false;
//     } else if (name.length > 50) {
//       tempErrors.name = "Name must be at most 50 characters";
//       isValid = false;
//     }

//     if (!email) {
//       tempErrors.email = "Email is required";
//       isValid = false;
//     } else if (
//       !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
//     ) {
//       tempErrors.email = "Email is invalid";
//       isValid = false;
//     }

//     if (!mobile) {
//       tempErrors.mobile = "Mobile number is required";
//       isValid = false;
//     } else if (!validateMobile(mobile)) {
//       tempErrors.mobile = "Mobile number must be exactly 10 digits";
//       isValid = false;
//     }

//     setErrors(tempErrors);
//     return isValid;
//   };

//   useEffect(() => {
//     setIsFormValid(
//       validateEmail(email) && validateMobile(mobile) && name.trim().length > 0
//     );
//   }, [name, email, mobile]);

//   const handleSignupSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);
//     if (!validateForm()) {
//       setLoading(false);
//       return;
//     }
//     const requestData = {
//       name,
//       mobile,
//       email,
//     };

//     try {
//       const response = await fetch(
//         `${config.apiDomain}/api/teacher/teacher_signup_verify_otp`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(requestData),
//         }
//       );

//       const data = await response.json();

//       if (!response.ok || data.st !== 1) {
//         const backendErrors = {};
//         if (data.errors) {
//           backendErrors.email = data.errors.email;
//           backendErrors.mobile = data.errors.mobile;
//         } else {
//           throw new Error(data.msg || "Failed to sign up");
//         }

//         toast.current.show({
//           severity: "error",
//           summary: "Signup Failed",
//           detail: "Check form errors",
//           life: 3000,
//         });
//         return;
//       }

//       setServerOtp(data.otp.toString()); // Store OTP received from server
//       setStep(2); // Move to the OTP step
//       setCountdown(30); // Reset countdown
//     } catch (error) {
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: error.message,
//         life: 3000,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleOtpSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     const otpCode = otp.join("");

//     if (otpCode !== serverOtp) {
//       setError("OTP does not match");
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: "OTP does not match",
//         life: 3000,
//       });
//       setLoading(false);
//       return;
//     }

//     const requestData = {
//       name,
//       mobile,
//       email,
//       otp: otpCode,
//     };

//     try {
//       const response = await fetch(
//         `${config.apiDomain}/api/teacher/teacher_signup`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(requestData),
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to verify OTP");
//       }
//       const data = await response.json();

//       const { access_token, user_details, user_settings, user_module } = data;

//       if (data.st !== 1) {
//         throw new Error(data.msg || "Verification failed");
//       }

//       const { user_id, name, role, broker_connection_status } = user_details;

//       // Store data in localStorage
//       localStorage.setItem("authToken", access_token);
//       localStorage.setItem("userId", user_id);
//       localStorage.setItem("userName", name);
//       localStorage.setItem("userRole", role);
//       localStorage.setItem("brokerConnectionStatus", broker_connection_status);

//       if (user_settings) {
//         localStorage.setItem("userTheme", user_settings.theme || "");
//         localStorage.setItem(
//           "userEmailReport",
//           user_settings.email_report || ""
//         );
//       }

//       if (user_module) {
//         localStorage.setItem(
//           "userTradeBook",
//           JSON.stringify(user_module.trade_book)
//         );
//         localStorage.setItem(
//           "userOrderBook",
//           JSON.stringify(user_module.order_book)
//         );
//         localStorage.setItem(
//           "userHolding",
//           JSON.stringify(user_module.holding)
//         );
//       }

//       // Example action on OTP verification
//       navigate("/teacher/dashboard"); // Navigate to the Login screen
//       toast.current.show({
//         severity: "success",
//         summary: "Success",
//         detail: "OTP verified successfully",
//         life: 3000,
//       });
//     } catch (error) {
//       setError(error.message);
//       toast.current.show({
//         severity: "error",
//         summary: "Error",
//         detail: error.message,
//         life: 3000,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleOtpChange = (index, value) => {
//     const newOtp = [...otp];
//     newOtp[index] = value;
//     setOtp(newOtp);

//     setIsOtpComplete(newOtp.every((digit) => digit !== ""));

//     if (index < otp.length - 1 && value !== "") {
//       const nextInput = document.getElementById(`otp-${index + 1}`);
//       if (nextInput) {
//         nextInput.focus();
//       }
//     }
//   };

//   const handleResendOtp = () => {
//     setCountdown(30);
//   };

//   const [errors, setErrors] = useState({});

//   return (
//     <div>
//       <Toast ref={toast} />
//       <div className="position-relative">
//         <div className="authentication-wrapper authentication-basic container-p-y">
//           <div className="authentication-inner py-6 mx-4">
//             <div className="card p-7">
//               <div className="app-brand justify-content-center mt-5">
//                 <a href="/" className="app-brand-link gap-3">
//                   <span className="app-brand-logo demo">
//                     <span>
//                       <svg
//                         width="30"
//                         height="24"
//                         viewBox="0 0 250 196"
//                         fill="none"
//                         xmlns="http://www.w3.org/2000/svg"
//                       >
//                         {/* SVG paths omitted for brevity */}
//                       </svg>
//                     </span>
//                   </span>
//                 </a>
//               </div>
//               <div className="text-center mb-4">
//                 <div className="d-flex align-items-center justify-content-center">
//                   <div className="avatar">
//                     <img
//                       src={`${process.env.PUBLIC_URL}/favicon.png`}
//                       alt=""
//                       className="w-40 h-auto rounded-circle"
//                     />
//                   </div>
//                   <span className="app-brand-text demo menu-text fw-semibold ms-3 ">
//                     AutoProfito
//                   </span>
//                 </div>
//               </div>
//               <div className="card-body mt-1">
//                 <h4 className="mb-1">Welcome to AutoProfito! 👋🏻</h4>
//                 <p className="mb-5">
//                   Please sign-up to your account and start the adventure
//                 </p>

//                 {step === 1 && (
//                   <form
//                     id="formAuthentication"
//                     className="mb-5"
//                     onSubmit={handleSignupSubmit}
//                   >
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="text"
//                         className={`form-control ${
//                           errors.name ? "is-invalid" : ""
//                         }`}
//                         id="name"
//                         name="name"
//                         placeholder=" Name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         autoFocus
//                       />
//                       <label htmlFor="name">
//                         {" "}
//                         <span className="text-danger">*</span> Name
//                       </label>
//                       {errors.name && (
//                         <div className="invalid-feedback">{errors.name}</div>
//                       )}
//                     </div>
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="email"
//                         className={`form-control ${
//                           errors.email ? "is-invalid" : ""
//                         }`}
//                         id="email"
//                         name="email"
//                         placeholder="Enter your email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                       />
//                       <label htmlFor="email">
//                         {" "}
//                         <span className="text-danger">*</span> Email
//                       </label>
//                       {errors.email && (
//                         <div className="invalid-feedback">{errors.email}</div>
//                       )}
//                     </div>
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="tel"
//                         className={`form-control ${
//                           errors.mobile ? "is-invalid" : ""
//                         }`}
//                         id="mobile"
//                         name="mobile"
//                         placeholder="Enter your mobile number"
//                         value={mobile}
//                         onChange={(e) => setMobile(e.target.value)}
//                         maxLength="10"
//                       />
//                       <label htmlFor="mobile">
//                         <span className="text-danger">*</span> Mobile
//                       </label>
//                       {errors.mobile && (
//                         <div className="invalid-feedback">{errors.mobile}</div>
//                       )}
//                     </div>
//                     <div className="mb-5">
//                       <button
//                         className="active btn btn-primary d-grid w-100"
//                         type="submit"
//                         disabled={!isFormValid || loading}
//                       >
//                         {loading ? "Signing up..." : "Sign Up"}
//                       </button>
//                     </div>
//                     {error && <p className="text-danger">{error}</p>}
//                   </form>
//                 )}

//                 {step === 2 && (
//                   <form
//                     id="formAuthentication"
//                     className="mb-5"
//                     onSubmit={handleOtpSubmit}
//                   >
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="name"
//                         name="name"
//                         placeholder="Enter your name"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         required
//                         autoFocus
//                         readOnly
//                         disabled={!isFormValid || step === 2}
//                       />
//                       <label htmlFor="name">Name</label>
//                     </div>
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="tel"
//                         className="form-control"
//                         id="mobile"
//                         name="mobile"
//                         placeholder="Enter your mobile"
//                         value={mobile}
//                         onChange={(e) => setMobile(e.target.value)}
//                         required
//                         autoFocus
//                         readOnly
//                         disabled={!isFormValid || step === 2}
//                       />
//                       <label htmlFor="mobile">Mobile</label>
//                     </div>
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="email"
//                         className="form-control"
//                         id="email"
//                         name="email"
//                         placeholder="Enter your email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                         autoFocus
//                         readOnly
//                         disabled={!isFormValid || step === 2}
//                       />
//                       <label htmlFor="email">Email</label>
//                     </div>
//                     <div className="mb-5">
//                       <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
//                         {otp.map((digit, index) => (
//                           <input
//                             key={index}
//                             type="text"
//                             id={`otp-${index}`}
//                             maxLength="1"
//                             className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
//                             value={digit}
//                             onChange={(e) =>
//                               handleOtpChange(index, e.target.value)
//                             }
//                             autoFocus={index === 0}
//                           />
//                         ))}
//                       </div>
//                     </div>

//                     <div className="mb-5 text-end ">
//                       <span className="mb-2 ">
//                         Didn’t get the code? &nbsp;
//                         {countdown > 0 ? (
//                           <span>Resend OTP in {countdown}s</span>
//                         ) : (
//                           <a href="#!" onClick={handleResendOtp}>
//                             Resend OTP
//                           </a>
//                         )}
//                       </span>
//                     </div>
//                     <div className="mb-5">
//                       <button
//                         className="active btn btn-success d-grid w-100"
//                         type="submit"
//                         disabled={!isOtpComplete || loading}
//                       >
//                         {loading ? (
//                           <>
//                             <span>
//                               {" "}
//                               <i className="ri-lock-star-line ri-lg"></i>{" "}
//                               Verifying...
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             <span>
//                               {" "}
//                               <i className="ri-lock-star-line ri-lg"></i> Verify
//                               OTP
//                             </span>
//                           </>
//                         )}
//                       </button>
//                     </div>
//                   </form>
//                 )}

//                 <p className="text-center">
//                   <span className="me-1">Already have an account?</span>
//                   <Link to="/commonlogin">
//                     <span>Login</span>
//                   </Link>
//                 </p>
//               </div>
//             </div>
//             <div class="d-flex justify-content-between mt-4 mb-6">
//               <Link to="/" className="text-secondary">
//                 Home
//               </Link>
//               <Link to="/about" className="text-secondary">
//                 About
//               </Link>
//               <Link to="/pricing" className="text-secondary">
//                 Pricing
//               </Link>
//               <Link to="/contact" className="text-secondary">
//                 Contact
//               </Link>
//             </div>

//             <p className="text-center mt-5">
//               Powered by{" "}
//               <a href="https://www.shekruweb.com" target="_blank">
//                 Shekru Labs India Pvt. Ltd.
//               </a>
//             </p>

//             <img
//               src={img}
//               alt="auth-tree"
//               className="authentication-image-object-left d-none d-lg-block"
//             />
//             <img
//               src={background}
//               className="authentication-image d-none d-lg-block scaleX-n1-rtl"
//               height="173"
//               alt="triangle-bg"
//               data-app-light-img="illustrations/auth-basic-mask-light.png"
//               data-app-dark-img="illustrations/auth-basic-mask-dark.png"
//             />
//             <img
//               src={img}
//               alt="auth-tree"
//               className="authentication-image-object-right d-none d-lg-block"
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Signup;



import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/img/illustrations/tree.png";
import background from "../../../assets/img/illustrations/auth-basic-mask-light.png";
import person from "../../../assets/img/illustrations/signupbackround.png";

// import person from "../../../assets/img/illustrations/auth-cover-register-illustration-light.png"
import { Link } from "react-router-dom";
import config from "../../../component/config";
import { Toast } from "primereact/toast";

const Signup = () => {
  const [step, setStep] = useState(1); // 1 for signup, 2 for OTP input
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [serverOtp, setServerOtp] = useState(null); // To store OTP from server
  const [countdown, setCountdown] = useState(30);
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (step === 2 && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [step, countdown]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile) => {
    const re = /^[0-9]{10}$/;
    return re.test(String(mobile));
  };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;

    if (!name) {
      tempErrors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(name)) {
      tempErrors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(name)) {
      tempErrors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (name.length < 3) {
      tempErrors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (name.length > 50) {
      tempErrors.name = "Name must be at most 50 characters";
      isValid = false;
    }

    if (!email) {
      tempErrors.email = "Email is required";
      isValid = false;
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
    ) {
      tempErrors.email = "Email is invalid";
      isValid = false;
    }

    if (!mobile) {
      tempErrors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!validateMobile(mobile)) {
      tempErrors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };

  useEffect(() => {
    setIsFormValid(
      validateEmail(email) && validateMobile(mobile) && name.trim().length > 0
    );
  }, [name, email, mobile]);

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const requestData = {
      name,
      mobile,
      email,
    };

    try {
      const response = await fetch(
        `${config.apiDomain}/api/teacher/teacher_signup_verify_otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (!response.ok || data.st !== 1) {
        const backendErrors = {};
        if (data.errors) {
          backendErrors.email = data.errors.email;
          backendErrors.mobile = data.errors.mobile;
        } else {
          throw new Error(data.msg || "Failed to sign up");
        }

        toast.current.show({
          severity: "error",
          summary: "Signup Failed",
          detail: "Check form errors",
          life: 3000,
        });
        return;
      }

      setServerOtp(data.otp.toString()); // Store OTP received from server
      setStep(2); // Move to the OTP step
      setCountdown(30); // Reset countdown
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const otpCode = otp.join("");

    if (otpCode !== serverOtp) {
      setError("OTP does not match");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "OTP does not match",
        life: 3000,
      });
      setLoading(false);
      return;
    }

    const requestData = {
      name,
      mobile,
      email,
      otp: otpCode,
    };

    try {
      const response = await fetch(
        `${config.apiDomain}/api/teacher/teacher_signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to verify OTP");
      }
      const data = await response.json();

      const { access_token, user_details, user_settings, user_module } = data;

      if (data.st !== 1) {
        throw new Error(data.msg || "Verification failed");
      }

      const { user_id, name, role, broker_connection_status } = user_details;

      // Store data in localStorage
      localStorage.setItem("authToken", access_token);
      localStorage.setItem("userId", user_id);
      localStorage.setItem("userName", name);
      localStorage.setItem("userRole", role);
      localStorage.setItem("brokerConnectionStatus", broker_connection_status);

      if (user_settings) {
        localStorage.setItem("userTheme", user_settings.theme || "");
        localStorage.setItem(
          "userEmailReport",
          user_settings.email_report || ""
        );
      }

      if (user_module) {
        localStorage.setItem(
          "userTradeBook",
          JSON.stringify(user_module.trade_book)
        );
        localStorage.setItem(
          "userOrderBook",
          JSON.stringify(user_module.order_book)
        );
        localStorage.setItem(
          "userHolding",
          JSON.stringify(user_module.holding)
        );
      }

      // Example action on OTP verification
      navigate("/teacher/dashboard"); // Navigate to the Login screen
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "OTP verified successfully",
        life: 3000,
      });
    } catch (error) {
      setError(error.message);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    setIsOtpComplete(newOtp.every((digit) => digit !== ""));

    if (index < otp.length - 1 && value !== "") {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleResendOtp = () => {
    setCountdown(30);
  };

  const [errors, setErrors] = useState({});

  return (
    <div>
      <Toast ref={toast} />


      <div className="authentication-wrapper authentication-cover">
        <div className="authentication-inner row m-0">
          <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
            <div>
              <img src={person} className="authentication-image-model d-none d-lg-block" alt="auth-model" data-app-light-img="illustrations/auth-cover-register-illustration-light.png" data-app-dark-img="illustrations/auth-cover-register-illustration-dark.png" />
            </div>
            {/* <img src={img} alt="tree" className="authentication-image-tree z-n1" /> */}
            <img src={background} className="scaleX-n1-rtl authentication-image d-none d-lg-block w-75" height="362" alt="triangle-bg" data-app-light-img="illustrations/auth-cover-mask-light.png" data-app-dark-img="illustrations/auth-cover-mask-dark.png" />
          </div>
          <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center bg-white authentication-bg position-relative py-sm-5 px-12 py-4">
            <div className="w-px-400 mx-auto pt-5 pt-lg-0">

              <div className="text-center mb-4">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="avatar">
                    <img
                      src={`${process.env.PUBLIC_URL}/favicon.png`}
                      alt=""
                      className="w-40 h-auto rounded-circle"
                    />
                  </div>
                  <span className="app-brand-text demo menu-text text-black fw-semibold ms-3 ">
                    AutoProfito
                  </span>
                </div>
              </div>
              <div className="card-body mt-1">
                <h4 className="mb-1">Welcome to AutoProfito! 👋🏻</h4>
                <p className="mb-5">
                  Please sign-up to your account and start the adventure
                </p>

                {step === 1 && (
                  <form
                    id="formAuthentication"
                    className="mb-5"
                    onSubmit={handleSignupSubmit}
                  >
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="text"
                        className={`form-control ${errors.name ? "is-invalid" : ""
                          }`}
                        id="name"
                        name="name"
                        placeholder=" Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                      />
                      <label htmlFor="name">
                        {" "}
                        <span className="text-danger">*</span> Name
                      </label>
                      {errors.name && (
                        <div className="invalid-feedback">{errors.name}</div>
                      )}
                    </div>
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="email"
                        className={`form-control ${errors.email ? "is-invalid" : ""
                          }`}
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="email">
                        {" "}
                        <span className="text-danger">*</span> Email
                      </label>
                      {errors.email && (
                        <div className="invalid-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="tel"
                        className={`form-control ${errors.mobile ? "is-invalid" : ""
                          }`}
                        id="mobile"
                        name="mobile"
                        placeholder="Enter your mobile number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        maxLength="10"
                      />
                      <label htmlFor="mobile">
                        <span className="text-danger">*</span> Mobile
                      </label>
                      {errors.mobile && (
                        <div className="invalid-feedback">{errors.mobile}</div>
                      )}
                    </div>
                    <div className="mb-5">
                      <button
                        className="active btn btn-primary d-grid w-100"
                        type="submit"
                        disabled={!isFormValid || loading}
                      >
                        {loading ? "Signing up..." : "Sign Up"}
                      </button>
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                  </form>
                )}

                {step === 2 && (
                  <form
                    id="formAuthentication"
                    className="mb-5"
                    onSubmit={handleOtpSubmit}
                  >
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        autoFocus
                        readOnly
                        disabled={!isFormValid || step === 2}
                      />
                      <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="tel"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        placeholder="Enter your mobile"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        required
                        autoFocus
                        readOnly
                        disabled={!isFormValid || step === 2}
                      />
                      <label htmlFor="mobile">Mobile</label>
                    </div>
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoFocus
                        readOnly
                        disabled={!isFormValid || step === 2}
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                    <div className="mb-5">
                      <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            id={`otp-${index}`}
                            maxLength="1"
                            className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                            value={digit}
                            onChange={(e) =>
                              handleOtpChange(index, e.target.value)
                            }
                            autoFocus={index === 0}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="mb-5 text-end ">
                      <span className="mb-2 ">
                        Didn’t get the code? &nbsp;
                        {countdown > 0 ? (
                          <span>Resend OTP in {countdown}s</span>
                        ) : (
                          <a href="#!" onClick={handleResendOtp}>
                            Resend OTP
                          </a>
                        )}
                      </span>
                    </div>
                    <div className="mb-5">
                      <button
                        className="active btn btn-success d-grid w-100"
                        type="submit"
                        disabled={!isOtpComplete || loading}
                      >
                        {loading ? (
                          <>
                            <span>
                              {" "}
                              <i className="ri-lock-star-line ri-lg"></i>{" "}
                              Verifying...
                            </span>
                          </>
                        ) : (
                          <>
                            <span>
                              {" "}
                              <i className="ri-lock-star-line ri-lg"></i> Verify
                              OTP
                            </span>
                          </>
                        )}
                      </button>
                    </div>
                  </form>
                )}

                <p className="text-center">
                  <span className="me-1">Already have an account?</span>
                  <Link to="/commonlogin">
                    <span>Login</span>
                  </Link>
                </p>
              </div>

              <div className="d-flex justify-content-between mt-12 mb-6">
                <Link to="/" className="text-secondary">
                  Home
                </Link>
                <Link to="/about" className="text-secondary">
                  About
                </Link>
                <Link to="/pricing" className="text-secondary">
                  Pricing
                </Link>
                <Link to="/contact" className="text-secondary">
                  Contact
                </Link>
              </div>

              <p className="text-center mt-5">
                Powered by{" "}
                <a href="https://www.shekruweb.com" target="_blank">
                  Shekru Labs India Pvt. Ltd.
                </a>
              </p>


            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Signup;
