import React, { useEffect, useState } from "react";
import config from "../../../component/config";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link } from "react-router-dom";

import axios from "axios";
import CardLinks from "../../../component/CardLinks";

const SubscriptionTeacher = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      try {
        const response = await axios.get(
          `${config.apiDomain}/api/teacher/subscription_details`
        );
        if (response.data.st === 1) {
          setSubscriptionDetails(response.data.subscription_details);
        } else {
          console.error("Error fetching subscription data:", response.data);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching subscription data:", error);
        setLoading(false);
      }
    };

    fetchSubscriptionDetails();
  }, []);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          throw new Error("Auth token not found");
        }

        const configAxios = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/check_subscription`,
          {
            teacher_id: userId,
          },
          configAxios
        );

        setSubscriptionData(response.data.subscription_details);
        setCurrentSubscriptionId(
          response.data.subscription_details.subscription_id
        ); // Set current subscription ID
      } catch (error) {
        console.error("Error fetching subscription data:", error);
      }
    };

    fetchSubscriptionData();
  }, []);
  const renderSubscriptionButton = (subscriptionId) => {
    if (subscriptionId === currentSubscriptionId) {
      return (
        <a
          href="payment-page.html"
          className="btn btn-outline-success d-grid w-100"
        >
          Your Current Plan
        </a>
      );
    }
    return null;
  };
  return (
    <div>
      <Header />
      <SubHeader />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y mb-10">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 text-black">
                <li className="breadcrumb-item">
                  <Link to="/teacher/dashboard" className="text-black">
                    <i className="ri-home-7-line ri-lg"></i>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-secondary"
                  aria-current="page"
                >
                  Subscription
                </li>
              </ol>
            </nav>

            <div className="row mb-5">
              <div className="col-lg-6 col-12">
                <div className="card">
                  <div className="card-body">
                    {subscriptionData.subscription_name ? (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <h4 className="card-title text-truncate">
                              Subscription:{" "}
                              <strong>
                                {subscriptionData.subscription_name}
                              </strong>
                            </h4>
                            <p className="mb-10">
                              Check out below plan for more details
                            </p>
                          </div>
                          <div className="col-md-6 position-relative text-end ">
                            <button className="btn btn-primary rounded-pill btn-label-primary waves-effect">
                              {Math.floor(
                                (new Date(
                                  subscriptionData.subscription_end_date
                                ) -
                                  new Date()) /
                                  (1000 * 60 * 60 * 24)
                              )}{" "}
                              Days Remaining
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <h4 className="card-title text-truncate">
                              {" "}
                              Rs. {subscriptionData.pricing}
                            </h4>
                            <p className="">Current Subscription Charges</p>
                          </div>
                          <div className="col-md-6 position-relative text-end ">
                            <h4 className="card-title text-truncate">
                              {new Date(
                                subscriptionData.subscription_end_date
                              ).toLocaleDateString()}
                            </h4>
                            <p className="">Current plan expires on</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <p>Loading subscription data...</p>
                    )}
                  </div>
                </div>
               <CardLinks></CardLinks>
              </div>
            </div>

            <div className="pricing-plans row mx-0 gap-6">
              <div className="row mb-5">
                {loading ? (
                  <p>Loading subscription data...</p>
                ) : (
                  subscriptionDetails.map((subscription) => (
                    <div
                      key={subscription.subscription_id}
                      className="col-lg-3 col-md-6 mb-4"
                    >
                      <div
                        className={`card ${
                          subscription.subscription_id === currentSubscriptionId
                            ? "border-success border"
                            : ""
                        }`}
                      >
                        <div className="card-body">
                          <h4 className="card-title text-center text-capitalize mb-1">
                            {subscription.subscription_name}
                          </h4>
                          <p className="text-center mb-5">
                            A simple start for everyone
                          </p>
                          <div className="text-center">
                            <div className="d-flex justify-content-center">
                              <sup className="h6 pricing-currency mt-2 mb-0 me-1 text-body">
                                Rs.
                              </sup>
                              <h1 className="mb-0 text-primary">
                                {subscription.pricing}
                              </h1>
                              <sub className="h6 pricing-duration mt-auto mb-2 text-body">
                                /month
                              </sub>
                            </div>
                            <small className="price-yearly price-yearly-toggle text-muted">
                              (GST Included)
                            </small>
                          </div>
                          <ul className="list-group ps-6 my-5 pt-4 flex-grow-1">
                            <li className="mb-2">
                              Copy Trading on{" "}
                              <strong>
                                {subscription.maximum_student_account} Student
                              </strong>{" "}
                              Account. (Excluding Master)
                            </li>
                            <li className="mb-2">
                              <strong>{subscription.service_period} </strong>{" "}
                              Service
                            </li>
                            <li className="mb-2">
                              Reporting: Daily, Weekly, Monthly
                            </li>
                          </ul>
                          {renderSubscriptionButton(
                            subscription.subscription_id
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}

                <div className="col-lg-3 mb-4">
                  <div className="card border rounded shadow-none h-100 d-flex flex-column">
                    <div className="card-body pt-4 pb-0">
                      {" "}
                      {/* Adjusted padding top and bottom */}
                      <h4 className="card-title text-center text-capitalize mb-1">
                        Custom
                      </h4>
                      <p className="text-center mb-3">
                        A simple start for everyone
                      </p>
                      <div className="list-group ps-4 my-3  flex-grow-1">
                        {" "}
                        {/* Adjusted padding and margins */}
                        <div className="mb-1">
                          For all your custom needs please connect with our
                          support team.
                        </div>
                        <div className="text-black text-center">
                          support@shekrulabs.com
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionTeacher;
