// import React, { useState, useEffect, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import { Button } from "primereact/button";
// import { Toast } from "primereact/toast";
// import config from "./config";
// import img from "../assets/img/illustrations/tree-3.png";
// import background from "../assets/img/illustrations/auth-basic-mask-light.png";
// import { Link } from "react-router-dom";

// const CommonLogin = () => {
//   const [step, setStep] = useState(1);
//   const [mobile, setMobile] = useState("");
//   const [otp, setOtp] = useState(["", "", "", ""]);
//   const [countdown, setCountdown] = useState(30);
//   const [isOtpComplete, setIsOtpComplete] = useState(false);
//   const [isMobileValid, setIsMobileValid] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);
//   const toast = useRef(null);

//   const navigate = useNavigate();

//   useEffect(() => {
//     window.history.pushState(null, "", "/commonlogin");
//     const handlePopState = () => {
//       window.history.back();
//     };
//     window.addEventListener("popstate", handlePopState);
//     return () => window.removeEventListener("popstate", handlePopState);
//   }, []);

//   useEffect(() => {
//     if (step === 2 && countdown > 0) {
//       const timer = setTimeout(() => {
//         setCountdown(countdown - 1);
//       }, 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [step, countdown]);

//   const handleMobileChange = (e) => {
//     const mobileValue = e.target.value;
//     const mobileRegex = /^[0-9]{10}$/;
//     setMobile(mobileValue);
//     setIsMobileValid(mobileRegex.test(mobileValue));
//   };

//   const handleMobileSubmit = async (e) => {
//     e.preventDefault();

//     // Validate the mobile number format before making an API call
//     if (!isMobileValid) {
//       setError("Please enter a valid mobile number.");
//       return;
//     }

//     setLoading(true);
//     setError(null);

//     try {
//       // Make the API request to send OTP
//       const response = await fetch(`${config.apiDomain}/api/common/login`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ mobile }),
//       });

//       // Parse the JSON response
//       const data = await response.json();

//       // If the request is successful, move to the next step and show a success message
//       if (response.ok) {
//         setStep(2);
//         setCountdown(30); // Start countdown for OTP expiration
//         toast.current.show({
//           severity: "success",
//           summary: "OTP Sent",
//           detail: data.msg || "OTP has been sent to your mobile number",
//           life: 3000,
//         });
//       } else {
//         // If there's an error, set the error state and show an error toast
//         const errorMessage = data.msg || "This Mobile Number does not exist.";
//         setError(errorMessage);
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: errorMessage,
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       // Handle network errors
//       setError("Network error");
//       toast.current.show({
//         severity: "error",
//         summary: "Network Error",
//         detail: "Failed to send OTP",
//         life: 3000,
//       });
//     } finally {
//       // Reset the loading state
//       setLoading(false);
//     }
//   };

//   const handleOtpSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setError(null);

//     try {
//       const response = await fetch(
//         `${config.apiDomain}/api/common/verify_otp`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ otp: otp.join(""), mobile }),
//         }
//       );

//       const data = await response.json();

//       if (response.ok && data.st === 1) {
//         const {
//           access_token,
//           user_details,
//           user_settings,
//           user_module,
//           active_subscription,
//         } = data;

//         const { user_id, name, role, broker_connection_status } = user_details;

//         localStorage.setItem("authToken", access_token);
//         localStorage.setItem("userId", user_id);
//         localStorage.setItem("userName", name);
//         localStorage.setItem("userRole", role);
//         localStorage.setItem(
//           "brokerConnectionStatus",
//           broker_connection_status
//         );

//         localStorage.setItem("userTheme", user_settings.theme);
//         localStorage.setItem("userEmailReport", user_settings.email_report);
//         localStorage.setItem(
//           "userSubscriptionName",
//           active_subscription.subscription_name
//         );
//         localStorage.setItem("userPlanName", active_subscription.plan_name);

//         // Save user module settings to localStorage
//         localStorage.setItem(
//           "userTradeBook",
//           JSON.stringify(user_module.trade_book)
//         );
//         localStorage.setItem(
//           "userOrderBook",
//           JSON.stringify(user_module.order_book)
//         );
//         localStorage.setItem(
//           "userHolding",
//           JSON.stringify(user_module.holding)
//         );

//         toast.current.show({
//           severity: "success",
//           summary: "Success",
//           detail: "Logged in successfully",
//           life: 3000,
//         });

//         switch (role) {
//           case "teacher":
//             navigate("/teacher/dashboard");
//             break;
//           case "student":
//             navigate("/student/dashboard");
//             break;
//           case "admin":
//             navigate("/admin/dashboard");
//             break;
//           default:
//             navigate("/commonlogin");
//             break;
//         }
//       } else if (
//         response.ok &&
//         (data.st === 2 || data.st === 3 || data.st === 4)
//       ) {
//         const errorMsg = data.msg || "Warning";
//         setError(new Error(errorMsg));
//         toast.current.show({
//           severity: "warn",
//           summary: "Warning",
//           detail: errorMsg,
//           life: 4000,
//         });
//       } else {
//         setError(new Error("OTP verification failed"));
//         toast.current.show({
//           severity: "error",
//           summary: "Error",
//           detail: "OTP verification failed",
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       setError(new Error("Network error"));
//       toast.current.show({
//         severity: "error",
//         summary: "Network Error",
//         detail: "Failed to verify OTP",
//         life: 3000,
//       });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleOtpChange = (index, value) => {
//     const newOtp = [...otp];
//     newOtp[index] = value;
//     setOtp(newOtp);
//     setIsOtpComplete(newOtp.every((digit) => digit !== ""));
//     if (index < otp.length - 1 && value !== "") {
//       const nextInput = document.getElementById(`otp-${index + 1}`);
//       if (nextInput) {
//         nextInput.focus();
//       }
//     }
//   };

//   const handleResendOtp = () => {
//     setCountdown(30);
//     toast.current.show({
//       severity: "info",
//       summary: "OTP Resent",
//       detail: "New OTP sent.",
//       life: 3000,
//     });
//   };

//   const handleCancelOtp = () => {
//     setStep(1);
//     setMobile("");
//     setOtp(["", "", "", ""]);
//     setIsOtpComplete(false);
//     setIsMobileValid(false);
//   };
//   return (
//     <div>
//       <Toast ref={toast} />

//       <div className="position-relative ">
//         <div className="authentication-wrapper authentication-basic container-p-y">
//           <div className="authentication-inner">
//             <div className="card p-7">
//               <div className="text-center mb-4">
//                 <div className="d-flex align-items-center justify-content-center">
//                   <div className="avatar">
//                     <img
//                       src={`${process.env.PUBLIC_URL}/favicon.png`}
//                       className="w-100 rounded-circle"
//                     />
//                   </div>
//                   <span className="app-brand-text demo menu-text text-black fw-semibold ms-3">
//                     AutoProfito
//                   </span>
//                 </div>
//               </div>
//               <div className="card-body mt-1">
//                 <h4 className="mb-1">Welcome to AutoProfito.</h4>
//                 <p className="mb-5">
//                   Enter mobile number and signin to your account.
//                 </p>

//                 {step === 1 && (
//                   <form id="formAuthentication" onSubmit={handleMobileSubmit}>
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="tel"
//                         className={`form-control ${error ? "is-invalid" : ""}`}
//                         id="mobile"
//                         name="mobile"
//                         placeholder="Enter Mobile Number"
//                         value={mobile}
//                         onChange={handleMobileChange}
//                         required
//                         autoFocus
//                         autoComplete="off"
//                       />
//                       <label htmlFor="mobile">
//                         <span className="text-danger">*</span> Mobile
//                       </label>
//                       {error && <p className="text-danger">{error}</p>}
//                     </div>
//                     <div className="mb-5">
//                       <button
//                         className="active btn btn-primary  w-100"
//                         type="submit"
//                         disabled={!isMobileValid || loading}
//                       >
//                         <i className=" ri ri-lock-star-line me-1"> </i>{" "}
//                         {loading ? "Sending OTP..." : "Login"}
//                       </button>
//                     </div>
//                     {error && <p className="text-danger">{error.message}</p>}
//                   </form>
//                 )}
//                 {step === 2 && (
//                   <form id="formAuthentication" onSubmit={handleOtpSubmit}>
//                     <div className="form-floating form-floating-outline mb-5">
//                       <input
//                         type="tel"
//                         className="form-control"
//                         id="mobile"
//                         name="mobile"
//                         placeholder="Enter Mobile Number"
//                         value={mobile}
//                         onChange={handleMobileChange}
//                         required
//                         autoFocus
//                         autoComplete="off"
//                         disabled
//                       />
//                       <label htmlFor="mobile">
//                         <span className="text-danger">*</span> Mobile
//                       </label>
//                     </div>
//                     <div className="mb-5">
//                       <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
//                         {otp.map((digit, index) => (
//                           <input
//                             key={index}
//                             type="tel"
//                             className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
//                             maxLength="1"
//                             value={digit}
//                             onChange={(e) =>
//                               handleOtpChange(index, e.target.value)
//                             }
//                             id={`otp-${index}`}
//                             autoFocus={index === 0}
//                             autoComplete="off"
//                           />
//                         ))}
//                       </div>

//                       <input type="hidden" name="otp" value={otp.join("")} />
//                     </div>
//                     {countdown > 0 ? (
//                       <p className="text-end text-secondary">
//                         Resend OTP in {countdown} seconds
//                       </p>
//                     ) : (
//                       <p className="float-end text-secondary">
//                         Didn't receive the OTP?{" "}
//                         <Link to="#" onClick={handleResendOtp}>
//                           Resend now
//                         </Link>
//                         <div
//                           className="btn-link p-0 text-primary"
//                           onClick={handleResendOtp}
//                           style={{ cursor: "pointer" }}
//                         ></div>
//                       </p>
//                     )}
//                     <div className="mb-5">
//                       <Button
//                         className={`active btn d-grid w-100 ${
//                           isOtpComplete ? "btn-success" : "btn-success"
//                         }`}
//                         type="submit"
//                         disabled={!isOtpComplete || loading}
//                       >
//                         {loading ? (
//                           <>
//                             <span>
//                               <i className="ri-lock-unlock-line ri-lg me-1"></i>
//                               Verifying...
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             <span>
//                               <i className="ri-lock-unlock-line ri-lg me-1"></i>{" "}
//                               Verify OTP
//                             </span>
//                           </>
//                         )}
//                       </Button>
//                     </div>

//                     {!isOtpComplete && (
//                       <Link
//                         to="#"
//                         className="text-center d-block text-secondary"
//                         onClick={handleCancelOtp}
//                       >
//                         Cancel and change mobile number
//                       </Link>
//                     )}
//                   </form>
//                 )}
//               </div>
//             </div>

//             <div class="d-flex justify-content-between mt-4 mb-6">
//               <Link to="/" className="text-secondary">
//                 Home
//               </Link>
//               <Link to="/about" className="text-secondary">
//                 About
//               </Link>
//               <Link to="/pricing" className="text-secondary">
//                 Pricing
//               </Link>
//               <Link to="/contact" className="text-secondary">
//                 Contact
//               </Link>
//             </div>

//             <p className="text-center">
//               Powered by{" "}
//               <a href="https://www.shekruweb.com">
//                 Shekru Labs India Pvt. Ltd.
//               </a>
//             </p>

//             {/* <img
//               src={img}
//               alt="auth-tree"
//               className="authentication-image-object-left d-none d-lg-block"
//             />
//             <img
//               src={background}
//               className="authentication-image d-none d-lg-block scaleX-n1-rtl"
//               height="172"
//               alt="triangle-bg"
//               data-app-light-img="illustrations/auth-basic-mask-light.png"
//               data-app-dark-img="illustrations/auth-basic-mask-dark.png"
//             />
//             <img
//               src={img}
//               alt="auth-tree"
//               className="authentication-image-object-right d-none d-lg-block"
//             /> */}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CommonLogin;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import config from "./config";
import img from "../assets/img/illustrations/tree.png";
import background from "../assets/img/illustrations/auth-basic-mask-light.png";
import person from "../assets/img/illustrations/signupbackround.png";
import { Link } from "react-router-dom";

const CommonLogin = () => {
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [countdown, setCountdown] = useState(30);
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    window.history.pushState(null, "", "/commonlogin");
    const handlePopState = () => {
      window.history.back();
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    if (step === 2 && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [step, countdown]);

  const handleMobileChange = (e) => {
    const mobileValue = e.target.value;
    const mobileRegex = /^[0-9]{10}$/;
    setMobile(mobileValue);
    setIsMobileValid(mobileRegex.test(mobileValue));
  };

  const handleMobileSubmit = async (e) => {
    e.preventDefault();

    // Validate the mobile number format before making an API call
    if (!isMobileValid) {
      setError("Please enter a valid mobile number.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Make the API request to send OTP
      const response = await fetch(`${config.apiDomain}/api/common/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ mobile }),
      });

      // Parse the JSON response
      const data = await response.json();

      // If the request is successful, move to the next step and show a success message
      if (response.ok) {
        setStep(2);
        setCountdown(30); // Start countdown for OTP expiration
        toast.current.show({
          severity: "success",
          summary: "OTP Sent",
          detail: data.msg || "OTP has been sent to your mobile number",
          life: 3000,
        });
      } else {
        // If there's an error, set the error state and show an error toast
        const errorMessage = data.msg || "This Mobile Number does not exist.";
        setError(errorMessage);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 3000,
        });
      }
    } catch (error) {
      // Handle network errors
      setError("Network error");
      toast.current.show({
        severity: "error",
        summary: "Network Error",
        detail: "Failed to send OTP",
        life: 3000,
      });
    } finally {
      // Reset the loading state
      setLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        `${config.apiDomain}/api/common/verify_otp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ otp: otp.join(""), mobile }),
        }
      );

      const data = await response.json();

      if (response.ok && data.st === 1) {
        const {
          access_token,
          user_details,
          user_settings,
          user_module,
          active_subscription,
          broadcasting_flag
        } = data;

        const { user_id, name, role, broker_connection_status } = user_details;
        localStorage.setItem("broadcastingFlag", broadcasting_flag);
        localStorage.setItem("authToken", access_token);
        localStorage.setItem("userId", user_id);
        localStorage.setItem("userName", name);
        localStorage.setItem("userRole", role);
        localStorage.setItem(
          "brokerConnectionStatus",
          broker_connection_status
        );

        localStorage.setItem("userTheme", user_settings.theme);
        localStorage.setItem("userEmailReport", user_settings.email_report);
        localStorage.setItem(
          "userSubscriptionName",
          active_subscription.subscription_name
        );
        localStorage.setItem("userPlanName", active_subscription.plan_name);

        // Save user module settings to localStorage
        localStorage.setItem(
          "userTradeBook",
          JSON.stringify(user_module.trade_book)
        );
        localStorage.setItem(
          "userOrderBook",
          JSON.stringify(user_module.order_book)
        );
        localStorage.setItem(
          "userHolding",
          JSON.stringify(user_module.holding)
        );

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Logged in successfully",
          life: 3000,
        });

        switch (role) {
          case "teacher":
            navigate("/teacher/dashboard");
            break;
          case "student":
            navigate("/student/dashboard");
            break;
          case "admin":
            navigate("/admin/dashboard");
            break;
          default:
            navigate("/commonlogin");
            break;
        }
      } else if (
        response.ok &&
        (data.st === 2 || data.st === 3 || data.st === 4)
      ) {
        const errorMsg = data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: errorMsg,
          life: 4000,
        });
      } else {
        setError(new Error("OTP verification failed"));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "OTP verification failed",
          life: 3000,
        });
      }
    } catch (error) {
      setError(new Error("Network error"));
      toast.current.show({
        severity: "error",
        summary: "Network Error",
        detail: "Failed to verify OTP",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    setIsOtpComplete(newOtp.every((digit) => digit !== ""));
    if (index < otp.length - 1 && value !== "") {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleResendOtp = () => {
    setCountdown(30);
    toast.current.show({
      severity: "info",
      summary: "OTP Resent",
      detail: "New OTP sent.",
      life: 3000,
    });
  };

  const handleCancelOtp = () => {
    setStep(1);
    setMobile("");
    setOtp(["", "", "", ""]);
    setIsOtpComplete(false);
    setIsMobileValid(false);
  };
  return (
    <div>
      <Toast ref={toast} />

      <div className="authentication-wrapper authentication-cover ">
      <div className="authentication-inner row m-0 ">
        <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
          <div>
            <img
              src={person}
              className="authentication-image-model d-none d-lg-block"
              alt="auth-model"
              data-app-light-img="illustrations/auth-cover-login-illustration-light.png"
              data-app-dark-img="illustrations/auth-cover-login-illustration-dark.png"
            />
          </div>
          {/* <img src={img} alt="tree" className="authentication-image-tree z-n1" /> */}
          <img
            src={background}
            className="scaleX-n1-rtl authentication-image d-none d-lg-block w-75"
            alt="triangle-bg"
            height="362"
            data-app-light-img="illustrations/auth-cover-mask-light.png"
            data-app-dark-img="illustrations/auth-cover-mask-dark.png"
          />
        </div>

       
          <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center bg-white authentication-bg position-relative py-sm-5 px-12 py-4">
            <div className="w-px-400 mx-auto pt-5 pt-lg-0">
              <div className="text-center mb-4">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="avatar">
                    <img
                      src={`${process.env.PUBLIC_URL}/favicon.png`}
                      className="w-100 rounded-circle"
                    />
                  </div>
                  <span className="app-brand-text demo menu-text text-black fw-semibold ms-3">
                    AutoProfito
                  </span>
                </div>
              </div>
              <div className="card-body mt-1">
                <h4 className="mb-1">Welcome to AutoProfito.</h4>
                <p className="mb-5">
                  Enter mobile number and signin to your account.
                </p>

                {step === 1 && (
                  <form id="formAuthentication" onSubmit={handleMobileSubmit}>
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="tel"
                        className={`form-control ${error ? "is-invalid" : ""}`}
                        id="mobile"
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        onChange={handleMobileChange}
                        maxLength={10}
                        autoFocus
                        autoComplete="off"
                      />
                      <label htmlFor="mobile">
                        <span className="text-danger">*</span> Mobile
                      </label>
                      {error && <p className="text-danger">{error}</p>}
                    </div>
                    <div className="mb-5">
                      <button
                        className="active btn btn-primary  w-100"
                        type="submit"
                        disabled={!isMobileValid || loading}
                      >
                        <i className=" ri ri-lock-star-line me-1"> </i>{" "}
                        {loading ? "Sending OTP..." : "Login"}
                      </button>
                    </div>
                    {error && <p className="text-danger">{error.message}</p>}
                  </form>
                )}
                {step === 2 && (
                  <form id="formAuthentication" onSubmit={handleOtpSubmit}>
                    <div className="form-floating form-floating-outline mb-5">
                      <input
                        type="tel"
                        className="form-control"
                        id="mobile"
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        value={mobile}
                        onChange={handleMobileChange}
                        required
                        autoFocus
                        autoComplete="off"
                        disabled
                      />
                      <label htmlFor="mobile">
                        <span className="text-danger">*</span> Mobile
                      </label>
                    </div>
                    <div className="mb-5">
                      <div className="auth-input-wrapper d-flex align-items-center justify-content-between numeral-mask-wrapper">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="tel"
                            className="form-control auth-input text-center numeral-mask h-px-50 mx-sm-1 my-2"
                            maxLength="1"
                            value={digit}
                            onChange={(e) =>
                              handleOtpChange(index, e.target.value)
                            }
                            id={`otp-${index}`}
                            autoFocus={index === 0}
                            autoComplete="off"
                          />
                        ))}
                      </div>

                      <input type="hidden" name="otp" value={otp.join("")} />
                    </div>
                    {countdown > 0 ? (
                      <p className="text-end text-secondary">
                        Resend OTP in {countdown} seconds
                      </p>
                    ) : (
                      <p className="float-end text-secondary">
                        Didn't receive the OTP?{" "}
                        <Link to="#" onClick={handleResendOtp}>
                          Resend now
                        </Link>
                        <div
                          className="btn-link p-0 text-primary"
                          onClick={handleResendOtp}
                          style={{ cursor: "pointer" }}
                        ></div>
                      </p>
                    )}
                    <div className="mb-5">
                      <Button
                        className={`active btn d-grid w-100 ${
                          isOtpComplete ? "btn-success" : "btn-success"
                        }`}
                        type="submit"
                        disabled={!isOtpComplete || loading}
                      >
                        {loading ? (
                          <>
                            <span>
                              <i className="ri-lock-unlock-line ri-lg me-1"></i>
                              Verifying...
                            </span>
                          </>
                        ) : (
                          <>
                            <span>
                              <i className="ri-lock-unlock-line ri-lg me-1"></i>{" "}
                              Verify OTP
                            </span>
                          </>
                        )}
                      </Button>
                    </div>

                    {!isOtpComplete && (
                      <Link
                        to="#"
                        className="text-center d-block text-secondary"
                        onClick={handleCancelOtp}
                      >
                        Cancel and change mobile number
                      </Link>
                    )}
                  </form>
                )}
                <p className="text-center">
                  <span className="me-1">Don't have an account?</span>
                  <Link to="/signup">
                    <span>Signup</span>
                  </Link>
                </p>
              </div>

              <div className="d-flex justify-content-between mt-12 mb-6">
                <Link to="/" className="text-secondary">
                  Home
                </Link>
                <Link to="/about" className="text-secondary">
                  About
                </Link>
                <Link to="/pricing" className="text-secondary">
                  Pricing
                </Link>
                <Link to="/contact" className="text-secondary">
                  Contact
                </Link>
              </div>

              <p className="text-center">
                Powered by{" "}
                <a href="https://www.shekruweb.com">
                  Shekru Labs India Pvt. Ltd.
                </a>
              </p>

              {/* <img
              src={img}
              alt="auth-tree"
              className="authentication-image-object-left d-none d-lg-block"
            />
            <img
              src={background}
              className="authentication-image d-none d-lg-block scaleX-n1-rtl"
              height="172"
              alt="triangle-bg"
              data-app-light-img="illustrations/auth-basic-mask-light.png"
              data-app-dark-img="illustrations/auth-basic-mask-dark.png"
            />
            <img
              src={img}
              alt="auth-tree"
              className="authentication-image-object-right d-none d-lg-block"
            /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonLogin;
