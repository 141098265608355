import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import AdminHeader from './AdminHeader';
import AdminSubHeader from './AdminSubHeader';
import Footer from '../../../component/Footer';
import config from '../../../component/config';
import CardLinks from '../../../component/CardLinks';
import { Tooltip } from "primereact/tooltip";
const ViewFeatureRequest = () => {
  const { id } = useParams();  
  const [loading, setLoading] = useState(true);
  const [featureRequest, setFeatureRequest] = useState({});
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    const fetchFeatureRequestDetails = async () => {
      const authToken = localStorage.getItem("authToken");

      try {
        const response = await axios.post(`${config.apiDomain}/api/admin/feature_request/detail`, 
          { feature_request_id: id }, 
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            },
          }
        );

        if (response.data.st === 1) {
          setFeatureRequest(response.data.data);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch details', life: 3000 });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/commonlogin'); // Navigate to login page on 401 Unauthorized
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while submitting the request.' });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeatureRequestDetails();
  }, [id]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = featureRequest.example_file; // Set the file URL
    link.download = 'attachment.jpg'; // Set the desired file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up by removing the link
  };

 
    const handleRefresh = async () => {
      const authToken = localStorage.getItem("authToken");
      setLoading(true);
      try {
        const response = await axios.post(`${config.apiDomain}/api/admin/feature_request/detail`, 
          { feature_request_id: id }, 
          {
            headers: {
              'Authorization': `Bearer ${authToken}`
            },
          }
        );

        if (response.data.st === 1) {
          setFeatureRequest(response.data.data);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch details', life: 3000 });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/commonlogin'); // Navigate to login page on 401 Unauthorized
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while submitting the request.' });
        }
      } finally {
        setLoading(false);
      }
    };

  
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/feature_request" className="text-black">
                Feature Request
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
               Feature Request Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-5 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-5 text-center">
              <h5 className="mb-0">Feature Request Details</h5>
            </div>
            <div className="col-2 text-end">
    {loading ? (
      <i className="custom-target-icon ri-loader-2-line ri-lg mt-1 p-text-secondary" />
    ) : (
      <div className="mt-1">
        <Tooltip target=".custom-target-icon" />
        <i
          className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary"
          data-pr-tooltip="Reload"
          onClick={handleRefresh}
          data-pr-position="top"
        />
      </div>
    )}
  </div>
          </div>

          {loading ? (
            <div>Loading...</div>
          ) : (
            <div className="col-12 mt-4">
              <div className="row mb-3">
               
              <div className="col-3">
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.user_name}</strong>
                    </span>
                    <div> Name</div>
                  </div>
                
                  <div className="col-3">
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.product}</strong>
                    </span>
                    <div> Product</div>
                  </div>
             
              
              <div className="row mt-6">
              <div className="col-3 mt-3">
              <div> Feature</div>
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.feature}</strong>
                    </span>
                    
                  </div>
              </div>
              <div className="row mt-6">
              <div className="col-3 mt-3">
              <div> Explanation</div>
                    <span className="text-black">
                      <strong className='text-capitalize'>{featureRequest.explanation}</strong>
                    </span>
                    
                  </div>
              </div>
        
            </div>
            <div className='mt-12'>
              <button type="button"
                className="btn rounded-pill btn-outline-secondary me-3"
                onClick={handleDownload} 
              >
                <i className="ri-download-2-line me-2"></i> Download Attachment
              </button>
             
            
            </div>
            </div>
          )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default ViewFeatureRequest;
