import React, { useEffect, useState,useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Modal } from "react-bootstrap";
import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import config from "../../../component/config";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import Footer from "../../../component/Footer";
import { Toast } from 'primereact/toast'; 
import CardLinks from '../../../component/CardLinks';
const StudentReportDetails = () => {
  const navigate = useNavigate();
  const { userId, sell_date } = useParams();

  const toast = useRef(null);
  const [backClicked, setBackClicked] = useState(false);
  // Fetch userId and sell_month from URL params
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authToken = localStorage.getItem('authToken');

  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });


  useEffect(() => {
    fetchData();
  }, [userId,sell_date]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/student_trade_details_view`,
        {
          user_id: userId,
          sell_date: sell_date,

        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`, // Include the auth token in the headers
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        setError(new Error(error.message || "Failed to fetch data"));
      }
    } finally {
      setLoading(false);
    }
  };




  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/student_trade_details_view`,
        {
          user_id: userId,
          sell_date: sell_date,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data) {
        setData(response.data.trades);
        setSummary(response.data.completed_trades_aggregate);

        // Show success toast message if data is fetched
        if (toast.current) {
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: response.data.msg || 'Trade details fetched successfully!',
            life: 3000,
          });
        }
      } else {
        setError(new Error('No data found'));

        // Show warning toast if no data is found
        if (toast.current) {
          toast.current.show({
            severity: 'warn',
            summary: 'Warning',
            detail: response.data.msg || 'No trade details found.',
            life: 3000,
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin');

        // Show error toast on unauthorized access
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Unauthorized access. Redirecting to login.',
            life: 3000,
          });
        }
      } else {
        setError(new Error(error.message || 'Failed to fetch data'));

        // Show error toast message in case of failure
        if (toast.current) {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error.message || 'Failed to fetch trade details.',
            life: 3000,
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };


  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component



  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };



 

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };


  const buyPriceTemplate = (rowData) => {
    return ` ${rowData.buy_price} Rs.`;
  };

  const sellPriceBodyTemplate = (rowData) => {
    return ` ${rowData.sell_price} Rs.`;
  };

  const PLTemplate = (rowData) => {
    return ` ${rowData.pandl} Rs.`;
  };

  const PLTotalBodyTemplate = (rowData) => {
    return ` ${rowData.pandl_total} Rs.`;
  };

  const PLPercentTemplate = (rowData) => {
    return ` ${rowData.pandl_percent} %`;
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} %`;
  };

  const formatDateTime = (datetime) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString('en-US', options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString('default', { month: 'short' });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };
  const buyColumnTemplate = (rowData) => {
    return (
      <div>
        <div className="mb-1">Price: <strong>{rowData.buy_price} </strong> | Lot:<strong>  {rowData.buy_lotsize} ({rowData.buy_stock_quantity})</strong></div>
        <div className="mb-1">{formatDateDay(rowData.buy_datetime)}-{formatDateMonth(rowData.buy_datetime)}-{formatDateYear(rowData.buy_datetime)} {formatDateTime(rowData.buy_datetime)} 
        </div>
        <div > {rowData.buy_orderid}</div>
      </div>
    );
  };
  
  const sellColumnTemplate = (rowData) => {
    return (
      <div >
        <div className="mb-1">Price: <strong>{rowData.sell_price} </strong>  | Lot:<strong> {rowData.sell_lotsize} ({rowData.sell_stock_quantity})</strong></div>
        <div className="mb-1">{formatDateDay(rowData.sell_datetime)}-{formatDateMonth(rowData.sell_datetime)}-{formatDateYear(rowData.sell_datetime)} {formatDateTime(rowData.sell_datetime)} </div>
        <div > {rowData.sell_orderid}</div>
      </div>
    );
  };
  return (
    <>
        <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
           
            <li className="breadcrumb-item">
              <Link to=" teacher/student_report" className="text-black">
              Student Report
              </Link>
            </li>
            <li className="breadcrumb-item">
            <Link to="/teacher/student_report_list/" className="text-black">Student List</Link>

            </li>
            <li className="breadcrumb-item">
              <Link to={`/teacher/student_report_datewise/${userId}/${sell_date}`} className="text-black">
              Student Datewise Report
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Student Report Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-start mb-5">
              <h5 className="mb-0"> Student Report Details</h5>
            </div>
          </div>
          


          <div className="d-flex justify-content-between align-items-center mb-3">
  <div className="d-flex align-items-center">
    <IconField iconPosition="left">
      <InputIcon className="ri ri-search-line"></InputIcon>
      <InputText
        type="search"
        placeholder="Search"
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="rounded"
      />
    </IconField>

    {loading ? (
      <i className="custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary" />
    ) : (
      <div className="mt-3">
        <Tooltip target=".custom-target-icon" />
        <i
          className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
          data-pr-tooltip="Reload"
          data-pr-position="top"
           onClick={handleRefresh}
        />
      </div>
    )}
  </div>

  <strong className="mt-3 ms-auto  fs-4">{sell_date}</strong> 
</div>
          <DataTable
            className="custom-column-border"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
                   <Column
    align="left"
      className="custom-column-border"
    header="Buy"
    body={buyColumnTemplate}
  ></Column>

  <Column
    align="left"
     className="custom-column-border"
    header="Sell"
    body={sellColumnTemplate}
  ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl"
              header="P&L"
              body={PLTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl_total"
              header=" Total P&L "
              body={PLTotalBodyTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pandl_percent"
              header="P&L Percent"
              body={PLPercentTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="commission"
              header="Commission"
              body={CommissionBodyTemplate}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </>
  );
};

export default StudentReportDetails;


const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};