import React, { useState, useEffect, useCallback, useRef } from "react";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import Header from "../component/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import CardLinks from "../../../component/CardLinks";
const WatchList = () => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  return (
    <div>
      <Header></Header>
      <SubHeader></SubHeader>
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
          <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Watchlist
            </li>
          </ol>
        </nav>
            <div className="row">
              <div className="col-xl-9 col-lg-9  col-9 ">
                <div className="card">
                  <div className="d-flex justify-content-start ms-4 mb-3  mt-2 me-3"></div>

                  <div
                    className="card-datatable table-responsive pt-0 "
                    style={{ height: "400px", overflowY: "auto" }}
                  ></div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3  col-3 ">
                
                <div className="card ">
                <div className="d-flex justify-content-between mt-3 ms-3 me-3 bg-white ">
                 
  <div >
    <span className="text-black">NIFTY 50</span>
    <span className="text-success ms-2">10120.75</span>
  </div>
  <div>
    <span className="text-black">SENSEX</span>
    <span className="text-success ms-2">586.90</span>
  </div>
  
</div>
<hr></hr>
                  <div className="d-flex justify-content-center my-1 mx-3  w-90 ">
                    <IconField iconPosition="left" className="w-100">
                      <InputIcon className="ri ri-search-line"></InputIcon>
                      <InputText
                        type="search"
                        placeholder="Search"
                        value={globalFilter}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        className="w-100"
                      />
                    </IconField>
                  </div>

                  <div
                    className="card-datatable table-responsive pt-0 "
                    style={{ height: "300px", overflowY: "auto" }}
                  >
                    <table className="table table-sm">
                      <tbody>
                        <tr>
                          <td>
                            <span className="text-danger">RHFL</span>
                          </td>
                          <td></td>
                          <td>
                            <span className="text-danger">77.55</span>
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-outline-danger btn-xs btn-icon rounded-pill dropdown-toggle "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              ></button>

                              <ul className="dropdown-menu">
                                <li>
                                  <button
                                    type="button"
                                    class="btn btn-label-success w-100 border-bottom dropdown-item mt-0"
                                  >
                                 <span className="text-start"><i className="ri-check-line text-success ri-lg me-2"></i> Buy</span>  
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    class="btn btn-label-danger w-100  border-bottom dropdown-item"
                                  >
                                                                  <span className="text-start"><i className="ri-telegram-line text-danger ri-lg me-2"></i> Buy</span>  

                                  </button>
                                </li>
                                <li>
                                  <span className=" w-100 btn-xs text-center dropdown-item">
                                    {" "}
                                    <i className="ri-close-large-line ri-md"></i>{" "}
                                    Delete
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="text-danger">RHFL</span>
                          </td>
                          <td className="text-center"></td>
                          <td>
                            <span className="text-danger">77.55</span>
                          </td>
                          <td>
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn btn-outline-danger btn-xs btn-icon rounded-pill dropdown-toggle "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              ></button>

                              <ul className="dropdown-menu">
                                <li>
                                  <button
                                    type="button"
                                    class="btn btn-label-success w-100 border-bottom dropdown-item"
                                  >
                                    Buy
                                  </button>
                                </li>
                                <li>
                                  <button
                                    type="button"
                                    class="btn btn-label-danger w-100  border-bottom dropdown-item"
                                  >
                                    Sell
                                  </button>
                                </li>
                                <li>
                                  <span className=" w-100 btn-xs text-center dropdown-item">
                                    {" "}
                                    <i className="ri-close-large-line ri-md"></i>{" "}
                                    Delete
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="text-success">RHFL</span>
                          </td>
                          <td className="text-center"></td>
                          <td>
                            <span className="text-success">77.55</span>
                          </td>
                          <td>
                            <div class="btn-group">
                              <div class="btn-group">
                                <button
                                  type="button"
                                  class="btn btn-outline-danger btn-xs btn-icon rounded-pill dropdown-toggle "
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                ></button>

                                <ul className="dropdown-menu">
                                  <li>
                                    <button
                                      type="button"
                                      class="btn btn-label-success w-100 border-bottom dropdown-item"
                                    >
                                      Buy
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      type="button"
                                      class="btn btn-label-danger w-100  border-bottom dropdown-item"
                                    >
                                      Sell
                                    </button>
                                  </li>
                                  <li>
                                    <span className=" w-100 btn-xs text-center dropdown-item">
                                      {" "}
                                      <i className="ri-close-large-line ri-md"></i>{" "}
                                      Delete
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <CardLinks></CardLinks>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default WatchList;
