import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import axios from "axios";
import CardLinks from "../../../component/CardLinks";

const ManageTelegramSubscription = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const toast = useRef(null);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleView = (id) => {
    navigate(`/admin/manage_telegram_subscription/view/${id}`);
  };

  const handleEdit = (id) => {
    navigate(`/admin/manage_telegram_subscription/update/${id}`);
  };

  const deleteAddon = async (id) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.delete(
        `https://ghanish.in/api/admin/subscription_telegram/delete`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: { subscription_id: id },
        }
      );

      if (response.data.st === 1) {
        setFaqs((prevFaqs) => prevFaqs.filter((faq) => faq.id !== id));
         // Update the state with the new data

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to delete subscription",
      });
    }
  };

  const handleDelete = (id) => {
    confirmDialog({
      message: "Are you sure you want to delete this Telegram Subscription?",
      header: "Confirm",
      icon: "pi pi-info-circle",
      acceptLabel: "Yes",
      rejectLabel: "No",
      accept: () => deleteAddon(id),
      reject: () => {
        toast.current.show({
          severity: "info",
          summary: "Cancelled",
          detail: "Delete action was cancelled",
        });
      },
    });
  };

  const toTitleCase = (str) => {
    if (!str) return "";
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const categoryBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.subscription_name)}</span>;
  };
  const activesubscriberBodyTemplate = (rowData) => {
    return <span>{rowData.subscriber_count}</span>;
  };
  const questionBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.description)}</span>;
  };

  const isButtonDisabled = (rowData) => {
    return rowData.subscriber_count > 0;
  };

  // const shouldHideButton = (rowData) => {
  //   return rowData.active_subscriber_count > 0;
  // };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const response = await axios.get(
          "https://ghanish.in/api/admin/subscription_telegram/listview",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setFaqs(response.data.all_subscription_list);
        } else {
          setError("Failed to fetch data");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        } else {
          setError("An error occurred while fetching data");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);


  const handleRefresh = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      const response = await axios.get(
        "https://ghanish.in/api/admin/subscription_telegram/listview",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        setFaqs(response.data.all_subscription_list);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.message || "Data fetched successfully!",
          life: 3000
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.message || "Failed to fetch Subscriptions",
          life: 3000
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
        toast.current.show({
          severity: "error",
          summary: "Unauthorized",
          detail: error.response.data.message || "Your session has expired. Please log in again.",
          life: 3000
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: error.response?.data?.message || "Failed to fetch Subscriptions",
          life: 3000
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Manage Telegram Subscription
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-4 text-center">
              <h5 className="mb-0">Manage Telegram Subscription</h5>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>
              {loading ? (
                <i className="custom-target-icon ri-loader-2-line ri-lg ms-3"></i>
              ) : (
                <div className="ms-3">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    data-pr-tooltip="Reload"
                    data-pr-position="top"
                    className="ri ri-refresh-line ri-lg"
                      onClick={handleRefresh}
                  />
                </div>
              )}
            </div>
            {/* <div className="ms-auto">
              <Link to="/admin/manage_telegram_subscription/create">
                <button className="btn btn-success">
                  <i className="ri-add-circle-line ri-lg me-1"></i>
                  <span>Create Subscription</span>
                </button>
              </Link>
            </div> */}
          </div>

          <DataTable
            value={faqs}
            rows={20}
            paginator
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="p-datatable-rounded border border-1 text-center"
          >
            <Column
              field="subscription_name"
              body={categoryBodyTemplate}
              header=" Subscription Name"
              className="border border-1"
              align={"center"}
            ></Column>

            <Column
              field="description"
              style={{
                wordBreak: "break-word",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "400px",
              }}
              align={"left"}
              className="border border-1"
              body={questionBodyTemplate}
              header="Subscription Description"
            ></Column>
            <Column
              field="subscriber_count"
              align={"center"}
              className="border border-1"
              body={activesubscriberBodyTemplate}
             
              header="Active Subscribers"
            ></Column>
            
            <Column
              align={"center"}
              className="border border-1"
              header="Actions"
              body={(rowData) => (
               
                <>
                  <button
                    className="btn btn-info me-3 custom-btn-action1"
                    onClick={() => handleView(rowData.subscription_id)}
                  >
                    <i className="ri-gallery-view-2 ri-lg"></i>
                  </button>
                  
                  <button
                    className="btn btn-warning me-3 custom-btn-action1"
                    onClick={() => handleEdit(rowData.subscription_id)}
                  
                  >
                    <i className="ri-edit-2-line ri-lg"></i>
                  </button>
                  
                  {/* <button
                    className="btn btn-danger active text-align custom-btn-action1"
                    onClick={() => handleDelete(rowData.subscription_id)}
                     disabled={isButtonDisabled(rowData)}
                  >
                    <i className="ri-close-large-line ri-lg"></i>
                  </button> */}
                </>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
};

export default ManageTelegramSubscription;
