import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../component/Header";
import Footer from "../../../component/Footer";
import SubHeader from "../component/SubHeader";
import config from "../../../component/config";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'primereact/tooltip';

const Home = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [studentCount, setStudentCount] = useState({
    total_student_count: 0,
    active_student_count: 0,
    inactive_student_count: 0,
  });
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTeacherHomeData = async () => {
      const userId = localStorage.getItem("userId");
      if (!userId) return;

      try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
          throw new Error("Auth token not found");
        }

        const response = await axios.post(
          `${config.apiDomain}/api/teacher/teacher_home`,
          {
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
            },
          }
        );

        if (response.data.st === 1) {
          setStudentCount(response.data.student_count);
        } else {
          console.error("Failed to fetch teacher home data:", response.data.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        } else {
          console.error("Error fetching teacher home data:", error);
        }
      }
    };

    fetchTeacherHomeData();
  }, []);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  return (
    <div>
<Header />
<SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
<div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
  <div className="layout-container">
    <div className="container-xxl flex-grow-1 container-p-y">
            <div className="row g-6">
            {studentCount.total_student_count > 0 && (
              <div className="col-sm-6 col-lg-2">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2">
                      <div className="avatar me-4">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="ri-group-3-line ri-24px"></i>
                        </span>
                      </div>
                      <h4 className="mb-0 fs-3">{studentCount.total_student_count}</h4>
                    </div>
                    <h6 className="mb-0 fw-normal"> Total Students</h6>
                  </div>
                </div>
              </div>
            )}
             {studentCount.active_student_count > 0 && (
              <div className="col-sm-6 col-lg-2">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2">
                      <div className="avatar me-4">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="ri-group-3-line ri-24px"></i>
                        </span>
                      </div>
                      <h4 className="mb-0 fs-3">{studentCount.active_student_count}</h4>
                    </div>
                    <h6 className="mb-0 fw-normal">Total Active Students</h6>
                  </div>
                </div>
              </div>
             )}
             {studentCount.inactive_student_count > 0 && (
              <div className="col-sm-6 col-lg-2">
                <div className="card card-border-shadow-primary h-100">
                  <div className="card-body">
                    <div className="d-flex align-items-center mb-2">
                      <div className="avatar me-4">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="ri-group-3-line ri-24px"></i>
                        </span>
                      </div>
                      <h4 className="mb-0 fs-3">{studentCount.inactive_student_count}</h4>
                    </div>
                    <h6 className="mb-0 fw-normal">Total Inactive Students</h6>
                  </div>
                </div>
              </div>
             )}
              

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};


// import React, { useEffect, useState } from 'react';
// import useWebSocket from 'react-use-websocket';

// const Home = () => {
//   const [data, setData] = useState([
//     { token: '2885', exchange: 'NSE' },
//     // { token: '1922', exchange: 'NSE' },
//     // { token: '1594', exchange: 'NSE' },
//     // { token: '3721', exchange: 'NSE' },
//     // { token: '4668', exchange: 'NSE' },
//   ]);

//   const [ltpData, setLtpData] = useState({});

//   const { sendMessage } = useWebSocket('ws://ghanish.in:8001/ws/market-data/', {
//     onOpen: () => {
//       // Send the payload when the WebSocket connection opens
//       sendMessage(JSON.stringify({
//         instruments: [
//           ["NSE", "2885"],
//           // ["NSE", "1922"],
//           // ["NSE", "1594"],
//           // ["NSE", "3721"],
//           // ["NSE", "4668"],
//         ]
//       }));
//     },
//     onMessage: (event) => {
//       // Handle incoming messages
//       const message = JSON.parse(event.data);
//       setLtpData(prevLtpData => ({
//         ...prevLtpData,
//         [message.token]: message.ltp
//       }));
//     }
//   });

//   return (
//     <div className="container text-center my-5">
//       <h2 className="mb-4">LTP Live data</h2>
//       <table className="table table-bordered bg-white mx-auto">
//         <thead>
//           <tr>
//             <th>Token</th>
//             <th>Exchange</th>
//             <th>LTP</th>
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((row, index) => (
//             <tr key={index}>
//               <td>{row.token}</td>
//               <td>{row.exchange}</td>
//               <td>{ltpData[row.token] || 'Loading...'}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// }

// export default Home;
