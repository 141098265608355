import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import Footer from "../../../component/Footer";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import config from "../../../component/config";
import CardLinks from "../../../component/CardLinks";
const UpdateFaq = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const { faqId } = useParams();
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [errors, setErrors] = useState({ category: "", question: "", answer: "" });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${config.apiDomain}/api/admin/get_faq_category`);
        if (response.data.st === 1) {
          setCategories(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchFaqDetails = async () => {
      const authToken = localStorage.getItem("authToken");
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/admin/view_faq`,
          { faq_id: faqId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`
            }
          }
        );
        if (response.data.st === 1) {
          const { question, answer, category } = response.data.data;
          setQuestion(question);
          setAnswer(answer); // Directly set the HTML content
          
          // After categories are loaded, set the selected category
          const selectedCategory = categories.find(cat => cat.category_name === category);
          if (selectedCategory) {
            setSelectedCategory(selectedCategory.faq_category_id);
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/common/login'); // Redirect to common login screen
        } else {
          console.error('Error fetching FAQ details:', error);
        }
      }
    };

    if (categories.length > 0) {
      fetchFaqDetails();
    }
  }, [faqId, categories, navigate]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    // Clear category error if a valid category is selected
    if (e.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, category: "" }));
    }
  };
  
  const handleQuestionChange = (e) => {
    const newQuestion = e.target.value;
    setQuestion(newQuestion);
    // Clear question error if the length is valid
    if (newQuestion.trim().length >= 5) {
      setErrors((prevErrors) => ({ ...prevErrors, question: "" }));
    }
  };
  
 

  const cleanHtmlContent = (html) => {
    const text = html.replace(/<\/?[^>]+(>|$)/g, "").trim();
    return text.length > 0 ? text : "";
  };
  
  
  const handleAnswerChange = (value) => {
   
    const cleanedValue = cleanHtmlContent(value);
    setAnswer(cleanedValue);
   
   
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    // Reset errors
    setErrors({ category: "", question: "", answer: "" });
  
    let hasError = false;
    const newErrors = {};
  
    if (!selectedCategory) {
      newErrors.category = 'Please select a category';
      hasError = true;
    }
  
    if (question.trim().length < 5) {
      newErrors.question = 'Question must be at least 5 characters';
      hasError = true;
    }
  
    const cleanedAnswer = cleanHtmlContent(answer);
    if (cleanedAnswer.length === 0) {
      newErrors.answer = 'Answer cannot be empty';
      hasError = true;
    } 
  
    if (hasError) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
  
    const data = {
      faq_id: faqId,
      faq_category_id: selectedCategory,
      question,
      answer: cleanedAnswer // Use cleaned answer
    };
  
    const authToken = localStorage.getItem("authToken");
  
    try {
      await axios.put(`${config.apiDomain}/api/admin/update_faq`, data, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      setLoading(false);
  
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'FAQ updated successfully', life: 3000 });
  
      setTimeout(() => {
        navigate("/admin/manage_faq");
      }, 1000);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin'); // Redirect to common login screen
      } else {
        console.error('Error updating FAQ:', error);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error updating FAQ', life: 3000 });
        setLoading(false);
      }
    }
  };
  

  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_faq" className="text-black">
                Manage FAQ
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Update FAQ
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-5 text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-6 text-start mb-5">
              <h5 className="mb-0">Update FAQ</h5>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="col-6 text-start">
                <label htmlFor="category_name" className="form-label"> <span className="text-danger">*</span> Category Name</label>
              </div>
              <select
                id="category_name"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className={`form-select ${errors.category ? "is-invalid" : ""}`}
              >
                <option value="">Select a category</option>
                {categories.map(category => (
  <option key={category.faq_category_id} value={category.faq_category_id} className="text-capitalize">
    {category.category_name}
  </option>
))}

              </select>
              {errors.category && <small className="text-danger">{errors.category}</small>}
            </div>
            <div className="mb-3">
              <div className="form-floating form-floating-outline">
                <input
                  type="text"
                  className={`form-control ${errors.question ? "is-invalid" : ""}`}
                  id="question"
                  value={question}
                  onChange={handleQuestionChange}
                  placeholder="Question"
                />
                <label htmlFor="question">
                  <span className="text-danger">*</span> Question
                </label>
                {errors.question && <small className="text-danger">{errors.question}</small>}
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="answer" className="form-label">
                <span className="text-danger">*</span> Answer
              </label>
              <ReactQuill
                theme="snow"
                value={answer} // Directly set HTML content
                onChange={handleAnswerChange}
                placeholder="Write something..."
                className={`form-control ${errors.answer ? "is-invalid" : ""}`}
              />
              {errors.answer && <small className="text-danger">{errors.answer}</small>}
            </div>
          
            <div className=" text-end mt-4">
                              {loading && (
                                <div className="spinner-border me-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>                              )}
                              <button
                                type="submit"
                                className="btn rounded-pill btn-info btn-sm"
                                disabled={loading}
                              >
                           <i className="ri-checkbox-circle-line ri-lg me-2"></i>    {loading ? "Saving Data" : "Save Data"} 
                              
                              </button>
                            </div>
          </form>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Toast ref={toast} position="top-right" />
      <Footer />
    </div>
  );
};

export default UpdateFaq;

