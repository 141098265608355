import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ConfirmDialog } from "primereact/confirmdialog"; // PrimeReact imports

const Header = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // State for confirmation dialog

  const navigate = useNavigate();

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);

  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const userPlanName = localStorage.getItem("userPlanName");
  const userSubscriptionName = localStorage.getItem("userSubscriptionName");
  
  const toTitleCase = (str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept", // Custom abbreviation
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsDate = { weekday: "short", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });
    
    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const closeDropdown = () => setDropdownOpen(false);

  const handleLogout = () => {
    setShowLogoutConfirm(true); // Show confirmation dialog
  };

  const acceptLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    navigate("/commonlogin"); // Navigate to the common login page after logout
  };

  return (
    <div>
      <ConfirmDialog // PrimeReact ConfirmDialog
        visible={showLogoutConfirm}
        onHide={() => setShowLogoutConfirm(false)}
        message="Are you sure you want to logout?"
        header="Logout Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={acceptLogout}
        reject={() => setShowLogoutConfirm(false)}
      />
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="container-xxl d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div className="avatar me-2">
              <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="AutoProfito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span className="app-brand-text demo menu-text fw-semibold">
              AutoProfito
            </span>
          </div>

          <div className="d-none d-xl-block">
            <span className="text-secondary">
              {formattedDate}
            </span>
            <br />
            <strong className="fs-4">
              {formattedTime}
            </strong>
          </div>

          {/* Right Section: User Info and Profile Menu */}
          <div className="d-flex align-items-center">
            <div className="text-end me-3">
              <h6 className=" fs-5 mb-0 bold text-uppercase">
                {toTitleCase(userName)}
              </h6>
              <div className="position-relative d-inline-block my-2">
                <Link to="/teacher/user_profile">
                  <span className="badge rounded-pill bg-label-dark ">
                    {toTitleCase(userRole)}
                  </span>
                </Link>
              </div>
            </div>
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item dropdown-user dropdown">
                <div
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  onClick={toggleDropdown}
                  style={{ cursor: "pointer" }}
                >
                  <div className="w-px-40 position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-primary border-3 rounded-circle bg-label-primary avatar-lg">
                      <span className="avatar-initial rounded-circle bg-label-primary fs-2">
                        {userName && userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
                {dropdownOpen && (
                  <ul
                    className="dropdown-menu dropdown-menu-end mt-3 py-2 show"
                    style={{ right: "0", left: "auto" }}
                    onMouseLeave={closeDropdown}
                  >
                    <li>
                      <Link
                        to="/teacher/user_profile"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-user-line ri-md me-2"></i>
                        <span className="align-middle">Profile</span>
                      </Link>
                    </li>
                    <hr className="mb-0 mt-0" />
                    <li>
                      <Link
                        to="/teacher/billing"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-medal-2-fill ri-md me-2"></i>
                        <span className="align-middle">Subscriptions</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/teacher/payment_history"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-money-rupee-circle-line ri-md me-2"></i>
                        <span className="align-middle">Payment History</span>
                      </Link>
                    </li>
                    <hr className="mb-0 mt-0" />
                    <li>
                      <Link
                        to="/teacher/my_report"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-bar-chart-box-line ri-md me-2"></i>
                        <span className="align-middle">My Report</span>
                      </Link>
                    </li>
                    <hr className="mb-0 mt-0" />
                    <li>
                      <Link
                        to="/teacher/feature_request"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-sparkling-line ri-md me-2"></i>
                        <span className="align-middle">Feature Request</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/teacher/chat"
                        className="dropdown-item"
                        onClick={closeDropdown}
                      >
                        <i className="ri-chat-2-line ri-md me-2"></i>
                        <span className="align-middle"> Chat</span>
                      </Link>
                    </li>
                   
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          handleLogout();
                          closeDropdown();
                        }}
                      >
                        <i className="ri-logout-circle-r-line ri-lg me-1"></i>
                        <span className="align-middle me-2">Logout</span>
                      </div>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
