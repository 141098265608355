// import React, { useEffect, useState, useRef } from "react";

// import Footer from "../../../component/Footer";
// import { Toast } from "primereact/toast";

// import { Link, useNavigate } from "react-router-dom";

// import { Modal, Button } from "react-bootstrap";
// import config from "../../../component/config";
// import axios from "axios";
// import back from "../../../assets/img/icons/misc/triangle-light.png";

// import image from "../../../assets/img/illustrations/illustration-upgrade-account-2.png";
// import StudentHeader from "./StudentHeader";
// import SubHeaderS from "./SubHeaderS";
// const ProfileReports = () => {
//   const [userData, setUserData] = useState(null);
//   const navigate = useNavigate();
//   const [successMessage, setSuccessMessage] = useState("");
//   const [isTradingPowerEditable, setIsTradingPowerEditable] = useState(false);
//   const [error, setError] = useState("");
//   const [formData, setFormData] = useState({});
//   const toast = useRef(null);
//   const [loading, setLoading] = useState(false);
//   useEffect(() => {
//     const fetchUserProfile = async () => {
//       try {
//         const userId = localStorage.getItem("userId");
//         const authToken = localStorage.getItem("authToken");

//         if (!userId) {
//           console.error("User ID not found in localStorage");
//           return;
//         }

//         if (!authToken) {
//           console.error("Access token not found in localStorage");
//           navigate("/commonlogin");
//           return;
//         }

//         const response = await fetch(
//           `${config.apiDomain}/api/common/get_profile_details`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${authToken}`,
//             },
//             body: JSON.stringify({ user_id: userId }),
//           }
//         );

//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }

//         const data = await response.json();

//         if (data.st === 1) {
//           setUserData(data.user);
//           setFormData(data.user); // Initialize formData with the fetched user data
//         } else {
//           console.error("Failed to fetch user profile:", data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching user profile:", error);
//         // Check for 401 Unauthorized error
//         if (error.response && error.response.status === 401) {
//           console.error(
//             "Unauthorized access, redirecting to common login screen"
//           );
//           navigate("/commonlogin"); // Navigate to common login screen on 401 error
//         }
//       }
//     };
//     fetchUserProfile();
//   }, []); // Ensure the dependency array is empty

//   const handleConnectionStatus = (status) => {
//     console.log("Connection status:", status);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name === "lot_size_limit") {
//       if (/^\d*$/.test(value)) {
//         // Only allow digits
//         if (parseInt(value, 10) > 5000) {
//           setError("Maximum limit is 5000");
//           setFormData({
//             ...formData,
//             [name]: 5000,
//           });
//         } else {
//           setError("");
//           setFormData({
//             ...formData,
//             [name]: value,
//           });
//         }
//       } else {
//         setError("Only digits are allowed");
//       }
//     } else {
//       setFormData({
//         ...formData,
//         [name]: value,
//       });

//       // If the "Trading Power" field is edited, set it as editable
//       if (name === "tradingPower") {
//         setIsTradingPowerEditable(true);
//       }
//     }
//   };

//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   };

//   const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

//   useEffect(() => {
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       // Check if it's 9:15 AM or 3:15 PM
//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000); // Every minute

//     // Clear interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   // Helper function to determine modal button variant
//   const getButtonVariant = () => {
//     const now = new Date();
//     const hours = now.getHours();

//     if (hours === 9) {
//       return "success"; // Green color for 9:15 AM
//     } else if (hours === 15) {
//       return "danger"; // Red color for 3:15 PM
//     }
//     return "secondary"; // Default color
//   };

//   return (
//     <>
//       <StudentHeader></StudentHeader>
//       <SubHeaderS></SubHeaderS>
//       <Toast ref={toast} position="top-right" />

//       <Modal
//         show={showPopup}
//         onHide={handleClosePopup}
//         dialogClassName={getColorModalClass()}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{getModalTitle()}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>{getModalBody()}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className=" layout-navbar-full layout-horizontal layout-without-menu">
//         <div className="layout-page">
//           <div className="content-wrapper">
//             <div className="container flex-grow-1 container-p-y">
//               <nav aria-label="breadcrumb">
//                 <ol className="breadcrumb breadcrumb-style1 text-secondary">
//                   <li className="breadcrumb-item">
//                     <Link to="/student/dashboard" className="text-secondary">
//                       <i className="ri-home-7-line ri-lg"></i>
//                     </Link>
//                   </li>
//                   <li
//                     className="breadcrumb-item active text-secondary"
//                     aria-current="page"
//                   >
//                     Profile
//                   </li>
//                 </ol>
//               </nav>

//               <div className="row">
//                 {userData && (
//                   <div className="row ">
//                     <div className="col-md-4">
//                       <div className="card ">
//                         <div className="card-body pt-0">
//                           <div className="user-info  mt-3 text-center">
//                             <h5>{capitalizeFirstLetter(userData.name)}</h5>
//                             <span className="badge bg-label-info rounded-pill">
//                               {" "}
//                               {capitalizeFirstLetter(userData.role)}
//                             </span>
//                           </div>
//                           <div class="d-flex justify-content-around flex-wrap my-6 gap-0 gap-md-3 gap-lg-4">
//                             <div class="d-flex align-items-center me-5 gap-4">
//                               <div class="avatar">
//                                 <div class="avatar-initial bg-label-primary rounded">
//                                   <i class="ri-check-line ri-24px"></i>
//                                 </div>
//                               </div>
//                               <div>
//                                 <h5 class="mb-0">1.23k</h5>
//                                 <span>Task Done</span>
//                               </div>
//                             </div>
//                             <div class="d-flex align-items-center gap-4">
//                               <div class="avatar">
//                                 <div class="avatar-initial bg-label-primary rounded">
//                                   <i class="ri-briefcase-line ri-24px"></i>
//                                 </div>
//                               </div>
//                               <div>
//                                 <h5 class="mb-0">568</h5>
//                                 <span>Project Done</span>
//                               </div>
//                             </div>
//                           </div>

//                           <ul className="list-unstyled my-3 py-1">
//                             <li className="d-flex flex-column align-items-start mb-4">
//                               <span className="fw-medium fs-5">About</span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Name:</strong>
//                               <span className="ml-auto">
//                                 {capitalizeFirstLetter(userData.name)}
//                               </span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Role:</strong>
//                               <span className="ml-auto">
//                                 {" "}
//                                 {capitalizeFirstLetter(userData.role)}
//                               </span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Broker Connection:</strong>
//                               <span className="text-success ml-auto">
//                                 <div className="ms-auto">
//                                   <div
//                                     className={`text-success ml-auto ${
//                                       userData.broker_conn_status
//                                         ? "text-success"
//                                         : "text-danger"
//                                     }`}
//                                     onClick={() =>
//                                       handleConnectionStatus(
//                                         !userData.broker_conn_status
//                                       )
//                                     }
//                                   >
//                                     {userData.broker_conn_status ? (
//                                       <>
//                                         <i className="ri-shield-check-line"></i>{" "}
//                                         Connected
//                                       </>
//                                     ) : (
//                                       <>
//                                         <i className="ri-close-large-line"></i>{" "}
//                                         Disconnected
//                                       </>
//                                     )}
//                                   </div>
//                                 </div>
//                               </span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Commission:</strong>
//                               <span className="ml-auto">
//                                 {userData.commission}%
//                               </span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Broker Acc. Balance:</strong>
//                               <span className="ml-auto">
//                                 {(userData.amount || 0).toFixed(2)} Rs.
//                               </span>
//                             </li>
//                           </ul>
//                           <hr />
//                           <ul className="list-unstyled my-3 py-1">
//                             <li className="d-flex flex-column align-items-start mb-4">
//                               <span className="fw-medium fs-5">Contacts</span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Email:</strong>
//                               <span className="ml-auto">{userData.email}</span>
//                             </li>
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Mobile:</strong>
//                               <span className="ml-auto">{userData.mobile}</span>
//                             </li>
//                           </ul>
//                           <hr />
//                           <ul className="list-unstyled my-3 py-1">
//                             <li className="d-flex justify-content-between align-items-center mb-4">
//                               <strong>Lot Size Limit:</strong>
//                               <span className="ml-auto ">
//                                 {userData.lot_size_limit} Lot
//                               </span>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-md-8">
//                       <div className="row">
//                         <div className="col-md-8 col-sm-7">
//                           <div class="nav-align-top">
//                             <ul class="nav nav-pills flex-column justify-content-start flex-md-row flex-wrap mb-6 row-gap-1">
//                               <li className="nav-item  active">
//                                 <Link
//                                   to="/app2/student_profile"
//                                   className="nav-link  "
//                                 >
//                                   <i className="ri-user-3-line me-1_5"></i>
//                                   Profile
//                                 </Link>
//                               </li>
//                               <li className="nav-item ">
//                                 <Link
//                                   to="/student/report"
//                                   className="nav-link active btn btn-primary"
//                                 >
//                                   <i className="ri-team-line me-1_5"></i>Reports
//                                 </Link>
//                               </li>

//                               <li className="nav-item">
//                                 <Link
//                                   to="/student/login_activity_log"
//                                   className="nav-link "
//                                 >
//                                   <i className="ri-team-line me-1_5"></i>
//                                   Activity log
//                                 </Link>
//                               </li>
//                             </ul>
//                           </div>
//                         </div>

//                         <div className="card-body pt-0">
//                           <div className="row">
//                             <div className="col-lg-3">
//                               <div className="card">
//                                 <div className="card-body">
//                                   <h5 className="card-title mb-5">
//                                     My Reports
//                                   </h5>
//                                   <h4 className="text-light mb-0">2,2199</h4>
//                                   <p className="mb-3">Profit This Month</p>
//                                   <Link
//                                     to="/app2/student_my_report"
//                                     className="btn btn-sm btn-primary"
//                                   >
//                                     <i className="ri-user-follow-fill ri-md me-2">
//                                       {" "}
//                                     </i>
//                                     My Reports
//                                   </Link>
//                                 </div>
//                                 <img
//                                   src={back}
//                                   className="scaleX-n1-rtl position-absolute bottom-0 end-0"
//                                   width="166"
//                                   alt="triangle background"
//                                   data-app-light-img="icons/misc/triangle-light.png"
//                                   data-app-dark-img="icons/misc/triangle-dark.png"
//                                 />
//                                 <img
//                                   src={image}
//                                   className="scaleX-n1-rtl position-absolute bottom-0 end-0 me-5 mb-3"
//                                   height="176"
//                                   alt="Upgrade Account"
//                                 />
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               <Footer></Footer>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ProfileReports;

// const getColorModalClass = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9 || hours === 15) {
//     return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
//   }
//   return "";
// };

// const getModalTitle = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is Open!";
//   } else if (hours === 15) {
//     return "Market is Closed!";
//   }
//   return "";
// };

// const getModalBody = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is currently open. Take necessary actions.";
//   } else if (hours === 15) {
//     return "Market is currently closed. Come back tomorrow.";
//   }
//   return "";
// };


import React, { useEffect, useState,useRef } from "react";
import axios from "axios";

import Footer from "../../../component/Footer";

import { Link, useNavigate } from "react-router-dom";
import config from "../../../component/config";
import back from "../../../assets/img/icons/misc/triangle-light.png";

import img from "../../../assets/img/avatars/1.png";

import image from "../../../assets/img/illustrations/illustration-upgrade-account-2.png";
import StudentHeader from "./StudentHeader";
import SubHeaderS from "./SubHeaderS";

const ProfileReports = () => {
  const [userData, setUserData] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const toast = useRef(null);
  const [brokerData, setBrokerData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken || !userId) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Access token or user ID not found. Please log in again.",
            life: 3000,
          });
          setLoading(false);
          navigate("/commonlogin");
          return;
        }

        const response = await axios.post(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            user_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setUserData(response.data.user_details);
          setBrokerData(response.data.broker_details);
          setFormData({
            ...response.data.user_details,
            ...response.data.broker_details,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: response.data.msg,
            life: 3000,
          });
        }
      } catch (error) {
        let errorMsg = "Failed to fetch data";
        if (error.response) {
          errorMsg = error.response.data.msg
            ? formatMessage(error.response.data.msg)
            : "An error occurred";
        } else if (error.message) {
          errorMsg = error.message;
        }
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleConnectionStatus = (status) => {
    console.log("Connection status:", status);
  };

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };
  return (
    <div>
      <div>
       <StudentHeader></StudentHeader>
       <SubHeaderS></SubHeaderS>
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper">
                <div className="container flex-grow-1 container-p-y">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb breadcrumb-style1">
                      <li className="breadcrumb-item">
                        <Link to="/student/dashboard">
                          {" "}
                          <i className="ri-home-7-line ri-lg"></i>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Profile
                      </li>
                    </ol>
                  </nav>

                
                  {userData && (
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card">
                        <div className="card-body pt-0">
                          <div className="user-info mt-3 text-center">
                            <h5>{capitalizeFirstLetter(userData.name)}</h5>
                            <span className="badge bg-label-info rounded-pill">
                              {capitalizeFirstLetter(userData.role)}
                            </span>
                          </div>
                          <ul className="list-unstyled my-3 py-1">
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Broker Connection:</strong>
                              <span className="text-success ml-auto">
                                <div className="ms-auto">
                                  <div
                                    className={`text-success ml-auto ${
                                      userData.broker_conn_status
                                        ? "text-success"
                                        : "text-danger"
                                    }`}
                                    onClick={() =>
                                      handleConnectionStatus(
                                        !userData.broker_conn_status
                                      )
                                    }
                                  >
                                    {userData.broker_conn_status ? (
                                      <>
                                        <i className="ri-shield-check-line"></i>{" "}
                                        Connected
                                      </>
                                    ) : (
                                      <>
                                        <i className="ri-close-large-line"></i>{" "}
                                        Not Connected
                                      </>
                                    )}
                                  </div>
                                </div>
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Broker Acc. Balance:</strong>
                              <span className="ml-auto">
                                {userData.amount} Rs.
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Commission:</strong>
                              <span className="ml-auto">
                                {userData.commission}%
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Lot Size Limit:</strong>
                              <span className="ml-auto">
                                {userData.lot_size_limit} Lot
                              </span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Segments:</strong>
                              <span className="ml-auto">
                                {userData.exchanges}
                              </span>
                            </li>
                          </ul>
                          <hr />
                          <ul className="list-unstyled my-3 py-1">
                            <li className="d-flex flex-column align-items-start mb-4">
                              <span className="fw-medium fs-5">Contacts</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Email:</strong>
                              <span className="ml-auto">{userData.email}</span>
                            </li>
                            <li className="d-flex justify-content-between align-items-center mb-4">
                              <strong>Mobile:</strong>
                              <span className="ml-auto">{userData.mobile}</span>
                            </li>
                          </ul>
                          <hr />
                          <ul className="list-unstyled my-3 py-1"></ul>
                        </div>
                      </div>
                    </div>
                      <div className="col-md-8">
                        <div className="row">
                        {/* <div className="col-md-8 col-sm-7">
                        <div class="nav-align-top">
                          <ul class="nav nav-pills flex-column justify-content-start flex-md-row flex-wrap mb-6 row-gap-1">
                          <li className="nav-item  ">
                            <Link
                              to="/app2/student_profile"
                              className="nav-link  "
                            >
                              <i className="ri-user-3-line me-1_5"></i>Profile
                            </Link>
                          </li>
                          <li className="nav-item active">
                            <Link
                              to="/student/report"
                              className="nav-link active btn btn-primary "
                            >
                              <i className="ri-team-line me-1_5"></i>Reports
                            </Link>
                          </li>
                          
                       
                    <li className="nav-item">
                      <Link to="/student/login_activity_log" className="nav-link " >
                        <i className="ri-team-line me-1_5"></i>Activity log
                      </Link>
                    </li>

                          </ul>
                        </div>
                      </div> */}

                          <div className="card-body pt-0">
                            <div className="row">
                              <div className="col-lg-3">
                                <div className="card">
                                  <div className="card-body">
                                    <h5 className="card-title mb-5">
                                      My Reports
                                    </h5>
                                    <h4 className="text-light mb-0">2,2199</h4>
                                    <p className="mb-3">Profit This Month</p>
                                    <Link
                                      to="/app2/student_my_report"
                                      className="btn btn-sm btn-primary"
                                    >
                                      <i className="ri-user-follow-fill ri-md me-2">
                                        {" "}
                                      </i>
                                      My Reports
                                    </Link>
                                  </div>
                                  <img
                                    src={back}
                                    className="scaleX-n1-rtl position-absolute bottom-0 end-0"
                                    width="166"
                                    alt="triangle background"
                                    data-app-light-img="icons/misc/triangle-light.png"
                                    data-app-dark-img="icons/misc/triangle-dark.png"
                                  />
                                  <img
                                    src={image}
                                    className="scaleX-n1-rtl position-absolute bottom-0 end-0 me-5 mb-3"
                                    height="176"
                                    alt="Upgrade Account"
                                  />
                                </div>
                              </div>
                             
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileReports;
