import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import userImg from "../../../assets/img/avatars/1.png";
import MetaTags from "./MetaTags";
import "./LandingHeader.css";

const LandingHeader = () => {
  const [activeLink, setActiveLink] = useState("/");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <MetaTags />
      {/* Mobile Navbar */}
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top d-lg-none">
        <div className="container-fluid d-flex justify-content-between align-items-center px-3">
          {/* Mobile Toggle Button */}
          <button
            className="navbar-toggler border-0"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
            onClick={handleMenuToggle}
          >
            <i className="ri-menu-fill ri-30px align-middle fs-4"></i>
          </button>
          {/* Brand Logo */}
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/favicon.png"}
              alt="Favicon"
              width="44"
            />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
          {/* User Profile Image */}
          <a href="#profile" className="d-flex align-items-center">
            <img
              src={userImg}
              alt="Profile"
              width="30"
              className="rounded-circle"
            />
          </a>
        </div>
        {/* Slide-in Navbar Menu */}
        <div
          className={`mobile-menu ${isMenuOpen ? "open" : ""}`}
          id="navbarSupportedContent"
        >
          {/* Brand Logo */}
          <Link to="/" className="navbar-brand d-flex align-items-center px-5">
            <img
              src={process.env.PUBLIC_URL + "/favicon.png"}
              alt="Favicon"
              width="44"
            />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
          <hr className="my"></hr>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/" ? "text-primary" : ""
                } px-4`}
                to="/"
                onClick={() => setActiveLink("/")}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/pricing" ? "text-primary" : ""
                } px-4`}
                to="/pricing"
                onClick={() => setActiveLink("/pricing")}
              >
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/contact" ? "text-primary" : ""
                } px-4`}
                to="/contact"
                onClick={() => setActiveLink("/contact")}
              >
                Contact
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${
                  activeLink === "/about" ? "text-primary" : ""
                } px-4`}
                to="/about"
                onClick={() => setActiveLink("/about")}
              >
                About
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      {/* Desktop Navbar */}
      <nav className="navbar navbar-expand-lg bg-white shadow-sm fixed-top d-none d-lg-block">
        <div className="container-xxl">
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/favicon.png"}
              alt="Favicon"
              width="44"
            />
            <span className="ms-2 fw-semibold fs-5">AutoProfito</span>
          </Link>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "/" ? "text-primary" : ""
                  } px-4`}
                  to="/"
                  onClick={() => setActiveLink("/")}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "/pricing" ? "text-primary" : ""
                  } px-4`}
                  to="/pricing"
                  onClick={() => setActiveLink("/pricing")}
                >
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "/contact" ? "text-primary" : ""
                  } px-4`}
                  to="/contact"
                  onClick={() => setActiveLink("/contact")}
                >
                  Contact
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${
                    activeLink === "/about" ? "text-primary" : ""
                  } px-4`}
                  to="/about"
                  onClick={() => setActiveLink("/about")}
                >
                  About
                </Link>
              </li>
            </ul>

            {/* Desktop Buttons */}
            <div className="navbar-nav ms-auto">
              <Link
                to="/signup"
                className="d-flex justify-content-center align-items-center rounded-pill me-2 text-dark pe-3 "
              >
                <div className="btn rounded-pill btn-outline-secondary waves-effect">
                  Signup
                </div>
              </Link>
              <Link
                to="/commonlogin"
                className="btn btn-info rounded-pill btn-outline-secondary waves-effect"
              >
                <i className="ri-user-line me-1" />
                Login
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default LandingHeader;
