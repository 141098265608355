import React from "react";
import FAQs from "./FAQs";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import MetaTags from "./MetaTags";

function CookiePolicy() {
  return (
    <div>
      <MetaTags />
      <LandingHeader />
      <div
        className="container-xxl mt-10  pt-10 "
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className=" p-3 rounded">
          <div className="mb-3">
            <h3 className="m-0">Cookies Policy</h3>
            <h5 className="mt-0">Last Updates May / 21 / 2024</h5>
          </div>
          <div>
            <div>
              <p className="mx-0 mb-1 fs-5">
                You can manage your cookie preferences and opt out of certain
                types of cookies by using the cookie settings on our site or by
                configuring your browser settings.
              </p>

              <p className="mx-0 mb-1 fs-5">
                For more information about how we use cookies and how you can
                manage your cookie preferences, please refer to our full Cookie
                Policy available on our site.
              </p>

              <p className="mx-0 mb-1 fs-5">
                By continuing to use our site without changing your settings,
                you consent to our use of cookies as described in this policy.
                please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                If you have any questions or concerns about our use of cookies,
                please contact us for more information. please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                You can manage your cookie preferences and opt out of certain
                types of cookies by using the cookie settings on our site or by
                configuring your browser settings.
              </p>

              <p className="mx-0 mb-1 fs-5">
                For more information about how we use cookies and how you can
                manage your cookie preferences, please refer to our full Cookie
                Policy available on our site.
              </p>

              <p className="mx-0 mb-1 fs-5">
                By continuing to use our site without changing your settings,
                you consent to our use of cookies as described in this
                policy.please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                If you have any questions or concerns about our use of cookies,
                please contact us for more information.please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                You can manage your cookie preferences and opt out of certain
                types of cookies by using the cookie settings on our site or by
                configuring your browser settings.
              </p>

              <p className="mx-0 mb-1 fs-5">
                For more information about how we use cookies and how you can
                manage your cookie preferences, please refer to our full Cookie
                Policy available on our site.
              </p>

              <p className="mx-0 mb-1 fs-5">
                By continuing to use our site without changing your settings,
                you consent to our use of cookies as described in this
                policy.please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                If you have any questions or concerns about our use of cookies,
                please contact us for more information.please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                You can manage your cookie preferences and opt out of certain
                types of cookies by using the cookie settings on our site or by
                configuring your browser settings.
              </p>

              <p className="mx-0 mb-1 fs-5">
                For more information about how we use cookies and how you can
                manage your cookie preferences, please refer to our full Cookie
                Policy available on our site.
              </p>

              <p className="mx-0 mb-1 fs-5">
                By continuing to use our site without changing your settings,
                you consent to our use of cookies as described in this
                policy.please contact us.
              </p>

              <p className="mx-0 mb-1 fs-5">
                If you have any questions or concerns about our use of cookies,
                please contact us for more information.please contact us.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <FAQs /> */}
      <LandingFooter />
    </div>
  );
}

export default CookiePolicy;
