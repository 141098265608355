import React from "react";
import { Helmet } from "react-helmet";

function MetaTags() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="keywords"
        content="ULTRASUEDE GREEN LIMITED, Deying of Textile Polyester Yarn, Processing synthetic Polyester Yarn"
      />
      <meta
        name="description"
        content="ULTRASUEDE GREEN LIMITED, Deying of Textile Polyester Yarn, Processing synthetic Polyester Yarn"
      />

      <meta http-equiv="content-type" content="text/html; charset=UTF-8" />
      <meta
        http-equiv="Cache-Control"
        content="no-cache, no-store, must-revalidate"
      />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta http-equiv="pragma" content="no-cache" />
      <meta http-equiv="expires" content="never" />
      <meta http-equiv="Expires" content="0" />

      <meta name="author" content="ULTRASUEDE GREEN LIMITED" />
      <meta name="revisit-after" content="7 days" />
      <meta name="distribution" content="web" />
      <meta name="rating" content="general" />
      <meta name="robots" content="index, follow" />
      <meta name="apple-mobile-web-app-capable" content="YES" />
      <meta name="HandheldFriendly" content="True" />
      <meta name="MobileOptimized" content="320" />
      <meta
        name="identifier-url"
        content="https://shekru-labs-india.github.io/autoprofito_frontend_sugatraj/"
      />
      <meta name="distribution" content="global" />
      <meta name="googlebot" content="all" />
      <meta name="geo.placename" content="India" />
      <meta
        name="googlebot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />
      <meta
        name="bingbot"
        content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />

      <link
        rel="canonical"
        href="https://shekru-labs-india.github.io/autoprofito_frontend_sugatraj/"
      />
      <link
        rel="shortlink"
        href="https://shekru-labs-india.github.io/autoprofito_frontend_sugatraj/"
      />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="ULTRASUEDE GREEN LIMITED | Deying of Textile Polyester Yarn | Processing synthetic Polyester Yarn"
      />
      <meta
        property="og:description"
        content="ULTRASUEDE GREEN LIMITED, Deying of Textile Polyester Yarn, Processing synthetic Polyester Yarn"
      />
      <meta
        property="og:url"
        content="https://shekru-labs-india.github.io/autoprofito_frontend_sugatraj/"
      />
      <meta property="og:site_name" content="ULTRASUEDE GREEN LIMITED" />
      <meta property="article:publisher" content="FB" />
      <meta property="article:updated_time" content="1589023717" />
      <meta
        property="og:image"
        content="https://shekru-labs-india.github.io/autoprofito_frontend_sugatraj/assets/img/logo/AutoProfito_Logo.png"
      />
      <meta property="og:image:alt" content="ULTRASUEDE GREEN LIMITED" />
      <meta property="og:image:width" content="720" />
      <meta property="og:image:height" content="602" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content="ULTRASUEDE GREEN LIMITED | Deying of Textile Polyester Yarn | Processing synthetic Polyester Yarn"
      />
      <meta
        name="twitter:description"
        content="ULTRASUEDE GREEN LIMITED, Deying of Textile Polyester Yarn, Processing synthetic Polyester Yarn"
      />
      <meta
        name="twitter:image"
        content="https://shekru-labs-india.github.io/autoprofito_frontend_sugatraj/assets/img/logo/AutoProfito_Logo.png"
      />
    </Helmet>
  );
}

export default MetaTags;
