import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import Footer from "../../../component/Footer"
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from 'primereact/tooltip';
import config from "../../../component/config";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Toast } from "primereact/toast";
import CardLinks from "../../../component/CardLinks";
const MyReportDatewise = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId"); 
  const [data, setData] = useState([]);
  const location = useLocation();
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);

  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });

  const [month, setMonth] = useState(""); // Define month state

  useEffect(() => {
    const { state } = location;

    // Check if required data is present in location state
    if (!state || !state.userId || !state.month) {
      // Handle the case where location state is not properly set
      // You can navigate back or handle it based on your application flow
      navigate(-1); // Navigate back
      return;
    }

    fetchData(state.userId, state.month);
  }, [location, navigate]);
  const fetchData = async (userId, month) => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

      if (!userId || !authToken) {
        throw new Error("User ID or auth token not found");
      }

      const response = await axios.post(
        `https://ghanish.in/api/common/datewise_report`,
        {
          user_id: userId,
          month_name: month,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_date);
        setSummary(response.data.completed_trades);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const authToken = localStorage.getItem("authToken");
      const { userId, month } = location.state; // Fetch userId and month from location.state
  
      if (!userId || !authToken) {
        throw new Error("User ID or auth token not found");
      }
  
      const response = await axios.post(
        `https://ghanish.in/api/common/datewise_report`,
        {
          user_id: userId,
          month_name: month,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data) {
        const { st, completed_trades_per_date, completed_trades, msg } = response.data;
  
        switch (st) {
          case 1:
            setData(completed_trades_per_date);
            setSummary(completed_trades);
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: formatMessage(msg) || "Success!",
              className: "text-start",
              life: 3000,
            });
            break;
  
          case 2:
            toast.current.show({
              severity: "warn",
              summary: "Warning",
              detail: formatMessage(msg) || "Warning: Please check the input.",
              life: 3000,
            });
            break;
  
          case 3:
          case 4:
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: formatMessage(msg) || "An error occurred.",
              life: 3000,
            });
            break;
  
          default:
            setErrors({ global: "Unexpected response status" });
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Unexpected response status",
              life: 3000,
            });
        }
      } else {
        setErrors({ global: "No data found" });
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No data found",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response?.data?.msg
          ? formatMessage(error.response.data.msg)
          : String(error.message || "Failed to fetch data");
  
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
          className: "text-start",
        });
      }
    } finally {
      setLoading(false);
    }
  };
  

  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  useEffect(() => {
    if (month) { // Check if month is defined
      fetchData(userId, month);
    }
  }, [month]);



  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleViewReport = (rowData) => {
    navigate(`/teacher/my_report_view`, { state: { userId, month: rowData.sell_date } });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component



  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };





  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };
  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} Rs.`;
  };
  return (
    <>
      <Toast ref={toast} />
      <Header></Header>
      <SubHeader></SubHeader>
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/my_report" className="text-black">
                My Report
              </Link>
            </li>
            
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Datewise Report
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-auto text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-center mb-5">
              <h5 className="mb-0">Datewise Report</h5>
            </div>
            <div className="col-auto text-end mb-5">
              <Link to="/teacher/student_report">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-group-line me-1 ri-lg"></i>{" "}
                    <span>Student Report</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_trades_count || 0}</h4>
              <p className="mt-0">Total Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_profitable_trades || 0}</h4>
              <p className="mt-0">Profitable Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_losing_trades || 0}</h4>
              <p className="mt-0">Losing Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_commission || 0} Rs.</h4>
              <p className="mt-0">Commission</p>
            </div>
          </div>

          <div className="d-flex justify-content-start mb-3">

            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"> </InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
              <i className=" custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary"

               
              />
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary "
                  data-pr-tooltip="Reload"
                  onClick={handleRefresh}
                  data-pr-position="top"


                >

                </i>
              </div>


            )}
          </div>
          <DataTable
            className="custom-column-border"
            align="center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
          >
            <Column
              align="center"
              className="custom-column-border" field="sell_date"
              header="Date"

            ></Column>
            <Column
              align="center"
              className="custom-column-border" field="trades_count"
              header="Total Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border" field="profitable_trades"
              header="Profitable Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border" field="losing_trades"
              header="Losing Trades"
            ></Column>
            <Column
              align="center"
              className="custom-column-border" field="commission"
              header="Commission"
              body={CommissionBodyTemplate}
            ></Column>
            <Column
              align="center"
              className="custom-column-border" header="Actions"
              body={(rowData) => (
                <Button
                  onClick={() => handleViewReport(rowData)}
                  className="btn btn-info custom-btn-action1"
                >
                  <i className="ri-gallery-view-2"></i>
                </Button>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default MyReportDatewise;


const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};