import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import MetaTags from "./MetaTags";

const FaqDetails = () => {
  const { faqId } = useParams(); // Extract FAQ ID from URL params
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [faqDetails, setFaqDetails] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize navigate for navigation

  useEffect(() => {
    const fetchFaqDetails = async () => {
      setLoading(true); // Ensure loading is set to true before fetching
      try {
        const response = await axios.post(
          "https://ghanish.in/api/website/faq_detail",
          { faq_id: faqId } // Request body
        );
        if (response.data.st === 1) {
          setFaqDetails(response.data.data);
          setError(null);
        } else {
          setFaqDetails(null);
          setError("FAQ not found");
        }
      } catch (error) {
        setError("An error occurred while fetching FAQ details");
      } finally {
        setLoading(false); // Set loading to false once done
      }
    };

    fetchFaqDetails(); // Fetch FAQ details when component mounts
  }, [faqId]);

  const fetchSearchResults = async (query) => {
    setLoading(true); // Ensure loading is set to true before fetching
    try {
      const response = await axios.post(
        "https://ghanish.in/api/website/search_faq",
        { keyword: query }
      );
      if (response.data.st === 1) {
        setSearchResults(response.data.data);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]);
    } finally {
      setLoading(false); // Set loading to false once done
    }
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchTerm(query);
    if (query.length >= 3) {
      fetchSearchResults(query); // Fetch search results if query length is 3 or more
    } else {
      setSearchResults([]);
    }
  };

  const handleSelect = (id) => {
    navigate(`/faq/${id}`); // Navigate to selected FAQ details
  };

  return (
    <div>
      <MetaTags />
      <LandingHeader />

      <section className="section-py first-section-pt">
        <div className="container">
          <div className="row gy-4 gy-lg-0">
            <div className="col-lg-8">
              <div className="mb-3">
                <Link to="/help_center">
                  <i className="ri-arrow-left-line me-3"></i>View All FAQs
                </Link>
              </div>
              {loading && <p>Loading...</p>}
              {error && <p>{error}</p>}
              {faqDetails && !loading && !error && (
                <>
                  <h4 className="mb-2">{faqDetails.question}</h4>
                  <p className="mb-0 text-capitalize">{faqDetails.category}</p>
                  <hr className="my-6" />
                  <p className="mb-0">
                    <strong className="me-3"></strong>
                    {faqDetails.answer}
                  </p>
                </>
              )}
            </div>
            <div className="col-lg-4 bg-white pt-3 rounded" style={{height:"400px"}}>
              <div className="input-group input-group-merge mb-6">
                <span className="input-group-text" id="article-search">
                  <i className="ri-search-line ri-20px" />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search..."
                  aria-label="Search..."
                  aria-describedby="article-search"
                  value={searchTerm}
                  onChange={handleSearch} // Handle input changes
                />
              </div>
              {/* <div className="bg-lightest py-2 px-4 rounded">
                <h5 className="mb-0">Articles in this section</h5>
              </div> */}
              <ul className="list-unstyled mt-4 mb-0">
                {searchResults.length > 0 ? (
                  searchResults.map((faq) => (
                    <li key={faq.faq_id} className="mb-4">
                      <Link
                        to={`/faq/${faq.faq_id}`}
                        className="text-heading d-flex justify-content-between align-items-center"
                      >
                        <span className="text-truncate me-1">
                          {faq.question}
                        </span>
                        <i className="tf-icons ri-arrow-right-s-line ri-20px scaleX-n1-rtl text-muted" />
                      </Link>
                    </li>
                  ))
                ) : (
                  <li>No FAQs available</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <LandingFooter />
    </div>
  );
};

export default FaqDetails;
