import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../component/Footer";
import Header from "../component/Header";
import config from "../../../component/config";
import SubHeader from "../component/SubHeader";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { Toast } from "primereact/toast";
import { useNavigate, Link } from "react-router-dom";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { VirtualScroller } from "primereact/virtualscroller";
import { Tooltip } from "primereact/tooltip";
import CardLinks from "../../../component/CardLinks";
const userId = localStorage.getItem("userId");
const Position = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMap,setLoadingMap]=useState({});
const [loadingMaps,setLoadingMaps]=useState({});
  const [Isloading, setIsloading] = useState(false);
  const [Isloadings, setIsloadings] = useState(false);
 const [Isloadingss, setIsloadingss] = useState(false);
 const [loadingRefresh, setLoadingRefresh] = useState(false);
  const [loadingRefreshes, setLoadingRefreshes] = useState(false);
  const toast = useRef(null);
  const [activeTab, setActiveTab] = useState("all");
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);
const [data, setData] = useState([]);
  const [filteredeData, setFilteredeData] = useState(data); 
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
useEffect(() => {
    filterData(searchTerm);
  }, [searchTerm]); 

  const filterData = (searchTerm) => {
    const filtered = filteredeData.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredeData(filtered);
  };

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
  };

  const filteredData = data.filter((item) => {
    const nameMatch = item.name
      .toLowerCase()
      .includes(globalFilter.toLowerCase());
    const instrumentsMatch = item.instruments.some((instrument) =>
      instrument.toLowerCase().includes(globalFilter.toLowerCase())
    );
    return nameMatch || instrumentsMatch;
  });
 const handleRefreshes = async () => {
    setLoadingRefreshes(true);
    try {
      const authToken = localStorage.getItem("authToken"); 
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        navigate("/commonlogin"); 
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, 
        },
        body: JSON.stringify({ teacher_id: userId }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_order_placed_student_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.st === 1) {
        setData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
        setFilteredeData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.msg || "Data refreshed successfully",
          life: 3000,
        });
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Warning: Data may not be refreshed",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to fetch data",
          life: 3000,
        });
      } else {
        console.error("No data found in get_order_placed_student_list");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); 
      } else {
        console.error("Error fetching order placed student list:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Network error: Failed to fetch data",
          life: 3000,
        });
      }
    } finally {
      setLoadingRefreshes(false);
    }
  };

  const getOrderPlacedStudentList = async () => {
    try {
      setLoading(true);

      const authToken = localStorage.getItem("authToken"); 
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        navigate("/commonlogin"); 
        return;
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
        },
        body: JSON.stringify({ teacher_id: userId }),
      };

      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_order_placed_student_list`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.data && data.data.length > 0) {
        setData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
        setFilteredeData(
          data.data.map((student) => ({
            name: student.name,
            user_id: student.user_id,
            instruments: student.orders.map((order) => order.symbol),
            lots: student.orders.map((order) => ({
              size: order.buy_lots,
              price: order.buy_price,
              status: order.sell_lots > 0 ? "completed" : "pending",
              sell: order.sell_lots,
              sellPrice: order.sell_price,
              symboltoken: order.token,
            })),
          }))
        );
      } else {
        console.error("No data found in get_order_placed_student_list");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        console.error("Error fetching order placed student list:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExitAllInstruments = async (student_id) => {
    setLoadingMaps((prev)=>({...prev,[student_id]:true}));

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({
          student_id: student_id,
        }),
      };

      const response = await fetch(
        " https://www.apbacked.xyz/exit_students_all_instrument/",
        requestOptions
      );

      if (!response.ok) {
        if (response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate("/commonlogin"); 
          return;
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Exit all instruments response:", responseData);

      await getPositionList();
      await getOrderPlacedStudentList();
    } catch (error) {
      console.error("Error exiting all student instruments:", error);
    } finally {
      setLoadingMaps((prev)=>({...prev,[student_id]:false}));
    }
  };


  const handleExit = async (studentId, instrumentData) => {
    const key = `${studentId}-${instrumentData.tradingsymbol}`;
    console.log(`Setting loader for key: ${key}`);

    setLoadingMap(prev => ({...prev, [key]: true})); 

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Add this line to set the content type to JSON
        },
        body: JSON.stringify({
          student_id: studentId,
          instrument_data: instrumentData,
        }),
      };

      const response = await fetch(
        "https://www.apbacked.xyz/exit_student_instrument/",
        requestOptions
      );

      if (!response.ok) {
        if (response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate("/commonlogin"); // Redirect to common login screen on 401 error
          return;
        }
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Exit student instrument response:", responseData);

      await getPositionList();
     
      await getOrderPlacedStudentList();
    } catch (error) {
      console.error("Error exiting student instrument:", error);
    } finally {
      setLoadingMap(prev => ({...prev, [key]: false}));
    }
  };


  useEffect(() => {
    getOrderPlacedStudentList();
  }, []);

 

  const [positionData, setPositionData] = useState({
    openPositions: [],
    closedPositions: [],
    totalRealisedPnl: 0.0,
    totalUnrealisedPnl: 0.0,
  });
  const [selectedPositions, setSelectedPositions] = useState(new Set());
  const [allSelected, setAllSelected] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0); // New state variable
  const [showActionColumn, setShowActionColumn] = useState(false);

  const getPositionList = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        // Redirect to common login screen or handle unauthorized access
        navigate("/commonlogin"); // Example using React Router to navigate to common login screen
        return;
      }

      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      let openPositionRows = [];
      let closedPositionRows = [];
      let totalRealisedPnl = 0.0;
      let totalUnrealisedPnl = 0.0;

      data.position_list.forEach((item) => {
        const dailyDiffPercent = (
          ((item.ltp - item.close) / item.close) *
          100
        ).toFixed(2);

        const netqty = parseInt(item.netqty);
        const lotsize = parseInt(item.lotsize);
        const lotquantity = parseInt(netqty / lotsize);
        const ordertype = netqty > 0 ? "BUY" : "SELL";
        const color_ordertype = netqty > 0 ? "text-success" : "text-danger";
        const avg_price =
          netqty > 0 ? item.totalbuyavgprice : item.totalsellavgprice;
        const color_pnl = item.pnl > 0 ? "text-success" : "text-danger";
        const color_diff_percent =
          dailyDiffPercent > 0 ? "text-success" : "text-danger";

        if (netqty !== 0) {
          totalUnrealisedPnl += parseFloat(item.unrealised);
          const dropdownOptions = Array.from(
            { length: lotquantity },
            (_, index) => lotquantity - index
          );
          openPositionRows.push({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            producttype: item.producttype,
            optiontype: item.optiontype,
            exchange: item.exchange,
            lotsize: item.lotsize,
            netqty: item.netqty,
            ltp: item.ltp,
            close: item.close,
            totalbuyavgprice: item.totalbuyavgprice,
            totalsellavgprice: item.totalsellavgprice,
            pnl: item.pnl,
            dailyDiffPercent,
            ordertype,
            color_ordertype,
            color_pnl,
            color_diff_percent,
            dropdownOptions,
            lotquantity,
            avg_price,
          });
        } else {
          totalRealisedPnl += parseFloat(item.realised);
          closedPositionRows.push(
            <tr key={item.tradingsymbol}>
              <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>
                <span className="instrument_symbol">{item.tradingsymbol}</span>{" "}
                <span className="d-none instrument_token">
                  {item.symboltoken}
                </span>
              </td>
              <td style={{ wordWrap: "break-word", maxWidth: "110px" }}>
                {item.producttype}
              </td>
              <td className="text-center">{item.optiontype}</td>
              <td className="text-center">{item.exchange}</td>
              <td>
                0 Lots{" "}
                <span className="text-body-tertiary">
                  (1 Lot = {item.lotsize})
                </span>
              </td>
              <td>
                <span className={color_diff_percent}>{item.ltp} ₹</span>{" "}
                <span className="text-body-tertiary">
                  ({dailyDiffPercent}%)
                </span>
              </td>
              <td>{item.totalbuyavgprice} ₹</td>
              <td>{item.totalsellavgprice} ₹</td>
              <td className={color_pnl}>{item.pnl} ₹</td>
            </tr>
          );
        }
      });

      setPositionData({
        openPositions: openPositionRows,
        closedPositions: closedPositionRows,
        totalRealisedPnl,
        totalUnrealisedPnl,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        console.error("Error occurred while fetching position list:", error);
      }
    } finally {
      setLoading(false); // Ensure loading state is set to false
    }
  };
  useEffect(() => {
    getPositionList();
  }, []);
  const handleCheckboxChange = (tradingsymbol, symboltoken, lotquantity) => {
    const key = JSON.stringify({ tradingsymbol, symboltoken, lotquantity });
    setSelectedPositions((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(key)) {
        newSelected.delete(key);
      } else {
        newSelected.add(key);
      }
      setSelectedCount(newSelected.size);
      return newSelected;
    });
  };
  
  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelectedPositions(new Set());
      setSelectedCount(0);
      setAllSelected(false);
      setShowActionColumn(false);
    } else {
      const newSelected = new Set();
      positionData.openPositions.forEach((item) => {
        if (item.netqty !== 0) {
          const key = JSON.stringify({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            lotquantity: parseInt(item.netqty / item.lotsize),
          });
          newSelected.add(key);
        }
      });
      setSelectedPositions(newSelected);
      setSelectedCount(newSelected.size);
      setAllSelected(true);
      setShowActionColumn(true);
    }
  };
  

  const isChecked = (tradingsymbol, symboltoken, lotquantity) => {
    const key = JSON.stringify({ tradingsymbol, symboltoken, lotquantity });
    return selectedPositions.has(key);
  };

  useEffect(() => {
    getPositionList();
  }, []);

  const exitPosition = async () => {
    setIsloadingss(true);
    try {
      const userId = localStorage.getItem("userId");
      const order_data = Array.from(selectedPositions).map((positionString) => {
        const { tradingsymbol, symboltoken, lotquantity } = JSON.parse(positionString);
        

        return {
          instrument: tradingsymbol,
          symboltoken,

        };
      });

      const response = await fetch(
        `https://www.apbacked.xyz/exit_position/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            teacher_id: userId,
            order_data,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Position exited successfully:", data);
      console.log("Selected count:", selectedCount); // Display the count
      if (data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Position exited successfully",
          life: 3000,
        });
      }
      await getOrderPlacedStudentList();
     await getPositionList();
    } catch (error) {
      console.error("Error occurred while exiting position:", error);
    } finally {
      setIsloadingss(false);
    }
  };

  const handleExitAllPending = async () => {

    const userId = localStorage.getItem("userId");
    setIsloadings(true);

    try {
      const response = await fetch(
        `https://www.apbacked.xyz/exit_all_student_pending/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Add this line to set the content type to JSON
          },
          body: JSON.stringify({
            teacher_id: userId,
          }),
        }
      );

      if (!response.ok) {

        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Exited all pending positions successfully:", data);


      await getOrderPlacedStudentList();
      await getPositionList();
    } catch (error) {
      console.error("Error occurred while exiting all pending positions:", error);
    } finally {
      setIsloadings(false); // Ensure loading state is set to false
    }
  };


  const handleRefresh = async () => {
    setLoadingRefresh(true);
    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from localStorage
      if (!authToken) {
        console.error("Auth token not found in localStorage");
        // Redirect to common login screen or handle unauthorized access
        navigate("/commonlogin"); // Example using React Router to navigate to common login screen
        return;
      }

      const userId = localStorage.getItem("userId");
      const response = await fetch(
        `${config.apiDomain}/api/teacher/get_position_list`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`, // Include the auth token in the headers
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (data.st === 1) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: data.msg || "Data fetched successfully",
          life: 3000,
        });
      } else if (data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: data.msg || "Warning: Data may not be fully refreshed",
          life: 3000,
        });
      } else if (data.st === 3 || data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data.msg || "Failed to fetch data",
          life: 3000,
        });
      } else {
        console.error("Unexpected status code:", data.st);
      }

      let openPositionRows = [];
      let closedPositionRows = [];
      let totalRealisedPnl = 0.0;
      let totalUnrealisedPnl = 0.0;

      data.position_list.forEach((item) => {
        const dailyDiffPercent = (
          ((item.ltp - item.close) / item.close) *
          100
        ).toFixed(2);

        const netqty = parseInt(item.netqty);
        const lotsize = parseInt(item.lotsize);
        const lotquantity = parseInt(netqty / lotsize);
        const ordertype = netqty > 0 ? "BUY" : "SELL";
        const color_ordertype = netqty > 0 ? "text-success" : "text-danger";
        const avg_price =
          netqty > 0 ? item.totalbuyavgprice : item.totalsellavgprice;
        const color_pnl = item.pnl > 0 ? "text-success" : "text-danger";
        const color_diff_percent =
          dailyDiffPercent > 0 ? "text-success" : "text-danger";

        if (netqty !== 0) {
          totalUnrealisedPnl += parseFloat(item.unrealised);

          openPositionRows.push({
            tradingsymbol: item.tradingsymbol,
            symboltoken: item.symboltoken,
            producttype: item.producttype,
            optiontype: item.optiontype,
            exchange: item.exchange,
            lotsize: item.lotsize,
            netqty: item.netqty,
            ltp: item.ltp,
            close: item.close,
            totalbuyavgprice: item.totalbuyavgprice,
            totalsellavgprice: item.totalsellavgprice,
            pnl: item.pnl,
            dailyDiffPercent,
            ordertype,
            color_ordertype,
            color_pnl,
            color_diff_percent,

            lotquantity,
            avg_price,
          });
        } else {
          totalRealisedPnl += parseFloat(item.realised);
          closedPositionRows.push(
            <tr key={item.tradingsymbol}>
              <td style={{ wordWrap: "break-word", maxWidth: "100px" }}>
                <span className="instrument_symbol">{item.tradingsymbol}</span>{" "}
                <span className="d-none instrument_token">
                  {item.symboltoken}
                </span>
              </td>
              <td style={{ wordWrap: "break-word", maxWidth: "110px" }}>
                {item.producttype}
              </td>
              <td className="text-center">{item.optiontype}</td>
              <td className="text-center">{item.exchange}</td>

              <td>
                0 Lots{" "}
                <span className="text-body-tertiary">
                  (1 Lot = {item.lotsize})
                </span>
              </td>
              <td>
                <span className={color_diff_percent}>{item.ltp} ₹</span>{" "}
                <span className="text-body-tertiary">
                  ({dailyDiffPercent}%)
                </span>
              </td>
              <td>{item.totalbuyavgprice} ₹</td>
              <td>{item.totalsellavgprice} ₹</td>
              <td className={color_pnl}>{item.pnl} ₹</td>
            </tr>
          );
        }
      });

      setPositionData({
        openPositions: openPositionRows,
        closedPositions: closedPositionRows,
        totalRealisedPnl,
        totalUnrealisedPnl,
      });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        console.error("Failed to fetch data:", error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while fetching data",
          life: 3000,
        });
      }
    } finally {
      setLoadingRefresh(false);
    }
  };

  // const handleRefreshes = () => {
  //   getOrderPlacedStudentList();
  // };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleLotSizeChange = (event, tradingsymbol, symboltoken) => {
    const selectedLotSize = parseInt(event.target.value, 10);

    setSelectedPositions((prevSelected) => {
      const updatedSelected = new Set(prevSelected);

      // Create the key for the item that needs its lot size updated
      const key = JSON.stringify({ tradingsymbol, symboltoken });

      // Check if the key exists in the selected positions
      if (updatedSelected.has(key)) {
        // Remove the existing item with the old lot size
        updatedSelected.delete(key);

        // Add the updated item with the new lot size
        updatedSelected.add(
          JSON.stringify({
            tradingsymbol,
            symboltoken,
            lotquantity: selectedLotSize,
          })
        );
      }

      return updatedSelected;
    });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    // Market open from 9:15 AM to 3:15 PM
    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    // Check if today is Saturday (6) or Sunday (0)
    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    // Check if the current time is within market hours
    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  return (
    <>
      <Header />
      <SubHeader />
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="mt-3 container-xxl">
        {isMarketOpen() ? (
          <div
            className="text-center "
            style={{
              border: "2px solid green",
              padding: "10px",
              color: "green",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Open
          </div>
        ) : (
          <div
            className="text-center"
            style={{
              border: "2px solid orange",
              padding: "10px",
              color: "orange",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Closed
          </div>
        )}
      </div>

      <div className="container-xxl flex-grow-1 container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Positions
            </li>
          </ol>
        </nav>
        <div className="row ">
          <div className="col-xl-9 col-lg-9 col-md-7 col-12">


            <div className="card mb-3" style={{ overflow: "hidden" }}>
              <div className="col text-start mb-5 mt-5 ms-5">
                <button
                  onClick={handleBack}
                  className="btn rounded-pill btn-outline-secondary btn-xs"
                >
                  <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
                </button>
              </div>
              <div className=" text-center">
                <div className="row">
                  <div className="col-md-4 mb-3">
                    <h4
                      className={
                        positionData.totalUnrealisedPnl < 0
                          ? "text-danger"
                          : positionData.totalUnrealisedPnl > 0
                            ? "text-success"
                            : "text-muted"
                      }
                    >
                      {positionData.totalUnrealisedPnl === 0
                        ? "0.00"
                        : positionData.totalUnrealisedPnl.toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Unrealised Profit & Loss</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <h4
                      className={
                        positionData.totalRealisedPnl < 0
                          ? "text-danger"
                          : positionData.totalRealisedPnl > 0
                            ? "text-success"
                            : "text-muted"
                      }
                    >
                      {positionData.totalRealisedPnl === 0
                        ? "0.00"
                        : positionData.totalRealisedPnl.toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Realised Profit & Loss</p>
                  </div>
                  <div className="col-md-4 mb-3">
                    <h4
                      className={
                        positionData.totalRealisedPnl +
                          positionData.totalUnrealisedPnl ===
                          0
                          ? "text-muted"
                          : positionData.totalRealisedPnl +
                            positionData.totalUnrealisedPnl <
                            0
                            ? "text-danger"
                            : "text-success"
                      }
                    >
                      {(
                        positionData.totalRealisedPnl +
                        positionData.totalUnrealisedPnl
                      ).toFixed(2)}{" "}
                      ₹
                    </h4>

                    <p className="mb-0">Total Profit & Loss</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="text-center flex-grow-1 m-0">Open Position</h5>
                <div>
                  {loadingRefresh ? (
                    <i className="custom-target-icon ri-loader-2-line ri-lg mt-  p-text-secondary"></i>
                  ) : (
                    <div>
                      <Tooltip target=".custom-target-icon" />
                      <div className="">
                        <i
                          className="custom-target-icon ri ri-refresh-line ri-lg  p-text-secondary"
                          data-pr-tooltip="Reload"
                          data-pr-position="top"
                          style={{ cursor: "pointer" }}
                          onClick={handleRefresh}
                        ></i>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="table-responsive text-start p-3">
                <div className="d-flex justify-content-end mb-3">
                  <button
                    type="button"
                    className="btn bg-white btn-outline-secondary rounded-pill btn-xs me-3"
                    onClick={handleToggleSelectAll}
                  >
                    {allSelected ? "Unselect All" : "Select All"}
                  </button>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Product Type</th>
                      <th>Option Type</th>
                      <th>Order Type</th>
                      <th>Exchange</th>
                      <th>Lots</th>
                      <th>LTP</th>
                      <th>Avg. Price</th>
                      <th>Profit & Loss</th>
                      {showActionColumn && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {positionData.openPositions.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{ wordWrap: "break-word", maxWidth: "100px" }}
                        >
                          <span className="instrument_symbol">
                            {item.tradingsymbol}
                          </span>{" "}
                          <span className="d-none instrument_token">
                            {item.symboltoken}
                          </span>
                        </td>
                        <td
                          style={{ wordWrap: "break-word", maxWidth: "110px" }}
                        >
                          <span className="instrument_producttype">
                            {item.producttype}
                          </span>
                        </td>
                        <td>{item.optiontype}</td>
                        <td className={item.color_ordertype}>
                          {item.ordertype}
                        </td>
                        <td>
                          <span className="instrument_exchange">
                            {item.exchange}
                          </span>
                        </td>
                        <td className={item.color_pnl}>
                          {item.lotquantity} Lots <br></br>
                          <span className="text-body-tertiary lot_size">
                            (1 Lot = {item.lotsize})
                          </span>
                        </td>
                        <td>
                          <span className={item.color_diff_percent}>
                            {item.ltp} ₹
                          </span>{" "}
                          <span className="text-body-tertiary">
                            ({item.dailyDiffPercent}%)
                          </span>
                        </td>
                        <td>{item.avg_price} ₹</td>
                        <td className={item.color_pnl}>{item.pnl} ₹</td>
                        {showActionColumn && (
                          <td>
                            <div className="d-flex align-items-center">

                            <input
                  type="checkbox"
                  checked={isChecked(item.tradingsymbol, item.symboltoken, parseInt(item.netqty / item.lotsize))}
                  onChange={() => handleCheckboxChange(item.tradingsymbol, item.symboltoken, parseInt(item.netqty / item.lotsize))}
                  id={`${item.tradingsymbol}-checkbox`}
                />
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    {showActionColumn && (
                      <tr>
                        <td colSpan="10">
                          <div className="d-flex justify-content-end">
                            <button
                              type="button"
                              className="btn btn-danger btn-sm rounded"
                              onClick={exitPosition}
                              disabled={Isloadingss || selectedCount == 0}
                            >

                              {Isloadingss ? (
                                <>
                                   <div className="spinner-border me-2" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
                                  Executing {selectedCount} Selected
                                </>
                              ) : (
                                <>

                                  <i className="ri-send-plane-fill ri-lg me-3"></i>
                                  Exit {selectedCount} Selected
                                </>
                              )}

                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-header d-flex align-items-center justify-content-between">
                <h5 className="text-center flex-grow-1 m-0">Closed Position</h5>

                {loadingRefresh ? (
                  <i className=" custom-target-icon ri-loader-line ri-lg ms-3 p-text-secondary"></i>
                ) : (
                  <div>
                    <Tooltip target=".custom-target-icon" />
                    <i
                      className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary"
                      data-pr-tooltip="Refresh"
                      data-pr-position="top"
                      style={{ cursor: "pointer" }}
                      onClick={handleRefresh}
                    ></i>
                  </div>
                )}
              </div>

              <div className="table-responsive text-start p-3">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Instrument</th>
                      <th>Product Type</th>
                      <th>Option Type</th>
                      <th>Exchange</th>
                      <th>Lots</th>
                      <th>LTP</th>
                      <th>Buy Price</th>
                      <th>Sell Price</th>
                      <th>Profit & Loss</th>
                    </tr>
                  </thead>
                  <tbody>{positionData.closedPositions}</tbody>
                </table>
              </div>
            </div>
          </div>
          {filteredData.length > 0 && (
          <div className="col-xl-3 col-lg-3 col-md-5 col-12">
           
            <div className="right-side-content">
              <div className="card">
                <div className="d-flex justify-content-around mt-5">
                  <button
                    type="button"
                    className="btn btn-danger rounded btn-md w-100 ms-5 me-5"
                    onClick={handleExitAllPending}
                    disabled={Isloadings || filteredData.length === 0}
                  >
                    {Isloadings ? (
                      <>
                         <div className="spinner-border me-2" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
                        Executing all Pendings
                      </>
                    ) : (
                      <>

                        <i className="ri-send-plane-fill me-3 "></i> Exit All
                        Pendings
                      </>
                    )}

                  </button>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-between mb-5 me-3">
                    <IconField iconPosition="left">
                      <InputIcon className="ri ri-search-line"></InputIcon>
                      <InputText
                        type="search"
                        placeholder="Search"
                        // value={searchTerm}
                        // onChange={handleSearch}
                        value={globalFilter}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        className="rounded custom-search-box"
                      // onClick={clearSearch}
                      />
                    </IconField>

                    {loadingRefreshes ? (
                      <i className=" custom-target-icon ri-loader-line ri-lg ms-3 mt-3 p-text-secondary"></i>
                    ) : (
                      <div className="mt-3 ms-2">
                        <Tooltip target=".custom-target-icon" />
                        <i
                          className="custom-target-icon ri ri-refresh-line ri-lg  p-text-secondary"
                          data-pr-tooltip="Reload"
                          data-pr-position="top"
                          style={{ cursor: "pointer" }}
                          onClick={handleRefreshes}
                        ></i>
                      </div>
                    )}
                  </div>

                  <hr />

                  <div
                    className="virtual-scroller-container"
                    style={{
                      height: "500px",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <VirtualScroller
                      items={filteredData}
                      itemSize={70} 
                      itemTemplate={(item, index) => (
                        <div key={index} className="border-bottom mb-3 pb-3">
                          <div className="d-flex align-items-start mb-3">
                            <p className="text-start mb-0 fs-5 text-black flex-grow-1">
                              {capitalizeFirstLetter(item.name)}
                            </p>
                            <button
                              type="button"
                              className="btn btn-danger btn-xs btn btn-outline-danger waves-effect "
                              onClick={() =>
                                handleExitAllInstruments(item.user_id)
                              }
                              disabled={loadingMaps[item.user_id]}
                            >
                            {loadingMaps[item.user_id] ? (
                         <div className="spinner-border me-2" role="status">
                         <span className="visually-hidden">Loading...</span>
                       </div>
                    ) : (
                        <i className="ri-send-plane-fill me-2"></i>
                    )}{" "}
                    {loadingMaps[item.user_id] ? "Exit" : "Exit"}
                            </button>
                          </div>

                          {item.lots.map((lot, lotIndex) => {
                const key = `${item.user_id}-${item.instruments[lotIndex]}`;
                return (
                            <div key={lotIndex} className="mb-3">
                              <div className="d-flex align-items-center mb-1">
                                <span className="fs-6">
                                  {item.instruments[lotIndex]}
                                </span>
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="text-start text-primary1">
                                  <strong className="text-muted">Buy:</strong>{" "}
                                  <span
                                    className={
                                      lot.size === 0
                                        ? "text-black"
                                        : "text-success"
                                    }
                                  >
                                    {lot.size} ({lot.price} Rs.)
                                  </span>{" "}
                                </div>
                                <div className="text-end text-primary1">
                                  <strong className="text-muted">Sell:</strong>{" "}
                                  <span
                                    className={
                                      lot.sell === 0
                                        ? "text-black"
                                        : "text-danger"
                                    }
                                  >
                                    {lot.sell} ({lot.sellPrice} Rs.)
                                  </span>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-danger btn-xs btn-outline-danger waves-effect"
                                  onClick={() =>
                                    handleExit(item.user_id, {
                                      tradingsymbol: item.instruments[lotIndex],
                                      symboltoken:
                                        item.lots[lotIndex].symboltoken,
                                    })
                                  }
                                  disabled={loadingMap[key] === true}
                                  >
                                      {loadingMap[key] ? (
                                           <div className="spinner-border me-2" role="status">
                                           <span className="visually-hidden">Loading...</span>
                                         </div>
                                      ) : (
                                          <i className="ri-send-plane-fill"></i>
                                      )}

                                </button>
                              </div>
                            </div>
                             );
                            })}
                        </div>
                      )}
                      delay={250}
                      className="border-1 surface-border border-round"
                      style={{ height: "500px" }} 
                    />
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          )}
          <CardLinks></CardLinks>
        </div>
      </div>
      {/* </div> */}
      <Footer />
    </>
  );
};

export default Position;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; 
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};




