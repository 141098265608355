import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter, BrowserRouter } from "react-router-dom";


import 'primereact/resources/themes/saga-blue/theme.css'; // PrimeReact theme
import 'primereact/resources/primereact.min.css'; // PrimeReact core CSS
import 'primeicons/primeicons.css'; // PrimeIcons CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import 'remixicon/fonts/remixicon.css';


import "./assets/css/demo.css";

import "./assets/vendor/css/rtl/core.css";
// import "./assets/vendor/css/rtl/core-dark.css"
import "./assets/vendor/css/pages/front-page.css";

import "./assets/vendor/css/pages/page-profile.css";
import "./assets/vendor/css/pages/front-page-landing.css";

import "./assets/vendor/css/pages/page-auth.css";
import "./assets/vendor/libs/animate-css/animate.css";
import "./assets/vendor/css/rtl/theme.css";
// import "./assets/vendor/css/rtl/theme-dark.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./assets/vendor/libs/typeahead-js/typeahead.css";

import "./assets/vendor/libs/@form-validation/form-validation.css";
import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "./assets/vendor/libs/node-waves/node-waves.css";



//import "./assets/vendor/css/pages/app-chat.css"
// import"./assets/js/front-page-landing.js"
import "./assets/vendor/css/pages/app-chat.css"
import "./assets/vendor/css/pages/page-misc.css"
import "./assets/vendor/css/pages/app-ecommerce-dashboard.css"
import "./assets/vendor/css/pages/page-auth.css";

import "./assets/vendor/css/pages/front-page.css"
import "./assets/vendor/css/pages/front-page-pricing.css"
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
