import React from 'react'

const CardLinks = () => {
  return (
   
       <div className="row my-2">
  <div className="col text-start">
    <a href="/teacher/feature_request" target="_blank" className="text-secondary me-3">
    <i className="ri-sparkling-line "></i>  Feature Request
    </a>
  </div>
  <div className="col text-end">
    <a href="/contact" target="_blank" className="text-secondary me-3">
      <i className="ri-customer-service-2-line"></i> Support
    </a>
    <a href="/help_center" target="_blank" className="text-secondary">
      <i className="ri-questionnaire-line"></i> Help Center
    </a>
  </div>

    </div>
  )
}

export default CardLinks
