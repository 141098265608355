import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate } from "react-router-dom";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog"; // Import ConfirmDialog
import CardLinks from "../../../component/CardLinks";

const ManageTeacher = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toast = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [userIdToToggle, setUserIdToToggle] = useState(null);
  const [activeStatusCount, setActiveStatusCount] = useState(0);
  const [tradeStatusCount, setTradeStatusCount] = useState(0);
const[ totalCount, setTotalCount]=useState(0);
const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);
  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);
  
  const handleToggleTrade = async (userId) => {
    try {
      const authToken = localStorage.getItem("authToken");

      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_switch`,
        { teacher_id: userId },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.st === 1) {
        const updatedData = data.map((item) => {
          if (item.user_id === userId) {
            return { ...item, trade_status: !item.trade_status };
          }
          return item;
        });

        setData(updatedData);

        // Display success toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Trade status updated successfully!",
          life: 3000,
        });
        await handleRefresh();
      }
    } catch (error) {
      console.error("Error switching trade status:", error);

      if (error.response && error.response.status === 401) {
        console.log("Unauthorized access detected. Redirecting to login...");
        navigate("/commonlogin");
      }

      // Display error toast
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.msg || "Failed to update trade status",
        life: 3000,
      });
    }
  };

  const handleToggle = async (userId) => {
    try {
      const authToken = localStorage.getItem("authToken");

      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await fetch(
        `${config.apiDomain}/api/admin/teacher_active_switch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: userId }),
        }
      );
      const responseData = await response.json();

      if (response.ok && responseData.st === 1) {
        const updatedData = data.map((item) => {
          if (item.user_id === userId) {
            return { ...item, active_status: !item.active_status };
          }
          return item;
        });

        setData(updatedData);

        // Display success toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Active status updated successfully!",
          life: 3000,
        });
        await handleRefresh();
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";

        // Display error toast
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem("userId");

      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.post(
        `${config.apiDomain}/api/admin/manage_teachers/listview`,
        {
          admin_id: userId,  // Include admin_id in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        const totalCount = sortedData.length;
        const activeCount = sortedData.filter(
          (item) => item.active_status
        ).length;
        const tradeCount = sortedData.filter(
          (item) => item.trade_status
        ).length;

        setData(sortedData);
        setActiveStatusCount(activeCount);
        setTradeStatusCount(tradeCount);
        setTotalCount(totalCount); 
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    try {
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
      const userId = localStorage.getItem("userId");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.post(
        `${config.apiDomain}/api/admin/manage_teachers/listview`,
        {
          admin_id: userId,  // Include admin_id in the request body
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        const sortedData = response.data.data.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        const totalCount = sortedData.length;
        const activeCount = sortedData.filter(
          (item) => item.active_status
        ).length;
        const tradeCount = sortedData.filter(
          (item) => item.trade_status
        ).length;

        setData(sortedData);
        setActiveStatusCount(activeCount);
        setTradeStatusCount(tradeCount);
        setTotalCount(totalCount); 
        const errorMsg = response.data.msg || "Success: Server Error";
        setError(new Error(errorMsg));

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && response.data.st === 2) {
        const errorMsg = response.data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (
        response.data &&
        (response.data.st === 3 || response.data.st === 4)
      ) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized access, redirecting to common login screen"
        );
        navigate("/commonlogin"); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (rowData) => {
    try {
      const authToken = localStorage.getItem("authToken");
      const userId = localStorage.getItem("userId");

      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await fetch(
        `${config.apiDomain}/api/admin/manage_teachers/delete`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: rowData.user_id ,admin_id:userId}),
        }
      );
      const responseData = await response.json();

      if (response.ok && responseData.st === 1) {
        const updatedData = data.filter(
          (item) => item.user_id !== rowData.user_id
        );

        setData(updatedData);

        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Teacher deleted successfully!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error deleting teacher:", error);

      if (error.response && error.response.status === 401) {
        console.log("Unauthorized access detected. Redirecting to login...");
        navigate("/commonlogin");
      }

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.msg || "Failed to delete teacher",
        life: 3000,
      });
    }
  };

  const confirmDelete = (rowData) => {
    confirmDialog({
      message: "Are you sure you want to delete this teacher?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-info",
      accept: () => handleDelete(rowData),
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const subscriptionBodyTemplate = (rowData) => {
    const subscription = rowData.active_subscription;

    if (!subscription) {
      return <span className="text-danger">No Subscription</span>;
    }

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-secondary text-capitalize fs-5">
            <strong>{subscription.subscription_name}</strong>
          </div>
          <span className="badge rounded-pill bg-label-secondary text-capitalize">
            {subscription.plan_name}
          </span>
        </div>

        {subscription.remaining_days != null && (
          <>
            <div className="text-muted mt-1">
              Completed: {subscription.completed_days} Days
            </div>

            <div className="progress">
              <div
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${
                    (subscription.completed_days /
                      subscription.service_period) *
                    100
                  }%`,
                }}
                aria-valuenow={
                  (subscription.completed_days / subscription.service_period) *
                  100
                }
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </>
        )}
      </div>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <div className="row">
        <div className="col-3 mt-6 ">
          <label
            className={`switch switch-square ${
              rowData.active_status ? "bg-success" : ""
            }`}
          >
            <input
              type="checkbox"
              className="switch-input"
              checked={rowData.active_status}
              onChange={() => handleToggle(rowData.user_id)}
            />
            <span
              className={`switch-toggle-slider ${
                rowData.active_status ? "bg-success" : ""
              }`}
            >
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
          </label>
        </div>

        <div className="col-8 mt-3">
          <div className="text-capitalize mb-1 fs-5">
            <strong>{rowData.name}</strong>
          </div>
          <div className="text-muted">{rowData.mobile}</div>
        </div>
      </div>
    );
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const lotSizeBodyTemplate = (rowData) => {
    return `${rowData.lot_size_limit} Lot`;
  };


  const commissionBodyTemplate = (rowData) => {
    return `${rowData.commission}%`;
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const confirmToggle = async () => {
    try {
     
      await handleToggle(userIdToToggle);
      setShowModal(false);
      setUserIdToToggle(null);
    } catch (error) {
      console.error("Error confirming toggle:", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to toggle status",
        life: 3000,
      });
    }
  };

  const tradeStatusBodyTemplate = (rowData) => {
    return (
      <div>
        {rowData.broker_status ? (
          <label
            className={`switch switch-square ${
              rowData.trade_status ? "bg-success" : ""
            }`}
          >
            <input
              type="checkbox"
              className="switch-input"
              checked={rowData.trade_status}
              onChange={() => handleToggleTrade(rowData.user_id)}
            />
            <span
              className={`switch-toggle-slider ${
                rowData.trade_status ? "bg-success" : ""
              }`}
            >
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
          </label>
        ) : (
          <span></span>
        )}
      </div>
    );
  };

  
  const brokerNameMap = {
    angle_one: "AngelOne",
    
  };

  const getBrokerDisplayName = (brokerName) => {
    return brokerNameMap[brokerName] || brokerName; // Fallback to brokerName if no mapping found
  };

  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y" align="center">
      <nav aria-label="breadcrumb">
  <div className="d-flex justify-content-between align-items-center">
    <ol className="breadcrumb breadcrumb-style1 text-black mb-3">
      <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i class="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Manage Teacher
            </li>
          </ol>
          <div className="mb-3">
     
      {brokerConnectionStatus && (
        <Link to="/admin/profile">
          <span className="badge rounded-pill bg-label-dark me-3">
            Broker: <i className="ri-check-line"></i>
          </span>
        </Link>
      )}
      </div>
    </div>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-4 text-center">
              <h5 className="mb-0">Manage Teacher</h5>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>
              {loading ? (
                <i className="custom-target-icon ri-loader-2-line ri-lg mt-1 ms-3 p-text-secondary" />
              ) : (
                <div className="mt-1">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                    data-pr-tooltip="Reload"
                    onClick={handleRefresh}
                    data-pr-position="top"
                  />
                </div>
              )}
            </div>
            
          </div>
          <DataTable
            value={data}
            rows={20}
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="custom-column-border p-datatable-rounded"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rowsPerPageOptions={[10, 20, 50]}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            paginator
           
            body={nameBodyTemplate}
          >
            <Column
              align={"center"}
              className="custom-column-border"
              header="No."
              body={(rowData, { rowIndex }) => rowIndex + 1}
            ></Column>
            <Column
              align={"center"}
              className="custom-column-border"
              field="name"
              header={
                <div>
                  <span>Name</span>
                  <span className="text-muted me-1"> ({activeStatusCount}/{totalCount})
                  </span>
                </div>
              }
              body={nameBodyTemplate}
            ></Column>

            <Column
              align={"center"}
              className="custom-column-border"
              field="subscription"
              header="Subscription"
              body={subscriptionBodyTemplate}
            ></Column>
            <Column
              align={"center"}
              className="custom-column-border"
              field="lot_size_limit"
              header="Lot Size Limit"
              body={lotSizeBodyTemplate}
            ></Column>
            <Column
              align={"center"}
              className="custom-column-border"
              field="commission"
              header="Commission"
              body={commissionBodyTemplate}
            ></Column>

            <Column
              align={"center"}
              className="custom-column-border"
              header={
                <div>
                  <span>Broker Conn. Status</span>
                  <span className="text-muted me-1"> ({tradeStatusCount}/{totalCount})</span>
                </div>
              }
              body={(rowData) => (
                <div
                  className={classNames({
                    "text-success": rowData.broker_status,
                    "text-danger": !rowData.broker_status,
                  })}
                >
                  {rowData.broker_status ? (
                    <div className="row">
                      <div className="col-4 mt-3">
                        {tradeStatusBodyTemplate(rowData)}
                      </div>

                      <div className="col-8">
                        <div>
                          <i className="ri-shield-check-line"></i>{" "}
                          {getBrokerDisplayName(rowData.broker_name)}
                        </div>
                        <div className="text-muted mt-1">
                          {rowData.broker_account_balance || 0} Rs
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>Broker Not Connected</div>
                    </>
                  )}
                </div>
              )}
            />
         

           
            <Column
              align={"center"}
              className="custom-column-border"
              header="Actions"
              body={(rowData) => (
                <>
                  <Link
                    to={`/admin/manage_teacher/view_teacher/${rowData.user_id}`}
                    state={{ teacherId: rowData.user_id }}
                  >
                    <button className="btn btn-info me-3 custom-btn-action1">
                      <i className="ri-gallery-view-2 ri-lg"></i>
                    </button>
                  </Link>
                 
                  <button
                    className="btn btn-danger active text-align custom-btn-action1"
                    onClick={() => confirmDelete(rowData)}
                  >
                    <i className="ri-close-large-line ri-lg"></i>
                  </button>
                </>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
      <ConfirmDialog
        visible={showModal}
        onHide={() => setShowModal(false)}
        header="Confirm"
        message="Are you sure you want to toggle this teacher's status?"
        icon="pi pi-exclamation-triangle"
        acceptLabel="Yes"
        rejectLabel="No"
        accept={confirmToggle}
        reject={() => setShowModal(false)}
      />
      <ConfirmDialog />
    </>
  );
};

export default ManageTeacher;
