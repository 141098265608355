import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import axios from "axios";
import config from "../../../component/config";

import Footer from "../../../component/Footer";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import CardLinks from "../../../component/CardLinks";

const Holding = () => {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [backClicked, setBackClicked] = useState(false);
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const fetchData = async () => {
    const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId || !authToken) {
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/get_all_holdings`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data.st === 1 && response.data.data) {
        setData(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleRefresh = async () => {
    setLoading(true);
    setError(null);
    const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId || !authToken) {
      setLoading(false);
      return;
    }
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/get_all_holdings`,
        {
          user_id: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        const errorMsg = response.data.msg || "Success";
        setData(response.data.data); // Assuming response.data.data is an array to set in DataTable
  
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && response.data.st === 2) {
        const errorMsg = response.data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
     
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? "even-row" : "odd-row";
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };

  return (
    <>
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="container-xxl container-p-y">
        <Toast ref={toast} />
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Holding
            </li>
          </ol>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button
              onClick={handleBack}
              className="btn rounded-pill btn-outline-secondary btn-xs"
            >
              <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
            </button>

            <h5 className="mb-0 mx-auto">Holding</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
           
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
                                          <i className=" custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary"

                strokeWidth="5"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i
                  className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                  data-pr-tooltip="Refresh"
                  onClick={handleRefresh}
                  data-pr-position="top"
                  
                ></i>
              </div>
            )}
          </div>
          <DataTable
            
           className="custom-column-border text-center"
            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column
              align="center"
              className="custom-column-border"
              field="tradingsymbol"
              header="Symbols"
              
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="quantity"
              header="Quantity"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="exchange"
              header="Exchange"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="ltp"
              header="LTP"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="averageprice"
              header="Average Price"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="profitandloss"
              header="Profit and Loss"
            ></Column>
            <Column
              align="center"
              className="custom-column-border"
              field="pnlpercentage"
              header="P&L Percentage"
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};

export default Holding;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};

// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";
// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";

// // Constants for WebSocket
// const WSS_URL = 'wss://api-feed.dhan.co';
// const FEED_REQUEST_CODE = 11;

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const navigate = useNavigate();
//   const toast = useRef(null);
//   const websocket = useRef(null);
  
//   // Fetch initial data
//   const fetchData = async () => {
//     const userId = localStorage.getItem("userId");
//     const authToken = localStorage.getItem("authToken");

//     if (!userId || !authToken) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         { user_id: userId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.st === 1 && response.data.data) {
//         setData(response.data.data);
//         // Subscribe to WebSocket with fetched data
//         subscribeToLTP(response.data.data);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       if (error.response && error.response.status === 401) {
//         navigate("/commonlogin");
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const subscribeToLTP = (holdings) => {
//     if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
//       console.log("WebSocket is not open");
//       return;
//     }
  
//     holdings.forEach((holding) => {
//       console.log(`Subscribing to symbol: ${holding.tradingsymbol}`);
//       websocket.current.send(
//         JSON.stringify({
//           type: "subscribe",
//           symbol: holding.tradingsymbol,
//         })
//       );
//     });
//   };

//   useEffect(() => {
//     const connectWebSocket = () => {
//       websocket.current = new WebSocket(WSS_URL);

//       websocket.current.onopen = () => {
//         console.log("WebSocket connection established");
//         authorizeWebSocket();
//       };

//       websocket.current.onmessage = (event) => {
//         const message = JSON.parse(event.data);
//         console.log("WebSocket message received:", message);

//         if (message.type === "ltp_update") {
//           setData((prevData) =>
//             prevData.map((holding) =>
//               holding.tradingsymbol === message.symbol
//                 ? { ...holding, ltp: message.ltp }
//                 : holding
//             )
//           );
//         }
//       };

//       websocket.current.onclose = () => {
//         console.log("WebSocket connection closed");
//       };

//       websocket.current.onerror = (error) => {
//         console.error("WebSocket error:", error);
//       };
//     };

//     connectWebSocket();

//     return () => {
//       if (websocket.current) {
//         websocket.current.close();
//       }
//     };
//   }, []);

//   const authorizeWebSocket = () => {
//     const apiAccessToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzIzMjY3ODcyLCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTEwMzcwNzAwNiJ9.LidpAzpuoZ5Ug7wei37M3akszp3W0waNhHuBVdb27Q8G_3D1ONRVh8e0Z4gJYd8gsehjhvObaLbWwLJnb7kQyw".padEnd(500, '\0');
//     const authenticationType = "2P";
//     const payload = apiAccessToken + authenticationType;

//     const messageLength = 83 + apiAccessToken.length + authenticationType.length;
//     const clientIdPadded = "1103707006".padEnd(30, '\0');
//     const header = createHeader(FEED_REQUEST_CODE, messageLength, clientIdPadded);
//     const authorizationPacket = new Uint8Array([...header, ...new TextEncoder().encode(payload)]);

//     websocket.current.send(authorizationPacket);
//     subscribeToInstruments();
//   };

//   const createHeader = (feedRequestCode, messageLength, clientId) => {
//     const header = new Uint8Array(83);
//     new DataView(header.buffer).setUint8(0, feedRequestCode);
//     new DataView(header.buffer).setUint16(1, messageLength, true);
//     new TextEncoder().encodeInto(clientId, header, 3);
//     return header;
//   };

//   const subscribeToInstruments = () => {
//     const subscriptionPacket = createSubscriptionPacket(data.map(d => d.tradingsymbol), FEED_REQUEST_CODE);
//     websocket.current.send(subscriptionPacket);
//   };

//   const createSubscriptionPacket = (instruments, feedRequestCode) => {
//     // Create the subscription packet based on your requirements
//     // You need to implement this according to your protocol
//     return new Uint8Array(); // Placeholder
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const rowClassName = (rowData, rowIndex) => {
//     return rowIndex % 2 === 0 ? "even-row" : "odd-row";
//   };

//   const getButtonVariant = () => {
//     const now = new Date();
//     const hours = now.getHours();

//     if (hours === 9) {
//       return "success"; // Green color for 9:15 AM
//     } else if (hours === 15) {
//       return "danger"; // Red color for 3:15 PM
//     }
//     return "secondary"; // Default color
//   };

//   useEffect(() => {
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <Modal
//         show={showPopup}
//         onHide={handleClosePopup}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Market Status</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>The market is now open or closed!</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <div className="card">
//           <h5 className="card-header">Holdings</h5>
//           <div className="table-responsive text-nowrap">
            
//             <DataTable
//               value={data}
//               paginator
//               rows={10}
//               globalFilter={globalFilter}
//               emptyMessage="No data found"
//               className="p-datatable-gridlines"
//               loading={loading}
//               rowClassName={rowClassName}
//             >
//               <Column field="symbol" header="Symbol" sortable />
//               <Column field="quantity" header="Quantity" sortable />
//               <Column field="ltp" header="LTP" sortable />
//             </DataTable>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;




// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";
// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";
// import { useNavigate } from "react-router-dom";

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const toast = useRef(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const websocket = useRef(null);
//   const navigate = useNavigate();

//   const fetchData = async () => {
//     const userId = localStorage.getItem("userId");
//     const authToken = localStorage.getItem("authToken");

//     if (!userId || !authToken) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         { user_id: userId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.st === 1 && response.data.data) {
//         setData(response.data.data);
//         // Re-subscribe to LTP updates with the fetched data
//         subscribeToLTP(response.data.data);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       if (error.response && error.response.status === 401) {
//         console.error("Unauthorized access, redirecting to common login screen");
//         navigate("/commonlogin");
//       } else {
//         console.error("Failed to fetch data:", error);
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const subscribeToLTP = (holdings) => {
//     if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
//       return;
//     }

//     holdings.forEach((holding) => {
//       websocket.current.send(
//         JSON.stringify({
//           type: "subscribe",
//           symbol: holding.tradingsymbol,
//         })
//       );
//     });
//   };

//   useEffect(() => {
//     fetchData();

//     const dhanAuthToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzIzMjY3ODcyLCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTEwMzcwNzAwNiJ9.LidpAzpuoZ5Ug7wei37M3akszp3W0waNhHuBVdb27Q8G_3D1ONRVh8e0Z4gJYd8gsehjhvObaLbWwLJnb7kQyw"; // Replace with your actual token
//     const dhanClientId = "YOUR_DHAN_CLIENT_ID"; // Replace with your actual client ID

//     // Establish WebSocket connection
//     websocket.current = new WebSocket("wss://api-feed.dhan.co");

//     websocket.current.onopen = () => {
//       console.log("WebSocket connection established");

//       // Send authentication message after connection is opened
//       websocket.current.send(
//         JSON.stringify({
//           type: "auth",
//           access_token: dhanAuthToken,
//         })
//       );
//     };

//     websocket.current.onmessage = (event) => {
//       // Handling binary data
//       const arrayBuffer = event.data;
//       const dataView = new DataView(arrayBuffer);
      
//       // Assuming the message structure is:
//       // 0-8 bytes: Response Header with code 2
//       // 9-12 bytes: Last Traded Price (float32)
//       // 13-16 bytes: Last Trade Time (int32)
      
//       if (dataView.getUint8(0) === 2) { // Check response code
//         const ltp = dataView.getFloat32(9, true); // Get LTP (little-endian)
//         const ltt = dataView.getInt32(13, true);  // Get LTT (little-endian)
        
//         // Update data based on the received symbol
//         setData((prevData) =>
//           prevData.map((holding) =>
//             holding.tradingsymbol === /* Symbol extraction logic from WebSocket message */ 
//               holding.tradingsymbol
//               ? { ...holding, ltp: ltp, ltt: ltt }
//               : holding
//           )
//         );
//       }
//     };

//     websocket.current.onclose = () => {
//       console.log("WebSocket connection closed");
//     };

//     websocket.current.onerror = (error) => {
//       console.error("WebSocket error:", error);
//     };

//     // Clean up WebSocket connection on component unmount
//     return () => {
//       if (websocket.current) {
//         websocket.current.close();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const rowClassName = (rowData, rowIndex) => {
//     return rowIndex % 2 === 0 ? "even-row" : "odd-row";
//   };

//   const handleBack = () => {
//     navigate(-1);
//   };

//   const getButtonVariant = () => {
//     const now = new Date();
//     const hours = now.getHours();

//     if (hours === 9) {
//       return "success"; // Green color for 9:15 AM
//     } else if (hours === 15) {
//       return "danger"; // Red color for 3:15 PM
//     }
//     return "secondary"; // Default color
//   };

//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <Modal
//         show={showPopup}
//         onHide={handleClosePopup}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Market Status</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>The market is now open or closed!</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <div className="card">
//           <h5 className="card-header text-center">Holdings</h5>
//           <div className="table-responsive text-nowrap">
           
//             <DataTable
//               value={data}
//               paginator
//               rows={10}
//               globalFilter={globalFilter}
//               loading={loading}
//               rowClassName={rowClassName}
//             >
//               <Column field="tradingsymbol" header="Trading Symbol" />
              
//               <Column field="ltp" header="LTP" />
//             </DataTable>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;


// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";
// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";
// import { useNavigate } from "react-router-dom";

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const toast = useRef(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const websocket = useRef(null);
//   const navigate = useNavigate();

//   const fetchData = async () => {
//     const userId = localStorage.getItem("userId");
//     const authToken = localStorage.getItem("authToken");

//     if (!userId || !authToken) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         { user_id: userId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.st === 1 && response.data.data) {
//         setData(response.data.data);
//         // Re-subscribe to LTP updates with the fetched data
//         subscribeToLTP(response.data.data);
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       if (error.response && error.response.status === 401) {
//         console.error("Unauthorized access, redirecting to common login screen");
//         navigate("/commonlogin");
//       } else {
//         console.error("Failed to fetch data:", error);
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const subscribeToLTP = (holdings) => {
//     if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
//       return;
//     }

//     holdings.forEach((holding) => {
//       websocket.current.send(
//         JSON.stringify({
//           type: "subscribe",
//           symbol: holding.tradingsymbol,
//         })
//       );
//     });
//   };

//   useEffect(() => {
//     fetchData();

//     const dhanAuthToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzIzMjY3ODcyLCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTEwMzcwNzAwNiJ9.LidpAzpuoZ5Ug7wei37M3akszp3W0waNhHuBVdb27Q8G_3D1ONRVh8e0Z4gJYd8gsehjhvObaLbWwLJnb7kQyw"; // Replace with your actual token
//     const dhanClientId = "1103707006"; // Replace with your actual client ID

//     // Establish WebSocket connection
//     websocket.current = new WebSocket("wss://api-feed.dhan.co");

//     websocket.current.onopen = () => {
//       console.log("WebSocket connection established");

//       // Send authentication message after connection is opened
//       websocket.current.send(
//         JSON.stringify({
//           type: "subscribe",
//           access_token: dhanAuthToken,
//           client_id:dhanClientId
//         })
//       );
//     };

//     websocket.current.onmessage = (event) => {
//       if (typeof event.data === 'string') {
//         const message = JSON.parse(event.data);
//         if (message.type === "update") {
//           setData((prevData) =>
//             prevData.map((holding) =>
//               holding.tradingsymbol === message.symbol
//                 ? { ...holding, ltp: message.ltp, ltt: message.ltt }
//                 : holding
//             )
//           );
//         }
//       } else {
//         const binaryData = new Uint8Array(event.data);
//         // Implement parsing logic here based on Dhan's documentation
//         // For example, convert binaryData to the needed format
//       }
//     };
    

//     websocket.current.onclose = () => {
//       console.log("WebSocket connection closed");
//     };

//     websocket.current.onerror = (error) => {
//       console.error("WebSocket error:", error);
//     };

//     // Clean up WebSocket connection on component unmount
//     return () => {
//       if (websocket.current) {
//         websocket.current.close();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   const rowClassName = (rowData, rowIndex) => {
//     return rowIndex % 2 === 0 ? "even-row" : "odd-row";
//   };

//   const handleBack = () => {
//     navigate(-1);
//   };

//   const getButtonVariant = () => {
//     const now = new Date();
//     const hours = now.getHours();

//     if (hours === 9) {
//       return "success"; // Green color for 9:15 AM
//     } else if (hours === 15) {
//       return "danger"; // Red color for 3:15 PM
//     }
//     return "secondary"; // Default color
//   };

//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <Modal
//         show={showPopup}
//         onHide={handleClosePopup}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Market Status</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>The market is now open or closed!</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <div className="card">
//           <h5 className="card-header text-center">Holdings</h5>
//           <div className="table-responsive text-nowrap">
//             <DataTable
//               value={data}
//               paginator
//               rows={10}
//               globalFilter={globalFilter}
//               loading={loading}
//               rowClassName={rowClassName}
//             >
//               <Column field="tradingsymbol" header="Trading Symbol" />
//               <Column field="ltp" header="LTP" />
//             </DataTable>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;


// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";
// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";
// import { useNavigate } from "react-router-dom";

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const toast = useRef(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const websocket = useRef(null);
//   const navigate = useNavigate();

//   const fetchData = async () => {
//     console.log("fetchData: Started fetching data.");
//     const userId = localStorage.getItem("userId");
//     const authToken = localStorage.getItem("authToken");

//     if (!userId || !authToken) {
//       console.log("fetchData: No userId or authToken found.");
//       setLoading(false);
//       return;
//     }

//     setLoading(true);

//     try {
//       console.log("fetchData: Sending API request.");
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         { user_id: userId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.st === 1 && response.data.data) {
//         console.log("fetchData: Data fetched successfully.", response.data.data);
//         setData(response.data.data);
//         subscribeToLTP(response.data.data);
//       } else {
//         console.log("fetchData: No data or invalid status received.");
//       }
//     } catch (error) {
//       console.error("fetchData: Error fetching data:", error);
//       if (error.response && error.response.status === 401) {
//         console.error("fetchData: Unauthorized access, redirecting to common login screen.");
//         navigate("/commonlogin");
//       } else {
//         console.error("fetchData: Failed to fetch data.");
//       }
//     } finally {
//       setLoading(false);
//       console.log("fetchData: Finished fetching data.");
//     }
//   };

//   const subscribeToLTP = (holdings) => {
//     console.log("subscribeToLTP: Subscribing to LTP updates.");
//     if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
//       console.log("subscribeToLTP: WebSocket is not open.");
//       return;
//     }

//     holdings.forEach((holding) => {
//       console.log(`subscribeToLTP: Subscribing to ${holding.tradingsymbol}.`);
//       websocket.current.send(
//         JSON.stringify({
//           type: "subscribe",
//           symbol: holding.tradingsymbol,
//         })
//       );
//     });
//   };

//   useEffect(() => {
//     console.log("useEffect: Component mounted, fetching data.");
//     fetchData();

//     const dhanAuthToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzIzMjY3ODcyLCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTEwMzcwNzAwNiJ9.LidpAzpuoZ5Ug7wei37M3akszp3W0waNhHuBVdb27Q8G_3D1ONRVh8e0Z4gJYd8gsehjhvObaLbWwLJnb7kQyw"; // Replace with your actual token
//     const dhanClientId = "1103707006"; // Replace with your actual client ID

//     console.log("useEffect: Establishing WebSocket connection.");
//     websocket.current = new WebSocket("wss://api-feed.dhan.co");

//     websocket.current.onopen = () => {
//       console.log("WebSocket: Connection established.");

//       console.log("WebSocket: Sending authentication message.");
//       websocket.current.send(
//         JSON.stringify({
//           type: "subscribe",
//           access_token: dhanAuthToken,
//           client_id: dhanClientId,
//         })
//       );
//     };

//     websocket.current.onmessage = (event) => {
//       console.log("WebSocket: Message received.");
    
//       if (typeof event.data === 'string') {
//         const message = JSON.parse(event.data);
//         console.log("WebSocket: String message received.", message); // Log the string message
    
//         if (message.type === "update") {
//           console.log("WebSocket: Update message received.", message);
//           setData((prevData) =>
//             prevData.map((holding) =>
//               holding.tradingsymbol === message.symbol
//                 ? { ...holding, ltp: message.ltp, ltt: message.ltt }
//                 : holding
//             )
//           );
//         }
//       } else {
//         const binaryData = new Uint8Array(event.data);
//         console.log("WebSocket: Binary message received."); // Log binary message for debugging
//         // Implement parsing logic here based on Dhan's documentation
//       }
//     };
    

//     websocket.current.onclose = () => {
//       console.log("WebSocket: Connection closed.");
//     };

//     websocket.current.onerror = (error) => {
//       console.error("WebSocket: Error occurred.", error);
//     };

//     return () => {
//       console.log("useEffect: Component unmounted, closing WebSocket.");
//       if (websocket.current) {
//         websocket.current.close();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     console.log("useEffect: Setting up market time check.");
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       console.log(`checkTime: Checking time: ${hours}:${minutes}.`);
//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
//         console.log("checkTime: Time matches, showing popup.");
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000);

//     return () => {
//       console.log("useEffect: Clearing market time check interval.");
//       clearInterval(interval);
//     };
//   }, []);

//   const handleClosePopup = () => {
//     console.log("handleClosePopup: Closing popup.");
//     setShowPopup(false);
//   };

//   const rowClassName = (rowData, rowIndex) => {
//     console.log(`rowClassName: Assigning class to row ${rowIndex}.`);
//     return rowIndex % 2 === 0 ? "even-row" : "odd-row";
//   };

//   const handleBack = () => {
//     console.log("handleBack: Navigating back.");
//     navigate(-1);
//   };

//   const getButtonVariant = () => {
//     const now = new Date();
//     const hours = now.getHours();

//     console.log("getButtonVariant: Determining button variant based on time.");
//     if (hours === 9) {
//       return "success"; // Green color for 9:15 AM
//     } else if (hours === 15) {
//       return "danger"; // Red color for 3:15 PM
//     }
//     return "secondary"; // Default color
//   };

//   console.log("Rendering Holding component.");
//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <Modal show={showPopup} onHide={handleClosePopup}>
//         <Modal.Header closeButton>
//           <Modal.Title>Market Status</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>The market is now open or closed!</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <div className="card">
//           <h5 className="card-header text-center">Holdings</h5>
//           <div className="table-responsive text-nowrap">
//             <DataTable
//               value={data}
//               paginator
//               rows={10}
//               globalFilter={globalFilter}
//               loading={loading}
//               rowClassName={rowClassName}
//             >
//               <Column field="tradingsymbol" header="Trading Symbol" />
//               <Column field="ltp" header="LTP" />
//             </DataTable>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;




// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import { ProgressSpinner } from "primereact/progressspinner";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
// import { Tooltip } from "primereact/tooltip";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";

// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const navigate = useNavigate();
//   const toast = useRef(null);
//   const ws = useRef(null);

//   const fetchData = async () => {
//     const userId = localStorage.getItem("userId");
//     const authToken = localStorage.getItem("authToken");

//     if (!userId || !authToken) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         { user_id: userId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.st === 1 && response.data.data) {
//         setData(response.data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       if (error.response && error.response.status === 401) {
//         navigate('/commonlogin');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const initializeWebSocket = () => {
//     const clientId = "1103707006";
//     const auth = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzI0MjIwMjY5LCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTEwMzcwNzAwNiJ9.ic6MLRK1kogAreiRGPDI1x0E-cR7uPJdWwUH6KV5MiccDALkIlMt_sLeTurYb6Q2mEh9NeQ_6bxJL6xK9sBOEQ"; // Ensure this is correct
  
//     if (!clientId || !auth) {
//       console.error('Client ID or Auth Token missing');
//       return;
//     }
  
//     const connectWebSocket = () => {
//       if (ws.current) {
//         ws.current.close();
//       }
  
//       ws.current = new WebSocket("wss://api-feed.dhan.co");
  
//       ws.current.binaryType = 'arraybuffer';
  
//       ws.current.onopen = () => {
//         console.log('WebSocket connection established');
//         // Send authentication packet
//         const authPacket = new Uint8Array(583);
//         authPacket[0] = 11;
//         authPacket.set(new TextEncoder().encode(clientId), 4);
//         authPacket.set(new TextEncoder().encode(auth), 84);
//         ws.current.send(authPacket);
//       };
  
//       ws.current.onmessage = (event) => {
//         if (event.data instanceof ArrayBuffer) {
//           const dataView = new DataView(event.data);
//           const responseCode = dataView.getUint8(0);
  
//           if (responseCode === 2) {
//             const ltp = dataView.getFloat32(9, true);
//             const securityId = dataView.getInt32(5, true);
//             console.log(`Security ID: ${securityId}, LTP: ${ltp}`);
//             setData((prevData) =>
//               prevData.map(item =>
//                 item.securityId === securityId ? { ...item, ltp } : item
//               )
//             );
//           }
//         } else {
//           console.warn('Received unexpected data type:', typeof event.data);
//         }
//       };
  
//       ws.current.onerror = (error) => {
//         console.error('WebSocket Error:', error);
//         setError(new Error('WebSocket Error'));
//       };
  
//       ws.current.onclose = (event) => {
//         console.log('WebSocket connection closed', event);
//         console.error('Close code:', event.code, 'Reason:', event.reason);
       
//         setError(new Error('WebSocket connection closed'));
//         // Attempt to reconnect after a delay
//         setTimeout(connectWebSocket, 1000); // Reconnect after 1 second
//       };
//     };
  
//     connectWebSocket();
  
//     return () => {
//       if (ws.current) {
//         ws.current.close();
//       }
//     };
//   };
  
  

//   useEffect(() => {
//     fetchData();
//     initializeWebSocket();
//     return () => {
//       if (ws.current) {
//         ws.current.close();
//       }
//     };
//   }, []);

//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <Modal
//         show={showPopup}
//         onHide={() => setShowPopup(false)}
//         dialogClassName="modal-dialog-centered"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Popup Title</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>Popup Body</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="primary" onClick={() => setShowPopup(false)}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <nav aria-label="breadcrumb">
//           <ol className="breadcrumb breadcrumb-style1 text-black">
//             <li className="breadcrumb-item">
//               <Link to="/teacher/dashboard" className="text-black">
//                 <i className="ri-home-7-line ri-lg"></i>
//               </Link>
//             </li>
//             <li
//               className="breadcrumb-item active text-secondary"
//               aria-current="page"
//             >
//               Holding
//             </li>
//           </ol>
//         </nav>

//         <div className="card p-5">
//           <div className="d-flex justify-content-between align-items-center mb-5">
//             <button
//               onClick={() => navigate(-1)}
//               className="btn rounded-pill btn-outline-secondary btn-xs"
//             >
//               <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
//             </button>

//             <h5 className="mb-0 mx-auto">Holding</h5>
//             <div></div>
//           </div>
//           <div className="d-flex justify-content-start mb-3">
//             <IconField iconPosition="left">
//               <InputIcon className="ri ri-search-line"></InputIcon>
//               <InputText
//                 type="search"
//                 placeholder="Search"
//                 value={globalFilter}
//                 onChange={(e) => setGlobalFilter(e.target.value)}
//                 className="rounded"
//               />
//             </IconField>
//             {loading ? (
//               <i
//                 className="custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary"
//                 strokeWidth="5"
//                 fill="var(--surface-ground)"
//                 animationDuration=".5s"
//               />
//             ) : (
//               <div className="mt-3">
//                 <Tooltip target=".custom-target-icon" />
//                 <i
//                   className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
//                   data-pr-tooltip="Refresh"
//                   onClick={fetchData}
//                   data-pr-position="top"
//                 ></i>
//               </div>
//             )}
//           </div>
//           <DataTable
//             className="custom-column-border text-center"
//             value={data}
//             paginator
//             rows={20}
//             showGridlines
//             loading={loading}
//             globalFilter={globalFilter}
//             emptyMessage="No records found"
//           >
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="tradingsymbol"
//               header="Symbols"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="quantity"
//               header="Quantity"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="exchange"
//               header="Exchange"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="ltp"
//               header="LTP"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="averageprice"
//               header="Average Price"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="profitandloss"
//               header="Profit and Loss"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="pnlpercentage"
//               header="P&L Percentage"
//             ></Column>
//           </DataTable>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;

// const getColorModalClass = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9 || hours === 15) {
//     return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
//   }
//   return "";
// };

// const getModalTitle = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is Open!";
//   } else if (hours === 15) {
//     return "Market is Closed!";
//   }
//   return "";
// };

// const getModalBody = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is currently open. Take necessary actions.";
//   } else if (hours === 15) {
//     return "Market is currently closed. Come back tomorrow.";
//   }
//   return "";
// };


// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import { Tooltip } from "primereact/tooltip";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";

// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [showPopup, setShowPopup] = useState(false);
//   const navigate = useNavigate();
//   const toast = useRef(null);
//   const ws = useRef(null);
//   const [reconnectAttempts, setReconnectAttempts] = useState(0);

//   const fetchData = async () => {
//     const userId = localStorage.getItem("userId");
//     const authToken = localStorage.getItem("authToken");

//     if (!userId || !authToken) {
//       setLoading(false);
//       return;
//     }

//     setLoading(true);

//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         { user_id: userId },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );

//       if (response.data.st === 1 && response.data.data) {
//         setData(response.data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       if (error.response && error.response.status === 401) {
//         navigate('/commonlogin');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };
//   const connectWebSocket = () => {
//     const clientId = "1103707006";
//     const auth = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJkaGFuIiwicGFydG5lcklkIjoiIiwiZXhwIjoxNzI0MjIwMjY5LCJ0b2tlbkNvbnN1bWVyVHlwZSI6IlNFTEYiLCJ3ZWJob29rVXJsIjoiIiwiZGhhbkNsaWVudElkIjoiMTEwMzcwNzAwNiJ9.ic6MLRK1kogAreiRGPDI1x0E-cR7uPJdWwUH6KV5MiccDALkIlMt_sLeTurYb6Q2mEh9NeQ_6bxJL6xK9sBOEQ"; // Replace with the correct auth token
  
//     if (!clientId || !auth) {
//       console.error('Client ID or Auth Token missing');
//       return;
//     }
  
//     if (ws.current) {
//       ws.current.close();
//     }
  
//     ws.current = new WebSocket("wss://api-feed.dhan.co");
//     ws.current.binaryType = 'arraybuffer';
  
//     ws.current.onopen = () => {
//       console.log('WebSocket connection established');
//       setReconnectAttempts(0); // Reset attempts on successful connection
  
//       // Send authentication packet
//       const authPacket = new Uint8Array(583);
//       authPacket[0] = 11;
//       authPacket.set(new TextEncoder().encode(clientId), 4);
//       authPacket.set(new TextEncoder().encode(auth), 84);
//       ws.current.send(authPacket);
  
//       console.log('WebSocket readyState on open:', ws.current.readyState);
//     };
  
//     ws.current.onmessage = (event) => {
//       if (event.data instanceof ArrayBuffer) {
//         const dataView = new DataView(event.data);
  
//         const messageType = dataView.getUint8(0);
//         console.log('Message Type:', messageType);
  
//         if (messageType === 50) {  // Assuming messageType 50 is LTP data
//           const securityId = dataView.getUint32(2, true);  // Ensure the offset is correct
//           const ltp = dataView.getFloat32(6, true);  // Ensure this matches the server-side data format
  
//           console.log(`Security ID: ${securityId}, LTP: ${ltp}`);
  
//           setData((prevData) =>
//             prevData.map(item =>
//               item.securityId === securityId ? { ...item, ltp } : item
//             )
//           );
//         } else {
//           console.warn('Unexpected message type:', messageType);
//         }
//       } else {
//         console.warn('Received unexpected data type:', typeof event.data);
//       }
//     };
  
//     ws.current.onerror = (error) => {
//       console.error('WebSocket Error:', error);
//       setError(new Error('WebSocket Error'));
//     };
  
//     ws.current.onclose = (event) => {
//       console.log('WebSocket connection closed', event);
//       console.error('Close code:', event.code, 'Reason:', event.reason);
  
//       setError(new Error(`WebSocket connection closed: Code ${event.code}, Reason: ${event.reason}`));
  
//       // Attempt to reconnect with exponential backoff
//       if (event.code === 1006 || event.code === 1001) {
//         const delay = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000); // Exponential backoff
//         setReconnectAttempts(reconnectAttempts + 1);
//         setTimeout(connectWebSocket, delay);
//       }
  
//       console.log('WebSocket readyState on close:', ws.current ? ws.current.readyState : 'Closed');
//     };
  
//     const pingInterval = setInterval(() => {
//       if (ws.current && ws.current.readyState === WebSocket.OPEN) {
//         ws.current.send(JSON.stringify({ type: 'ping' }));
//         console.log('Ping message sent');
//       }
//     }, 30000); // Ping every 30 seconds
  
//     return () => {
//       clearInterval(pingInterval);
//       if (ws.current) {
//         ws.current.close();
//       }
//     };
//   };
  
  
  
  

//   useEffect(() => {
//     fetchData();
//     const cleanupWebSocket = connectWebSocket();
//     return cleanupWebSocket;
//   }, []);

//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <Modal
//         show={showPopup}
//         onHide={() => setShowPopup(false)}
//         dialogClassName="modal-dialog-centered"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Popup Title</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>Popup Body</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="primary" onClick={() => setShowPopup(false)}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <nav aria-label="breadcrumb">
//           <ol className="breadcrumb breadcrumb-style1 text-black">
//             <li className="breadcrumb-item">
//               <Link to="/teacher/dashboard" className="text-black">
//                 <i className="ri-home-7-line ri-lg"></i>
//               </Link>
//             </li>
//             <li
//               className="breadcrumb-item active text-secondary"
//               aria-current="page"
//             >
//               Holding
//             </li>
//           </ol>
//         </nav>

//         <div className="card p-5">
//           <div className="d-flex justify-content-between align-items-center mb-5">
//             <button
//               onClick={() => navigate(-1)}
//               className="btn rounded-pill btn-outline-secondary btn-xs"
//             >
//               <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
//             </button>

//             <h5 className="mb-0 mx-auto">Holding</h5>
//             <div></div>
//           </div>
//           <div className="d-flex justify-content-start mb-3">
//             <div className="input-group">
//               <InputText
//                 type="search"
//                 placeholder="Search"
//                 value={globalFilter}
//                 onChange={(e) => setGlobalFilter(e.target.value)}
//                 className="rounded"
//               />
//             </div>
//             {/* {loading ? (
//               <i
//                 className="custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary"
//                 strokeWidth="5"
//                 fill="var(--surface-ground)"
//                 animationDuration=".5s"
//               />
//             ) : (
//               <div className="mt-3">
//                 <Tooltip target=".custom-target-icon" />
//                 <i
//                   className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
//                   data-pr-tooltip="Refresh"
//                   onClick={fetchData}
//                   data-pr-position="top"
//                 ></i>
//               </div>
//             )} */}
//           </div>
//           <DataTable
//             className="custom-column-border text-center"
//             value={data}
//             paginator
//             rows={20}
//             showGridlines
//             loading={loading}
//             globalFilter={globalFilter}
//             emptyMessage="No records found"
//           >
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="tradingsymbol"
//               header="Symbols"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="quantity"
//               header="Quantity"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="exchange"
//               header="Exchange"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="ltp"
//               header="LTP"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="averageprice"
//               header="Average Price"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="profitandloss"
//               header="Profit and Loss"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="pnlpercentage"
//               header="P&L Percentage"
//             ></Column>
//           </DataTable>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;


// import React, { useState, useEffect, useRef } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Modal } from "react-bootstrap";
// import { Link, useNavigate } from "react-router-dom";
// import { ProgressSpinner } from "primereact/progressspinner";
// import { IconField } from "primereact/iconfield";
// import { InputIcon } from "primereact/inputicon";
// import { Tooltip } from "primereact/tooltip";
// import { Toast } from "primereact/toast";
// import axios from "axios";
// import config from "../../../component/config";
// import { DhanHqClient } from "dhanhq"; 
// import Footer from "../../../component/Footer";
// import Header from "../component/Header";
// import SubHeader from "../component/SubHeader";

// const Holding = () => {
//   const [data, setData] = useState([]);
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [subscription, setSubscription] = useState(null);
//   const navigate = useNavigate();
//   const toast = useRef(null);
//   const [backClicked, setBackClicked] = useState(false);

//   const dhan = new DhanHqClient({
//     accessToken: process.env.REACT_APP_ACCESS_TOKEN,
//     clientId: process.env.REACT_APP_DHAN_CLIENT_ID,
//   });

//   const fetchData = async () => {
//     const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
//     const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
//     if (!userId || !authToken) {
//       setLoading(false);
//       return;
//     }
  
//     setLoading(true);
  
//     try {
//       const response = await axios.post(
//         `${config.apiDomain}/api/common/get_all_holdings`,
//         {
//           user_id: userId,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );
  
//       if (response.data.st === 1 && response.data.data) {
//         setData(response.data.data);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       // Check for 401 Unauthorized error
//       if (error.response && error.response.status === 401) {
//         console.error('Unauthorized access, redirecting to common login screen');
//         navigate('/commonlogin'); // Navigate to common login screen on 401 error
//       } else {
//         const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
     
//         toast.current.show({
//           severity: 'error',
//           summary: 'Error',
//           detail: errorMsg,
//           life: 3000,
//         });
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   // WebSocket logic to fetch LTP
//   useEffect(() => {
//     const fetchLTP = async () => {
//       try {
//         const symbols = data.map(item => item.tradingsymbol);
    
//         symbols.forEach(symbol => {
//           dhan.on('ltpUpdate', (ltpData) => {
//             if (ltpData.symbol === symbol) {
//               setData(prevData => prevData.map(item => {
//                 if (item.tradingsymbol === ltpData.symbol) {
//                   return { ...item, ltp: ltpData.ltp };
//                 }
//                 return item;
//               }));
//             }
//           });
    
//           dhan.subscribeToSymbols([symbol]);
//         });
//       } catch (error) {
//         console.error("Error fetching LTP data:", error);
//       }
//     };
    
  
//     if (data.length > 0) {
//       fetchLTP();
//     }

//     // Cleanup function to unsubscribe from WebSocket
//     return () => {
//       if (subscription) {
//         subscription.forEach(sub => sub.unsubscribe());
//       }
//     };
//   }, [data]);

//   const handleRefresh = async () => {
//     setLoading(true);
//     setError(null);
//     await fetchData();
//   };

//   const rowClassName = (rowData, rowIndex) => {
//     return rowIndex % 2 === 0 ? "even-row" : "odd-row";
//   };

//   const handleBack = () => {
//     if (!backClicked) {
//       setBackClicked(true);
//       navigate(-1);
//     }
//   };

//   return (
//     <>
//       <Header />
//       <SubHeader />
//       <div className="container-xxl container-p-y">
//         <Toast ref={toast} />
//         <nav aria-label="breadcrumb">
//           <ol className="breadcrumb breadcrumb-style1 text-black">
//             <li className="breadcrumb-item">
//               <Link to="/teacher/dashboard" className="text-black">
//                 <i className="ri-home-7-line ri-lg"></i>
//               </Link>
//             </li>
//             <li
//               className="breadcrumb-item active text-secondary"
//               aria-current="page"
//             >
//               Holding
//             </li>
//           </ol>
//         </nav>

//         <div className="card p-5">
//           <div className="d-flex justify-content-between align-items-center mb-5">
//             <button
//               onClick={handleBack}
//               className="btn rounded-pill btn-outline-secondary btn-xs"
//             >
//               <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
//             </button>

//             <h5 className="mb-0 mx-auto">Holding</h5>
//             <div></div>
//           </div>
//           <div className="d-flex justify-content-start mb-3">
//             <IconField iconPosition="left">
//               <InputIcon className="ri ri-search-line"></InputIcon>
//               <InputText
//                 type="search"
//                 placeholder="Search"
//                 value={globalFilter}
//                 onChange={(e) => setGlobalFilter(e.target.value)}
//                 className="rounded"
//               />
//             </IconField>
//             {loading ? (
//               <i className=" custom-target-icon ri-loader-2-line ri-lg mt-3 ms-3 p-text-secondary"
//                 strokeWidth="5"
//                 fill="var(--surface-ground)"
//                 animationDuration=".5s"
//               />
//             ) : (
//               <div className="mt-3">
//                 <Tooltip target=".custom-target-icon" />
//                 <i
//                   className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
//                   data-pr-tooltip="Refresh"
//                   onClick={handleRefresh}
//                   data-pr-position="top"
//                 ></i>
//               </div>
//             )}
//           </div>
//           <DataTable
//             className="custom-column-border text-center"
//             value={data}
//             paginator
//             rows={20}
//             showGridlines
//             loading={loading}
//             globalFilter={globalFilter}
//             emptyMessage="No records found"
//             rowClassName={rowClassName}
//           >
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="tradingsymbol"
//               header="Symbols"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="quantity"
//               header="Quantity"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="exchange"
//               header="Exchange"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="ltp"
//               header="LTP"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="averageprice"
//               header="Average Price"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="profitandloss"
//               header="Profit and Loss"
//             ></Column>
//             <Column
//               align="center"
//               className="custom-column-border"
//               field="pnlpercentage"
//               header="P&L Percentage"
//             ></Column>
//           </DataTable>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// };

// export default Holding;

