import React, { useState, useEffect, useCallback, useRef } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import Footer from "../../../component/Footer";
import { Button } from "primereact/button";
import { Modal } from "react-bootstrap";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import config from "../../../component/config";
import { AutoComplete } from "primereact/autocomplete";

import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { VirtualScroller } from "primereact/virtualscroller";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import "./Autocompletee.css";
import CardLinks from "../../../component/CardLinks";

const Basket = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [baskets, setBaskets] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const authToken = localStorage.getItem('authToken');
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  
  const [showModal, setShowModal] = useState(false);
  const [value, setValue] = useState("");
  const [items, setItems] = useState([]);

  const [selectedBasketIndex, setSelectedBasketIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [globalFilter, setGlobalFilter] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [executionMessage, setExecutionMessage] = useState("");
  const [backClicked, setBackClicked] = useState(false);
  const [error, setError] = useState(null);
  const [onlyTeacherExecute, setOnlyTeacherExecute] = useState(false);

  const toast = useRef(null);

  const titleCase = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handleDeleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleDeleteRowModal = (index) => {
    const newInstruments = [...currentBasket.instruments];
    newInstruments.splice(index, 1);
    setCurrentBasket((prevBasket) => ({
      ...prevBasket,
      instruments: newInstruments,
    }));
  };

  const [rows, setRows] = useState([
    {
      instrument: "",
      lot_quantity_buffer: "1",

      transactionType: "BUY",
      exchange: "NFO",
      orderType: "MARKET",
      productType: "CARRYFORWARD",
    },
  ]);

  const [currentBasket, setCurrentBasket] = useState({
    name: "",
    instruments: [],
  });

  const [editedBasket, setEditedBasket] = useState({
    name: "",
    instruments: [],
  });


  const fetchBaskets = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/basket_list_view`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.st === 1) {
        setBaskets(response.data.basket_details);
      } else {
        // setBaskets([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {


        navigate('/commonlogin');
        // setBaskets([]);
      } else {
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBaskets();
  }, []);



  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleDeleteAllBaskets = async () => {
    setShowModal(true);
  };

  const confirmDeleteAll = async () => {


    try {
      setDeleteLoading(true);

      const authToken = localStorage.getItem('authToken');
      if (!authToken) {

        navigate('/commonlogin');
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/delete_baskets_all`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );


      if (response.data && response.data.st === 1) {
        fetchBaskets();
      } else {
        
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin');
      } else {
     
      }
    } finally {
      setDeleteLoading(false);
      setShowModal(false);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };


  const handleDeleteBasket1 = async (index) => {
    const basketId = baskets[index].basket_id;


    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        navigate('/commonlogin');
        return;
      }

      const response = await fetch(
        `${config.apiDomain}/api/teacher/delete_basket`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${authToken}`,
          },
          body: JSON.stringify({ basket_id: basketId }),
        }
      );

      if (response.ok) {
        const newBaskets = [...baskets];
        newBaskets.splice(index, 1);
        setBaskets(newBaskets);
      } else {
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin');
      } else {
      }
    }
  };

  // const search = async (event) => {
  //   try {
  //     const userId = localStorage.getItem("userId");
  //     const authToken = localStorage.getItem('authToken');
  //     if (!authToken) {
  //       return;
  //     }
  
  //     const response = await axios.post(
  //       `${config.apiDomain}/api/teacher/get_instrument_list`,
  //       {
  //         search: event.query,
  //         teacher_id: userId,
  //       },
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${authToken}`,
  //           'Content-Type': 'application/json',
  //         },
  //       }
  //     );
  
  //     if (response.data.st === 1) {
  //       const instrumentList = response.data.data.map((item) => ({
  //         label: `${item.symbol} (${item.exchange})`, // Display both symbol and exchange
  //         value: item.symbol, // Use symbol as the value
  //       }));
  //       setItems(instrumentList);
  //     } else {
  //       setItems([]);
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       navigate('/commonlogin');
  //     } else {
  //       setItems([]);
  //     }
  //   }
  // };
  


  // const handleAutoCompleteChange = (e, rowIndex) => {
  //   const newRows = rows.map((row, index) => {
  //     if (index === rowIndex) {
  //       return {
  //         ...row,
  //         instrument: e.value,
  //       };
  //     }
  //     return row;
  //   });
  
  //   const updatedErrors = { ...validationErrors };
  //   if (updatedErrors.rows[rowIndex]) {
  //     updatedErrors.rows[rowIndex].instrument = "";
  //     setValidationErrors(updatedErrors);
  //   }
  
  //   setRows(newRows);
  // };
  
  const search = async (event) => {
    try {
      const userId = localStorage.getItem("userId");
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        return;
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/get_instrument_list`,
        {
          search: event.query,
          teacher_id: userId,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data.st === 1) {
        const instrumentList = response.data.data.map((item) => item.symbol);
        setItems(instrumentList);
      } else {
        setItems([]);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin');
      } else {
        setItems([]);
      }
    }
  };
const handleAutoCompleteChange = (e, rowIndex) => {
  const newRows = rows.map((row, index) => {
    if (index === rowIndex) {
      return {
        ...row,
        instrument: e.value,
      };
    }
    return row;
  });

  const updatedErrors = { ...validationErrors };
  if (updatedErrors.rows[rowIndex]) {
    updatedErrors.rows[rowIndex].instrument = "";
    setValidationErrors(updatedErrors);
  }

  setRows(newRows);
};
  




  const handleRefresh = async () => {
    setLoading(true);
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/basket_list_view`,
        {
          teacher_id: userId,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.st === 1) {
        setBaskets(response.data.basket_details);
       
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: response.data.msg,
        });
      } else if (response.data.st === 2) {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: response.data.msg,
        });
      } else if (response.data.st === 3 || response.data.st === 4) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.data.msg,
        });
      } else {
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          navigate('/commonlogin');
        } else if (error.response.data.st) {
          const { st, msg } = error.response.data;
          if (st === 4) {
            toast.current.show({
              severity: "error",
              summary: "Method Not Allowed",
              detail: msg,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: msg,
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "An error occurred",
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Network Error",
          detail: "Failed to fetch baskets",
        });
      }
    } finally {
      setLoading(false);
    }
  };


  const handleInputChanges = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index] = {
      ...newRows[index],
      [name]: value,
    };
    setRows(newRows);
  };


  const filterBaskets = () => {
    if (!globalFilter) {
      return baskets;
    }
    return baskets.filter((basket) =>
      basket.name.toLowerCase().includes(globalFilter.toLowerCase())
    );
  };
  const handleEditBasket = async (index) => {
    try {
      const authToken = localStorage.getItem('authToken');
      const basketId = baskets[index].basket_id;

      const response = await axios.post(
        `${config.apiDomain}/api/teacher/get_basket_details`,
        { basket_id: basketId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setCurrentBasket({
        name: response.data.basket_name,
        instruments: response.data.basket_data,
      });

      // Show the modal

    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        setError(error.message || "Failed to fetch data");
      }
    }
  };

  const handleInputChange = (index, event, isModal = false) => {
    const { name, value } = event.target;
    let error = "";

    if (name === "instrument" && value.trim() === "") {
      error = "Instrument is required";
    } else if (name === "lot_quantity_buffer" && !/^\d+$/.test(value)) {
      error = "Lot Quantity Buffer must be a number";
    }

    const updatedErrors = { ...errors };
    updatedErrors[index] = {
      ...updatedErrors[index],
      [name]: error,
    };
    setErrors(updatedErrors);

    const updatedInstruments = isModal
      ? [...editedBasket.instruments]
      : [...currentBasket.instruments];
    updatedInstruments[index] = {
      ...updatedInstruments[index],
      [name]: value,
    };

    if (isModal) {
      setEditedBasket({
        ...editedBasket,
        instruments: updatedInstruments,
      });
    } else {
      setCurrentBasket({
        ...currentBasket,
        instruments: updatedInstruments,
      });
    }
  };

  const handleExecuteAll = async () => {
    const userId = localStorage.getItem("userId");

setIsLoading(true);
    try {
      const response = await axios.post(
       `https://www.apbacked.xyz/execute_orders/`,
        {
          teacher_id: userId,
          order_data: currentBasket.instruments.map((instrument) => ({
            instrument: instrument.instrument,
            lot_quantity_buffer: instrument.lot_quantity_buffer,
            transactionType: instrument.transaction_type,
            exchange: instrument.exchange,
            orderType: instrument.order_type,
            productType: instrument.product_type,
          })),
          only_teacher_execute: onlyTeacherExecute, 

        }
      );

      if (response.data.st === 1) {

        navigate("/trade_position");
        window.location.reload();
      } else {
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getFormattedBasketName = (index) => {
    return `Basket ${index + 1}`;
  };

  useEffect(() => {
    fetchBaskets();
  }, [userId]);

  const handleRowChange = (index, field, value) => {
    const updatedRows = [...editedBasket.rows];
    updatedRows[index] = { ...updatedRows[index], [field]: value };
    setEditedBasket({ ...editedBasket, rows: updatedRows });
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setEditedBasket({ ...editedBasket, name: value });

    if (value.trim() !== "") {
      setValidationErrors({ ...validationErrors, name: "" });
    }
  };

  const handleAddRow = () => {
    if (rows.length < 30) {
      setRows([
        ...rows,
        {
          instrument: "",
          lot_quantity_buffer: "1",

          transactionType: "SELL",
          exchange: "NFO",
          orderType: "MARKET",
          productType: "CARRYFORWARD",
        },
      ]);
    } else {

      confirmDialog({
        message: 'You can only add up to 30 Instruments.',
        header: 'Instrument Limit Reached',
        icon: 'ri ri-feedback-line',
        acceptLabel: 'OK',
        acceptClassName: ' btn btn-success',
        rejectVisible: false,
        accept: () => {

        }
      });
    }
  };

  const handleAddRowModal = () => {
    if (currentBasket.instruments.length < 30) {
      setCurrentBasket((prevBasket) => ({
        ...prevBasket,
        instruments: [
          ...prevBasket.instruments,
          {
            instrument: "",
            lot_quantity_buffer: "1",

            transaction_type: "BUY",
            exchange: "NFO",
            order_type: "MARKET",
            product_type: "CARRYFORWARD",
          },
        ],
      }));
    } else {


    }
  };

  const handleCreateBasket = async () => {
    const isValid = validateFields();

    if (!isValid) {
      return;
    }

    setLoadings(true);

    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/create_update_basket`,
        {
          basket_id: null,
          basket_name: editedBasket.name,
          teacher_id: userId,
          basket_data: rows,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;

        setBaskets([...baskets, data]);
        setRows([
          {
            instrument: "",
            lot_quantity_buffer: "1",
            transactionType: "BUY",
            exchange: "NFO",
            orderType: "MARKET",
            productType: "CARRYFORWARD",
          },
        ]);
        setEditedBasket({
          name: "",
          instruments: [],
        });
        setShowModal(false);
        fetchBaskets();
        const successMessage = response.data.message;
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: successMessage || "Basket created successfully",
          life: 3000,
        });
      } else {
        const errorMessage = response.data.message || "Failed to create basket";
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: errorMessage,
          life: 3000,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while creating basket";

      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: errorMessage,
        life: 3000,
      });
    } finally {
      setLoadings(false);
    }
  };

  const [validationErrors, setValidationErrors] = useState({
    name: "",
    rows: Array(rows.length).fill({
      instrument: "",

    }),
  });

  const validateFields = () => {
    let isValid = true;
    const errors = { ...validationErrors };

    if (!editedBasket.name.trim()) {
      errors.name = "Basket Name is required";
      isValid = false;
    } else if (editedBasket.name.length < 3) {
      errors.name = "Basket Name must be at least 3 characters";
      isValid = false;
    } else if (editedBasket.name.length > 50) {
      errors.name = "Basket Name must be at most 50 characters";
      isValid = false;
    } else {
      errors.name = "";
    }



    rows.forEach((row, index) => {
      const rowErrors = { ...errors.rows[index] };


      if (!row.instrument.trim()) {
        rowErrors.instrument = "Instrument is required";
        isValid = false;
      } else {
        rowErrors.instrument = "";
      }


      if (!row.lot_quantity_buffer.trim()) {
        rowErrors.lot_quantity_buffer = "Lot QTY Buffer is required";
        isValid = false;
      } else if (!/^[0-9]+$/.test(row.lot_quantity_buffer)) {
        rowErrors.lot_quantity_buffer = "Only positive numbers are allowed";
        isValid = false;
      } else if (parseInt(row.lot_quantity_buffer) <= 0) {
        rowErrors.lot_quantity_buffer = "Lot QTY Buffer must be greater than 0";
        isValid = false;
      } else {
        rowErrors.lot_quantity_buffer = "";
      }

      errors.rows[index] = rowErrors;
    });


    setValidationErrors(errors);

    return isValid;
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };


  const handleCloseModal = () => {
    setModalOpen(false);
    setMessage("");        
    setError("");          
      
    setGlobalFilter("");    
  
  
  };
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  const isMarketOpen = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    const currentDay = currentTime.getDay();

    const marketOpenHour = 9;
    const marketOpenMinute = 15;
    const marketCloseHour = 15;
    const marketCloseMinute = 30;

    if (currentDay === 0 || currentDay === 6) {
      return false;
    }

    if (
      (currentHour > marketOpenHour ||
        (currentHour === marketOpenHour &&
          currentMinute >= marketOpenMinute)) &&
      (currentHour < marketCloseHour ||
        (currentHour === marketCloseHour && currentMinute <= marketCloseMinute))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 30)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

 

  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success";
    } else if (hours === 15) {
      return "danger";
    }
    return "secondary";
  };
  const handleToggleChange = () => {
    setOnlyTeacherExecute(!onlyTeacherExecute);
  };
  return (
    <div>
      <Toast ref={toast} />
      <Header />
      <SubHeader />
      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <ConfirmDialog />

      <div className="mt-3 container-xxl">
        {isMarketOpen() ? (
          <div
            className="text-center "
            style={{
              border: "2px solid green",
              padding: "10px",
              color: "green",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Open
          </div>
        ) : (
          <div
            className="text-center "
            style={{
              border: "2px solid orange",
              padding: "10px",
              color: "orange",
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            Market is Closed
          </div>
        )}
      </div>

      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="container-xxl flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 text-black">
                <li className="breadcrumb-item">
                  <Link to="/teacher/dashboard" className="text-black">
                    <i className="ri-home-7-line ri-lg"></i>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-secondary"
                  aria-current="page"
                >
                  Basket
                </li>
              </ol>
            </nav>
            <div className="row">
              <div className="col-xl-9 col-lg-9 col-md-7 col-12 mt-3">
                <div className="card">
                  <div className="col-7 text-start mb-5 mt-5 ms-3 d-flex align-items-center justify-content-between">
                    <button
                      onClick={handleBack}
                      className="btn rounded-pill btn-outline-secondary btn-xs "
                    >
                      <i className="ri-arrow-left-circle-fill me-1 ri-md"></i>{" "}
                      Back
                    </button>
                    <strong
                      className="mb-0 text-center"
                      style={{ fontSize: "1.4rem" }}
                    >
                      Create Basket
                    </strong>
                  </div>

                  <div className="mx-3 align-items-center mb-3">
                    <div
                      className={`form-floating form-floating-outline ${validationErrors.name ? "has-error" : ""
                        }`}
                    >
                      <input
                        type="text"
                        className={`form-control form-control-sm me-3 w-50 ${validationErrors.name ? "is-invalid" : ""
                          }`}
                        placeholder="Enter basket name"
                        value={editedBasket.name}
                        onChange={handleNameChange}
                      />
                      <label htmlFor="brokerApiKey">
                        {" "}
                        <span className="text-danger">* </span>Basket Name
                      </label>
                      {validationErrors.name && (
                        <span className="text-danger">
                          {validationErrors.name}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="m-3 table-responsive table-bordered">
                    <table className="table table-sm">
                      <thead>
                        <tr>
                          <th>Sr</th>
                          <th>Instrument</th>
                          <th>Lot Qty Buffer</th>

                          <th>Trans. Type</th>
                          <th>Exchange</th>
                          <th>Order Type</th>
                          <th>Product Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rows.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>

                            <td>
  <AutoComplete
    value={row.instrument}
    suggestions={items}
    completeMethod={search}
    panelStyle={{
      width: "200px",
      fontSize: "14px",
    }}
    onChange={(e) => handleAutoCompleteChange(e, index)}
    placeholder="Instruments search"
  />
  {validationErrors.rows[index]?.instrument && (
    <span className="text-danger">
      {validationErrors.rows[index].instrument}
    </span>
  )}
</td>

<td>
                              <input
                                type="text"

                                className={`form-control ${
                                  validationErrors.rows[index]?.lot_quantity_buffer ? "is-invalid" : ""
                                }`}
                                name="lot_quantity_buffer"
                                placeholder="Enter Lot QTY Buffer"
                                value={row.lot_quantity_buffer}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              />
                              {validationErrors.rows[index]
                                ?.lot_quantity_buffer && (
                                <span className="text-danger">
                                  {
                                    validationErrors.rows[index]
                                      .lot_quantity_buffer
                                  }
                                </span>
                              )}
                            </td>

                            <td>
                              <select
                                type="text"
                                className="form-control form-control-sm"
                                name="transactionType"
                                value={row.transactionType}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              >
                                {" "}
                                <option value="BUY">BUY</option>
                                <option value="SELL">SELL</option>
                              </select>

                              {validationErrors.rows[index]
                                ?.transactionType && (
                                  <span className="text-danger">
                                    {validationErrors.rows[index].transactionType}
                                  </span>
                                )}
                            </td>
                            <td>
                              <select
                                type="text"
                                className="form-control form-control-sm"
                                name="exchange"
                                value={row.exchange}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              >
                                {" "}
                                <option value="NFO">NFO</option>
                                <option value="BFO">BFO</option>
                                <option value="BSE">BSE</option>
                                <option value="NSE">NSE</option>
                                <option value="MCX">MCX</option>
                                <option value="CDS">CDS</option>
                              </select>

                              {validationErrors.rows[index]?.exchange && (
                                <span className="text-danger">
                                  {validationErrors.rows[index].exchange}
                                </span>
                              )}
                            </td>
                            <td>
                              <select
                                type="text"
                                className="form-control form-control-sm"
                                name="orderType"
                                value={row.orderType}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              >
                                {" "}
                                <option value="MARKET">MARKET</option>
                                <option value="LIMIT">LIMIT</option>
                                <option value="STOPLOSS_LIMIT">
                                  STOPLOSS_LIMIT
                                </option>
                                <option value="STOPLOSS_MARKET">
                                  STOPLOSS_MARKET
                                </option>
                              </select>

                              {validationErrors.rows[index]?.orderType && (
                                <span className="text-danger">
                                  {validationErrors.rows[index].orderType}
                                </span>
                              )}
                            </td>
                            <td>
                              <select
                                type="text"
                                className="form-control form-control-sm"
                                name="productType"
                                value={row.productType}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              >
                                {" "}
                                <option value="CARRYFORWARD">
                                  CARRYFORWARD
                                </option>
                                <option value="DELIVERY">DELIVERY</option>
                                <option value="MARGIN">MARGIN</option>
                                <option value="INTRADAY">INTRADAY</option>
                                <option value="BO">BO</option>
                              </select>

                              {validationErrors.rows[index]?.productType && (
                                <span className="text-danger">
                                  {validationErrors.rows[index].productType}
                                </span>
                              )}
                            </td>
                            <td>
                              {index === 0 ? (
                                <div

                                  className="badge badge-center bg-lg rounded-pill bg-label-primary"
                                  onClick={handleAddRow}
                                >
                                  <i className="ri-add-large-line ri-md"></i>
                                </div>
                              ) : (
                                <div

                                  className="badge badge-center badge-lg rounded-pill bg-label-danger"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteRow(index);
                                  }}
                                >
                                  <i className="ri-close-large-line ri-sm"></i>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-6 text-end mb-5">

                    <button

                      className="btn btn-success rounded-pill  text-end me-3"
                      disabled={loadings}
                      onClick={handleCreateBasket}
                    >
                      {loadings ?  <div className="spinner-border me-2" role="status">
  <span className="visually-hidden">Loading...</span>
</div> :
                        <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                      } {loadings ? "Saving Data" : "Save Data"}
                    </button>
                  </div>
                  <Toast
                    ref={toast}
                    position="top-right"
                    className="text-start"
                  />

                </div>
               
              </div>
              {baskets.length > 0 && (
              <div className="col-xl-3 col-lg-3 col-md-5 col-12 mt-3">
                <div className="card">
                  <div className="col-5 text-start mb-5 mt-2  ms-auto">


                    <button
                      onClick={handleDeleteAllBaskets}
                      className="btn btn-warning btn-xs rounded-pill me-0"
                    >
                      <i className="ri-close-large-line me-1 ri-lg"></i> Delete
                      all
                    </button>

                    <Modal show={showModal} onHide={closeModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        Are you sure you want to delete all baskets?
                      </Modal.Body>
                      <Modal.Footer>
                        <div className="d-flex justify-content-between w-100">
                          <button
                            onClick={closeModal}
                            className="btn btn-outline-secondary rounded-pill btn-sm"
                          >
                            <i className="ri-close-large-line me-2"></i>
                            <span>Cancel</span>
                          </button>
                          <button
                            onClick={confirmDeleteAll}
                            disabled={deleteLoading}
                            className="btn btn-danger rounded-pill btn-sm"
                          >
                            <i className="ri-close-large-line me-2"></i>
                            {deleteLoading ? "Deleting..." : "Delete All"}
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>

                  <div className="d-flex justify-content-start ms-4 mb-3 me-3">
                    <IconField iconPosition="left">
                      <InputIcon className="ri ri-search-line"></InputIcon>
                      <InputText
                        type="search"
                        placeholder="Search"
                        value={globalFilter}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        className="rounded custom-search-box"
                      />
                    </IconField>
                    {loading ? (
                      <i className=" custom-target-icon ri-loader-2-line ri-lg ms-3 mt-3 p-text-secondary"></i>
                    ) : (
                      <div className="mt-3">
                        <Tooltip target=".custom-target-icon" />
                        <i
                          className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary "
                          data-pr-tooltip="Reload"
                          onClick={handleRefresh}
                          data-pr-position="top"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                    )}
                  </div>

                  <VirtualScroller
    items={filterBaskets()}
    itemSize={70}
    itemTemplate={(basket, options) => (
        <div
            key={basket.basket_id}
            className="card-datatable table-responsive pt-0"
        >
             <Tooltip target={`.custom-tooltip-icon-${basket.basket_id}`}>
                <div>
                    {Array.isArray(basket.instrument_details) && basket.instrument_details.length > 0 ? (
                        basket.instrument_details.map((instrument, index) => (
                            <div key={index}>
                                <ul>
                                    <li> {instrument.instrument} - {instrument.transaction_type}</li>
                                </ul>
                            </div>
                        ))
                    ) : (
                        <div>No instruments available</div> // Optionally handle the case where instrument_details is missing or empty
                    )}
                </div>
            </Tooltip>
      
            <table className="table table-sm">
                <tbody>
                    <tr>
                        <td
                            className="fw-bold"
                            colSpan="2"
                            onClick={() => handleEditBasket(options.index)}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            style={{ border: "none", cursor: "pointer" }}
                        >
                            {titleCase(
                                basket.name || getFormattedBasketName(options.index)
                            )}
                        </td>
                        <td className="text-center" style={{ border: "none" }}>
                            <div
                                className="badge badge-center bg-lg rounded-pill bg-label-danger"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteBasket1(options.index);
                                }}
                            >
                                <i className="ri-close-large-line ri-xs"></i>
                            </div>
                        </td>
                    </tr>
                    <tr
                        onClick={() => handleEditBasket(options.index)}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                    >
                        <td>
                            <i className={`ri ri-information-line me-2 custom-tooltip-icon-${basket.basket_id}`}></i>
                            <small>
                                Total: {basket.total_instruments_count}/30
                            </small>
                        </td>
                        <td>
                            <small>
                                <span
                                    className={
                                        basket.buy_instruments_count > 0
                                            ? "text-success"
                                            : ""
                                    }
                                >
                                    <span className="text-secondary">Buy:</span>{" "}
                                    {basket.buy_instruments_count}
                                </span>
                            </small>
                        </td>
                        <td>
                            <small>
                                <span
                                    className={
                                        basket.sell_instruments_count > 0
                                            ? "text-danger"
                                            : ""
                                    }
                                >
                                    <span className="text-secondary">Sell:</span>{" "}
                                    {basket.sell_instruments_count}
                                </span>
                            </small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )}
    delay={250}
    className="border-1 surface-border border-round"
    style={{ height: "500px" }}
/>



                </div>
              </div>
              )}
              <div
                // className="modal fade "
                // id="staticBackdrop"
                // data-bs-backdrop="static"
                // data-bs-keyboard="false"
                // tabindex="-1"
                // aria-labelledby="staticBackdropLabel"
                // aria-hidden="true"
                className="modal fade"
                id="exampleModal"
                 data-bs-backdrop="static"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title text-center w-100"
                        id="exampleModalLabel"
                      >
                        {titleCase(currentBasket?.name)}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleCloseModal}
                      ></button>
                    </div>
                    <div className="modal-body table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Sr</th>
                            <th>Instrument</th>
                            <th>Lot Qty Buffer</th>

                            <th>Trans. Type</th>
                            <th>Exchange</th>
                            <th>Order Type</th>
                            <th>Product Type</th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentBasket?.instruments.map((row, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
  <AutoComplete
    suggestions={items}
    completeMethod={search}
    panelStyle={{
      width: "200px",
      fontSize: "14px",
    }}
    name="instrument"
    placeholder="Enter instrument"
    value={row.instrument}
    onChange={(event) => handleInputChange(index, event)}
  />
  <br />
  {errors[index]?.instrument && (
    <span className="error-message text-danger">
      {errors[index].instrument}
    </span>
  )}
</td>



                             
 <td>
                              <input
                                type="text"

                                className={`form-control ${
                                  validationErrors.rows[index]?.lot_quantity_buffer ? "is-invalid" : ""
                                }`}
                                name="lot_quantity_buffer"
                                placeholder="Enter Lot QTY Buffer"
                                value={row.lot_quantity_buffer}
                                onChange={(event) =>
                                  handleInputChanges(index, event)
                                }
                              />
                              {validationErrors.rows[index]
                                ?.lot_quantity_buffer && (
                                <span className="text-danger">
                                  {
                                    validationErrors.rows[index]
                                      .lot_quantity_buffer
                                  }
                                </span>
                              )}
                            </td>
                              <td>
                                <select
                                  type="text"
                                  className="form-control "
                                  name="transaction_type"
                                  value={row.transaction_type}
                                  placeholder="Transaction Type"
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                >
                                  {" "}
                                  <option value="BUY">BUY</option>
                                  <option value="SELL">SELL</option>
                                </select>
                              </td>

                              <td>
                                <select
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="exchange"
                                  value={row.exchange}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                >
                                  {" "}
                                  <option value="NFO">NFO</option>
                                  <option value="BFO">BFO</option>
                                  <option value="BSE">BSE</option>
                                  <option value="NSE">NSE</option>
                                  <option value="MCX">MCX</option>
                                  <option value="CDS">CDS</option>
                                </select>
                              </td>
                              <td>
                                <select
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="order_type"
                                  value={row.order_type}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                >
                                  {" "}
                                  <option value="MARKET">MARKET</option>
                                  <option value="LIMIT">LIMIT</option>
                                  <option value="STOPLOSS_LIMIT">
                                    STOPLOSS_LIMIT
                                  </option>
                                  <option value="STOPLOSS_MARKET">
                                    STOPLOSS_MARKET
                                  </option>
                                </select>
                              </td>
                              <td>
                                <select
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="product_type"
                                  value={row.product_type}
                                  onChange={(event) =>
                                    handleInputChange(index, event)
                                  }
                                >
                                  {" "}
                                  <option value="CARRYFORWARD">
                                    CARRYFORWARD
                                  </option>
                                  <option value="DELIVERY">DELIVERY</option>
                                  <option value="MARGIN">MARGIN</option>
                                  <option value="INTRADAY">INTRADAY</option>
                                  <option value="BO">BO</option>
                                </select>
                              </td>
                              <td>

                                {index === 0 ? (

                                  <div

                                    className="badge badge-center badge-lg rounded-pill bg-label-primary "
                                    onClick={handleAddRowModal}
                                  >
                                    <i className="ri-add-line ri-md"></i>
                                  </div>
                                ) : (

                                  <div

                                    className="badge badge-center badge-lg rounded-pill bg-label-danger"
                                    onClick={() => handleDeleteRowModal(index)}
                                  >
                                    <i className="ri-close-large-line ri-sm"></i>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="modal-footer">

                      <div className="d-flex align-items-center">
                       <div className="switch-label me-3">Execute Teacher only?</div>
              <label className="switch me-5">
                <input
                  type="checkbox"
                  className="switch-input"
                  checked={onlyTeacherExecute}
                  onChange={handleToggleChange}
                />
                <span className="switch-toggle-slider">
                  <span className="switch-on"></span>
                  <span className="switch-off"></span>
                </span>
                <div className="switch-label"></div>

              </label>
               {isLoading && (
    <div className="d-flex align-items-center ms-6">
      <i className="ri ri-checkbox-circle-fill text-success me-2 ri-lg"></i>
      <span className="me-3">
        {onlyTeacherExecute
          ? `Executing ${currentBasket.instruments.length} instruments on teacher only`
          : `Executing ${currentBasket.instruments.length} instruments on teacher & students`}
      </span>
    </div>
  )} 
                      

                        <button
                          type="button"
                          className="btn btn-info d-flex align-items-center"
                          onClick={handleExecuteAll}
                          disabled={
                            isLoading
                          
                          }
                        >
                          {isLoading ? (
                            <>
                            <div className="spinner-border me-2" role="status">
  <span className="visually-hidden">Loading...</span>
</div>
                              Executing orders
                            </>
                          ) : (
                            <>
                              <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                              Execute All
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardLinks></CardLinks>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Basket;

const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red";
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};



