import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate
import MetaTags from "./MetaTags";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import HelpFAQ from "./HelpFAQ";
import img from "../../../assets/img/pages/header-light.png";

const HelpCenter = () => {
  const [faqDetails, setFaqDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate(); // Initialize navigate for navigation

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Function to handle search input change and fetch results
  const handleSearchChange = async (e) => {
    const inputValue = e.target.value;
    setSearchQuery(inputValue);

    if (!inputValue) {
      setSearchResults([]);
      return;
    }

    try {
      const response = await axios.post(
        "https://ghanish.in/api/website/search_faq",
        { keyword: inputValue },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.data.st === 1 && response.data.data.length > 0) {
        // Only set results if there are data entries
        setSearchResults(response.data.data);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      console.error("Error fetching FAQ options:", error);
      // Avoid showing error on screen, just log it and clear results
      setSearchResults([]);
    }
  };

  // Function to handle selecting a search result
  const handleSelect = (faqId) => {
    // Redirect to the FAQ details page using navigate
    navigate(`/faq/${faqId}`);
  };

  return (
    <div>
      <MetaTags />
      <LandingHeader />

      <section className="section-py first-section-pt help-center-header position-relative overflow-hidden">
        {/* Background image */}
        <img
          className="banner-bg-img position-absolute top-0 start-0 w-100 h-100"
          src={img}
          alt="Help center header"
          style={{ objectFit: "cover", zIndex: -1 }}
        />

        {/* Header text */}
        <div className="text-center my-5 position-relative z-1">
          <h4 className="text-primary mb-3">Hello, how can we help?</h4>

          {/* Search input */}
          <div
            className="input-group input-group-merge mx-auto w-75 bg-white"
            style={{ maxWidth: "600px" }}
          >
            <span className="input-group-text">
              <i className="tf-icons ri-search-line"></i>
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              aria-label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          {/* Dropdown for search suggestions */}
          {searchResults.length > 0 && (
            <ul
              className="list-group position-absolute top-100 start-50 translate-middle-x mt-2 shadow-sm"
              style={{
                zIndex: 1050,
                maxHeight: "200px",
                overflowY: "auto",
                width: "600px",
                backgroundColor: "#fff",
                borderRadius: "0.25rem",
              }}
            >
              {searchResults.map((item) => (
                <li
                  key={item.faq_id}
                  className="list-group-item list-group-item-action"
                  onClick={() => handleSelect(item.faq_id)} // Redirect to FAQ details
                >
                  {item.question}
                </li>
              ))}
            </ul>
          )}

          {/* Helper text */}
          <p className="text-center px-4 mb-0 text-muted  ">
            Common troubleshooting topics: eCommerce, Blogging to payment
          </p>
        </div>
      </section>

      {loading && <p className="text-center mt-4">Loading...</p>}

      <HelpFAQ />
      <LandingFooter />
    </div>
  );
};

export default HelpCenter;
