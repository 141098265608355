import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";

const UpdateTeacher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const studentData = location.state || {};
  const { id } = useParams();
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedBroker, setSelectedBroker] = useState("angle_one");

  const handleBrokerChange = (broker) => {
    setSelectedBroker(broker);
  };
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          throw new Error("Auth token not found");
        }

        const response = await axios.post(
          `${config.apiDomain}/api/admin/manage_teachers/view`,
          { teacher_id: id },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data && response.data.st === 1) {
          setTeacherData(response.data.data);
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 401) {
          navigate("/commonlogin");
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, navigate]);

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!teacherData.name) {
      errors.name = "Name is required";
      isValid = false;
    } else if (/\d/.test(teacherData.name)) {
      errors.name = "Name cannot contain digits";
      isValid = false;
    } else if (/[^a-zA-Z\s]/.test(teacherData.name)) {
      errors.name = "Name cannot contain special characters";
      isValid = false;
    } else if (teacherData.name.length < 3) {
      errors.name = "Name must be at least 3 characters";
      isValid = false;
    } else if (teacherData.name.length > 50) {
      errors.name = "Name must be at most 50 characters";
      isValid = false;
    }

    if (!teacherData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else {
      const emailPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const localPart = teacherData.email.split("@")[0];

      if (!emailPattern.test(teacherData.email)) {
        errors.email = "Email is invalid";
        isValid = false;
      } else if (/[^a-zA-Z0-9._+-]/.test(localPart)) {
        errors.email =
          "Email can only contain special characters (., -, _, +) in the local part";
        isValid = false;
      }
    }

    if (!teacherData.mobile) {
      errors.mobile = "Mobile number is required";
      isValid = false;
    } else if (!/^\d+$/.test(teacherData.mobile)) {
      errors.mobile = "Mobile number must contain only digits";
      isValid = false;
    } else if (teacherData.mobile.length !== 10) {
      errors.mobile = "Mobile number must be exactly 10 digits";
      isValid = false;
    }

    if (teacherData.lot_size_limit === "") {
      errors.lot_size_limit = "Lot size limit is required";
      isValid = false;
    } else if (isNaN(teacherData.lot_size_limit)) {
      errors.lot_size_limit = "Lot size limit must be a number";
      isValid = false;
    } else {
      const lotSizeLimit = parseInt(teacherData.lot_size_limit, 10);
      if (lotSizeLimit < 1 || lotSizeLimit > 5000) {
        errors.lot_size_limit = "Lot size limit must be between 1 and 5000";
        isValid = false;
      }
    }

    if (!teacherData.commission) {
      errors.commission = "Commission is required";
      isValid = false;
    } else if (!/^[0-9%]+$/.test(teacherData.commission)) {
      errors.commission =
        "Commission must only contain digits and the '%' character";
      isValid = false;
    } else if (
      teacherData.commission.includes("%") &&
      teacherData.commission.length === 1
    ) {
      errors.commission = "Commission cannot be just '%'";
      isValid = false;
    }



    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleChange = (field) => (e) => {
    const { value } = e.target;
    setTeacherData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Clear specific error if the input becomes valid
    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      switch (field) {
        case "name":
          if (
            value &&
            !/\d/.test(value) &&
            !/[^a-zA-Z\s]/.test(value) &&
            value.length >= 3 &&
            value.length <= 50
          ) {
            delete updatedErrors.name;
          }
          break;
        case "email":
          const emailPattern =
            /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const localPart = value.split("@")[0];
          if (
            value &&
            emailPattern.test(value) &&
            !/[^a-zA-Z0-9._+-]/.test(localPart)
          ) {
            delete updatedErrors.email;
          }
          break;
        case "mobile":
          if (value && /^\d+$/.test(value) && value.length === 10) {
            delete updatedErrors.mobile;
          }
          break;
        case "lot_size_limit":
          if (
            value &&
            !isNaN(value) &&
            parseInt(value, 10) >= 1 &&
            parseInt(value, 10) <= 5000
          ) {
            delete updatedErrors.lot_size_limit;
          }
          break;
        case "commission":
          if (
            value &&
            /^[0-9%]+$/.test(value) &&
            !(value.includes("%") && value.length === 1)
          ) {
            delete updatedErrors.commission;
          }
          break;
       
        default:
          break;
      }

      return updatedErrors;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validate()) return;

    const payload = {
      teacher_id: id,
      name: teacherData.name,
      mobile: teacherData.mobile,
      email: teacherData.email,
      commission: teacherData.commission,
      lot_size_limit: teacherData.lot_size_limit,
     
      broker:"angle_one",
      broker_details:{
          api_key: teacherData.api_key,
          client_id: teacherData.client_id,
          password: teacherData.password,
          qr_totp_token: teacherData.qr_totp_token
      }
    };

    try {
      setError(null);

      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        throw new Error("Auth token not found");
      }

      const response = await axios.put(
        `${config.apiDomain}/api/admin/manage_teachers/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data.st === 1) {
        // Show success toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: formatMessage(response.data.msg) || "Update successful",
          life: 3000,
        });
        setTimeout(() => navigate("/admin/manage_teacher"), 1000);
      } else {
        // Show error toast
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            formatMessage(response.data.msg) || "Failed to update student",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error updating data:", error);
      if (error.response && error.response.status === 401) {
        navigate("/commonlogin");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            formatMessage(error.response?.data?.msg) ||
            error.message ||
            "Failed to fetch data",
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const formatMessage = (msg) => {
    if (typeof msg === "object") {
      return Object.values(msg).flat().join(", ");
    }
    return String(msg);
  };

  const toLowerCase = (string) => {
    return string.toLowerCase();
  };

  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          <div className="Container-xxl">
            <div className="container-xxl flex-grow-1 container-p-y">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-style1">
                  <li className="breadcrumb-item">
                    <Link to="/admin/dashboard" className="text-black">
                      <i className="ri-home-7-line ri-lg"></i>
                    </Link>
                  </li>
                  <li className="breadcrumb-item text-black">
                    <Link to="/admin/manage_teacher">Manage Teacher</Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-secondary"
                    aria-current="page"
                  >
                    Update Teacher
                  </li>
                </ol>
              </nav>

              {teacherData && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-6">
                      <div className="d-flex justify-content-between align-items-center card-header">
                        <button
                          onClick={handleBack}
                          className="btn rounded-pill btn-outline-secondary btn-xs"
                        >
                          <i className="ri-arrow-left-circle-fill me-1 ri-md"></i>{" "}
                          Back
                        </button>
                        <h5 className="text-center mb-0 flex-grow-1">
                          Update Teacher
                        </h5>
                      </div>
                      <div className="card-body pt-0">
                        <form id="formAccountSettings" onSubmit={handleSubmit}>
                          <div className="row mt-1 g-3">
                            <h5 className="text-start">
                              {" "}
                              <i className="ri-user-line ri-ms me-2"></i>
                              Personal Information
                            </h5>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  className={`form-control ${
                                    validationErrors.name ? "is-invalid" : ""
                                  }`}
                                  type="text"
                                  id="name"
                                  name="name"
                                  placeholder="Name"
                                  value={teacherData.name}
                                  onChange={handleChange("name")}
                                />
                                <label htmlFor="name">
                                  <span className="text-danger">*</span> Name
                                </label>
                                {validationErrors.name && (
                                  <span className="error">
                                    {validationErrors.name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="input-group input-group-merge">
                                <div className="form-floating form-floating-outline">
                                  <input
                                    type="text"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    className={`form-control ${
                                      validationErrors.mobile
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="202 555 0111"
                                    value={teacherData.mobile}
                                    onChange={handleChange("mobile")}
                                    maxLength="10"
                                  />
                                  <label htmlFor="mobileNumber">
                                    <span className="text-danger">*</span>{" "}
                                    Mobile Number
                                  </label>
                                  {validationErrors.mobile && (
                                    <span className="error">
                                      {validationErrors.mobile}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="form-floating form-floating-outline">
                                <input
                                  className={`form-control ${
                                    validationErrors.email ? "is-invalid" : ""
                                  }`}
                                  type="email"
                                  id="email"
                                  name="email"
                                  placeholder="john.doe@example.com"
                                  value={toLowerCase(teacherData.email)}
                                  onChange={handleChange("email")}
                                />
                                <label htmlFor="email">
                                  <span className="text-danger">*</span> E-mail
                                </label>
                                {validationErrors.email && (
                                  <div className="invalid-feedback">
                                    {validationErrors.email}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-1">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    validationErrors.lot_size_limit
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="lot_size_limit"
                                  name="lot_size_limit"
                                  placeholder="Lot Size Limit"
                                  value={teacherData.lot_size_limit}
                                  onChange={handleChange("lot_size_limit")}
                                  maxLength="5000"
                                />
                                <label htmlFor="lot_size_limit">
                                  <span className="text-danger">*</span> Lot
                                  Size Limit
                                </label>
                                {validationErrors.lot_size_limit && (
                                  <div className="invalid-feedback">
                                    {validationErrors.lot_size_limit}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-1">
                              <div className="form-floating form-floating-outline">
                                <input
                                  type="number"
                                  className={`form-control ${
                                    validationErrors.commission
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  id="commission"
                                  name="commission"
                                  placeholder="Commission"
                                  value={teacherData.commission}
                                  onChange={handleChange("commission")}
                                />
                                <label htmlFor="commission">
                                  <span className="text-danger">*</span>{" "}
                                  Commission
                                </label>
                                {validationErrors.commission && (
                                  <div className="invalid-feedback">
                                    {validationErrors.commission}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                       
                          <hr />
                          <div className="row mt-3 g-3">
                            <h5 className="text-start">
                              {" "}
                              <i className="ri-group-line ri-ms me-2"></i>
                              Broker Information
                            </h5>
                            <div className="nav-align-top mb-3">
                              <ul className="nav nav-pills" role="tablist">
                                <li className="nav-item">
                                  <button
                                    type="button"
                                    className={`nav-link ${
                                      selectedBroker === "angle_one"
                                        ? "active"
                                        : ""
                                    }`}
                                    role="tab"
                                    onClick={() =>
                                      handleBrokerChange("angle_one")
                                    }
                                  >
                                    Angle One
                                  </button>
                                </li>
                                {/* <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${selectedBroker === "zerodha" ? "active" : ""}`}
            role="tab"
            onClick={() => handleBrokerChange("zerodha")}
          >
            Zerodha
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={`nav-link ${selectedBroker === "dhan" ? "active" : ""}`}
            role="tab"
            onClick={() => handleBrokerChange("dhan")}
          >
            Dhan
          </button>
        </li> */}
                              </ul>

                              <div
                                className={`tab-pane fade ${
                                  selectedBroker === "angle_one"
                                    ? "show active"
                                    : ""
                                }`}
                                id="navs-pills-top-home"
                                role="tabpanel"
                              >
                                {teacherData.broker_details && (
                                  <div className="row mt-6">
                                    <div className="col-md-3">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            validationErrors.client_id
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="client_id"
                                          name="client_id"
                                          placeholder="Broker Client ID"
                                          value={
                                            teacherData.broker_details.client_id
                                          }
                                          onChange={handleChange("client_id")}
                                        />
                                        <label htmlFor="client_id">
                                          <span className="text-danger">*</span>{" "}
                                          Broker Client ID
                                        </label>
                                        {validationErrors.client_id && (
                                          <div className="invalid-feedback">
                                            {validationErrors.client_id}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-2">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="password"
                                          className={`form-control ${
                                            validationErrors.password
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="password"
                                          name="password"
                                          placeholder="Broker Password"
                                          value={
                                            teacherData.broker_details.password
                                          }
                                          onChange={handleChange("password")}
                                        />
                                        <label htmlFor="brokerPassword">
                                          <span className="text-danger">*</span>{" "}
                                          Broker Password
                                        </label>
                                        {validationErrors.password && (
                                          <div className="invalid-feedback">
                                            {validationErrors.password}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            validationErrors.qr_totp_token
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="qr_totp_token"
                                          name="qr_totp_token"
                                          placeholder="Broker QR TOTP Token"
                                          value={
                                            teacherData.broker_details
                                              .qr_totp_token
                                          }
                                          onChange={handleChange(
                                            "qr_totp_token"
                                          )}
                                        />
                                        <label htmlFor="qr_totp_token">
                                          <span className="text-danger">*</span>{" "}
                                          Broker QR TOTP Token
                                        </label>
                                        {validationErrors.qr_totp_token && (
                                          <div className="invalid-feedback">
                                            {validationErrors.qr_totp_token}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="form-floating form-floating-outline">
                                        <input
                                          type="text"
                                          className={`form-control ${
                                            validationErrors.api_key
                                              ? "is-invalid"
                                              : ""
                                          }`}
                                          id="api_key"
                                          name="api_key"
                                          placeholder="Broker API Key"
                                          value={
                                            teacherData.broker_details.api_key
                                          }
                                          onChange={handleChange("api_key")}
                                        />
                                        <label htmlFor="api_key">
                                          <span className="text-danger">*</span>{" "}
                                          Broker API Key
                                        </label>
                                        {validationErrors.api_key && (
                                          <div className="invalid-feedback">
                                            {validationErrors.api_key}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="navs-pills-top-profile"
                              role="tabpanel"
                            ></div>
                            <div
                              className="tab-pane fade"
                              id="navs-pills-top-messages"
                              role="tabpanel"
                            ></div>
                          </div>
                          <Toast ref={toast} />
                          <div className="row">
                            <div className="col-5 text-start mb-5 mt-4">
                              <button
                                onClick={handleBack}
                                className="btn rounded-pill btn-outline-secondary btn-xs"
                              >
                                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i>{" "}
                                Back
                              </button>
                            </div>
                            <div className="col-7 text-end mb-5 mt-6">
                              <button
                                type="submit"
                                className="btn rounded-pill btn-info btn-sm"
                                disabled={loading}
                              >
                                {loading ? (
                                 <div className="spinner-border me-2" role="status">
                                 <span className="visually-hidden">Loading...</span>
                               </div>
                                ) : (
                                  <i className="ri-checkbox-circle-line ri-lg me-1"></i>
                                )}{" "}
                                {loading ? "Saving Data" : "Save Data"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default UpdateTeacher;
