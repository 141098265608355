// import SubHeaderS from "./SubHeaderS";
// import StudentHeader from "./StudentHeader";
// import { Link, useNavigate } from "react-router-dom";
// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Toast } from "primereact/toast";
// import { Modal, Button } from "react-bootstrap";
// import config from "../../../component/config";
// import img from "../../../assets/img/avatars/1.png";

// import { VirtualScroller } from "primereact/virtualscroller";
// import Footer from "../../../component/Footer";
// const LoginActivityLog = () => {
//   const [loading, setLoading] = useState(false);
//   const toast = React.useRef(null);
// const navigate=useNavigate();
//   const [reportData, setReportData] = useState(null);
//   const [userData, setUserData] = useState(null);
//   const [activities, setActivities] = useState([]);
//   const [successMessage, setSuccessMessage] = useState("");
//   const capitalizeFirstLetter = (string) => {
//     return string.charAt(0).toUpperCase() + string.slice(1);
//   };
//   useEffect(() => {
//     const fetchUserProfile = async () => {
//       try {
//         const userId = localStorage.getItem("userId");
//         const authToken = localStorage.getItem("authToken");
       
//         if (!userId) {
//           console.error("User ID not found in localStorage");
//           return;
//         }
  
//         if (!authToken) {
//           console.error("Access token not found in localStorage");
//           navigate("/commonlogin")
//           return;
//         }
  
//         const response = await fetch(
//           `${config.apiDomain}/api/common/get_profile_details`,
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               "Authorization": `Bearer ${authToken}`
//             },
//             body: JSON.stringify({ user_id: userId }),
//           }
//         );
  
//         if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//         }
  
//         const data = await response.json();
  
//         if (data.st === 1) {
//           setUserData(data.user);
      
//         } else {
//           console.error("Failed to fetch user profile:", data.message);
//         }
//       }catch (error) {
//         console.error("Error fetching user profile:", error);
//         // Check for 401 Unauthorized error
//         if (error.response && error.response.status === 401) {
//           console.error("Unauthorized access, redirecting to common login screen");
//           navigate('/commonlogin'); // Navigate to common login screen on 401 error
//         }
//       }
//     };
  
//     fetchUserProfile();
//   }, []); // Ensure the dependency array is empty
  
  

//   const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

//   useEffect(() => {
//     const checkTime = () => {
//       const now = new Date();
//       const hours = now.getHours();
//       const minutes = now.getMinutes();

//       // Check if it's 9:15 AM or 3:15 PM
//       if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
//         setShowPopup(true);
//       }
//     };

//     const interval = setInterval(() => {
//       checkTime();
//     }, 60000); // Every minute

//     // Clear interval on component unmount
//     return () => clearInterval(interval);
//   }, []);

//   const handleClosePopup = () => {
//     setShowPopup(false);
//   };

//   // Helper function to determine modal button variant
//   const getButtonVariant = () => {
//     const now = new Date();
//     const hours = now.getHours();

//     if (hours === 9) {
//       return "success"; // Green color for 9:15 AM
//     } else if (hours === 15) {
//       return "danger"; // Red color for 3:15 PM
//     }
//     return "secondary"; // Default color
//   };



 

//   const formatDateTime = (datetime) => {
//     const options = {
//       hour: "2-digit",
//       minute: "2-digit",
//       second: "2-digit",
//       hour12: true,
//     };
//     return new Date(datetime).toLocaleTimeString("en-US", options);
//   };

//   const formatDateDay = (datetime) => {
//     return new Date(datetime).getDate();
//   };

//   const formatDateMonth = (datetime) => {
//     return new Date(datetime).toLocaleString("default", { month: "short" });
//   };

//   const formatDateYear = (datetime) => {
//     return new Date(datetime).getFullYear();
//   };

 

  

//   const [isLoading, setIsLoading] = useState(false);

//   const generateReport = async () => {
//     try {
//       const authToken = localStorage.getItem('authToken');
//      const userId=localStorage.getItem('userId');
//       if (!authToken) {
//         console.error('Auth token not found in localStorage');
//         return;
//       }
  
//       const response = await axios.post(`${config.apiDomain}/api/common/login_activity_log`, {
//         user_id: userId,
//       }, {
//         headers: {
//           'Authorization': `Bearer ${authToken}`, // Include the auth token in the headers
//           'Content-Type': 'application/json',
//         },
//       });
  
//       if (response.data.st === 1) {
//         setReportData(response.data.activity_list);
//         toast.current.show({
//           severity: 'success',
//           summary: 'Success',
//           detail: 'Activity log fetched successfully.',
//           life: 3000,
//         });
//       } else {
//         toast.current.show({
//           severity: 'error',
//           summary: 'Error',
//           detail: response.data.msg,
//           life: 3000,
//         });
//       }
//     } catch (error) {
//       console.error("Error fetching user profile:", error);
//       // Check for 401 Unauthorized error
//       if (error.response && error.response.status === 401) {
//         console.error("Unauthorized access, redirecting to common login screen");
//         navigate('/commonlogin'); // Navigate to common login screen on 401 error
//       }
//     }
//   };
  
//   const itemTemplate = (item) => {
//     return (
//       <div className="mb-5">
//         <small className={`mb-1 d-inline ${item.Message ? 'text-success' : 'text-danger'}`}>
//           <small className="text-start text-muted">Status:</small> {item.Message}
//         </small>
//         <p className="mb-5 text-start">
//           Datetime: {formatDateTime(item.datetime)} {formatDateDay(item.datetime)}-
//           {formatDateMonth(item.datetime)}-{formatDateYear(item.datetime)}
//         </p>
//       </div>
//     );
//   };


//   return (
//     <div>
//     <StudentHeader />
//     <SubHeaderS />
//       <Toast ref={toast} />

//       <Modal
//         show={showPopup}
//         onHide={handleClosePopup}
//         dialogClassName={getColorModalClass()}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>{getModalTitle()}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>{getModalBody()}</p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant={getButtonVariant()} onClick={handleClosePopup}>
//             Ok
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
//         <div className="layout-container">
//           <div className="layout-page">
//             <div className="content-wrapper">
//               <div className="container flex-grow-1 container-p-y">
//               <nav aria-label="breadcrumb">
//   <ol className="breadcrumb breadcrumb-style1 text-secondary">
//     <li className="breadcrumb-item">
//       <Link to="/student/dashboard" className="text-secondary">
//         <i className="ri-home-7-line ri-lg"></i>
//       </Link>
//     </li>
//     <li className="breadcrumb-item active text-secondary" aria-current="page">
//       Profile
//     </li>
//   </ol>
// </nav>

//                 <div className="row">
//                   <div className="col-12">
//                     <div className="card mb-6">
//                       <div
//                         className="user-profile-header-banner"
//                         style={{
//                           background:
//                             "linear-gradient(90deg, rgba(0,128,255,1) 0%, rgba(0,255,128,1) 100%)",
//                           height: "164px",
//                           borderTopLeftRadius: "8px",
//                           borderTopRightRadius: "8px",
//                         }}
//                       ></div>
//                       <div className="user-profile-header d-flex flex-column flex-lg-row text-sm-start text-center mb-4">
//                         <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
//                           <img
//                             src={img}
//                             alt="user image"
//                             className="d-block h-auto ms-0 ms-sm-5 rounded user-profile-img"
//                           />
//                         </div>
//                         <div className="flex-grow-1 mt-3 mt-lg-5">
//                           <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-5 flex-md-row flex-column gap-4">
//                             <div className="user-profile-info">
//                               {userData ? (
//                                 <>
//                                   <h4 className="mb-2 mt-lg-6">
//                                     {" "}
//                                     {capitalizeFirstLetter(userData.name)}
//                                   </h4>
//                                   <ul className="list-inline mb-0 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-4">
//                                     <li className="list-inline-item">
//                                       <i className="ri-user-settings-line  ri-24px"></i>
//                                       <span className="fw-medium">
//                                         {" "}
//                                         {capitalizeFirstLetter(userData.role)}
//                                       </span>
//                                     </li>
//                                     <li className="list-inline-item">
//                                       <i className="ri-mobile-download-line  ri-24px"></i>
//                                       <span className="fw-medium">
//                                         {" "}
//                                         {userData.mobile}
//                                       </span>
//                                     </li>
//                                     <li className="list-inline-item">
//                                       <i className="ri-wallet-line  ri-24px"></i>
//                                       <span className="fw-medium">
//                                         {" "}
//                                         Commission: {userData.commission}%
//                                       </span>
//                                     </li>
//                                   </ul>
//                                 </>
//                               ) : (
//                                 <p>Loading...</p>
//                               )}
//                             </div>
//                             <div className="ms-auto">
//                               {userData && (
//                                 <span
//                                   className={`badge bg-success ${
//                                     userData.broker_conn_status
//                                       ? "bg-success"
//                                       : ""
//                                   }`}
//                                   style={{ fontSize: "14px" }}
//                                 >
//                                   {userData.broker_conn_status && (
//                                     <>
//                                       <i className="ri-shield-check-line me-1"></i>
//                                       Connected
//                                     </>
//                                   )}
//                                 </span>
//                               )}
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="row">
//                   <div className="col-md-12">
//                     <div className="nav-align-top">
//                       <ul className="nav nav-pills flex-column flex-sm-row mb-6 gap-2 gap-lg-0">
//                       <li className="nav-item  active">
//                    <Link to="/app2/student_profile"  className="nav-link ">
//                         <i className="ri-user-3-line me-1_5"></i>Profile
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                     <Link to="/student/report"  className="nav-link">
//                         <i className="ri-team-line me-1_5"></i>Reports
//                         </Link>
//                     </li>
//                     <li className="nav-item">
//                     <Link to="/student/login_activity_log"  className="nav-link active btn btn-primary">
//                         <i className="ri-team-line me-1_5"></i>Activity log
//                         </Link>
//                     </li>
//                       </ul>
//                     </div>
//                   </div>

//                   <div className="col-xl-6 mb-6 mb-xl-0">
//                     <div className="card">
//                       <div className="d-flex justify-content-between align-items-center card-header">
//                         <h5 className="text-center mb-0 flex-grow-1">Activity Log</h5>
//                       </div>
//                       <div className="card-body">
//                         <button className="btn btn-primary active me-3 mb-3" onClick={generateReport}>
//                           Activity Log
//                         </button>
                        
//                           <div style={{ height: '400px', overflowY: 'auto' }}>
//                             <VirtualScroller
//                               items={reportData}
//                               itemSize={50}
//                               itemTemplate={itemTemplate}
//                               className="custom-scrollbar"
//                             />
//                           </div>
                       
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <Footer />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LoginActivityLog;
// const getColorModalClass = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9 || hours === 15) {
//     return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
//   }
//   return "";
// };

// const getModalTitle = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is Open!";
//   } else if (hours === 15) {
//     return "Market is Closed!";
//   }
//   return "";
// };

// const getModalBody = () => {
//   const now = new Date();
//   const hours = now.getHours();

//   if (hours === 9) {
//     return "Market is currently open. Take necessary actions.";
//   } else if (hours === 15) {
//     return "Market is currently closed. Come back tomorrow.";
//   }
//   return "";
// };



import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Modal, Button } from "react-bootstrap";
import config from "../../../component/config";
import img from "../../../assets/img/avatars/1.png";

import { VirtualScroller } from "primereact/virtualscroller";
import Footer from "../../../component/Footer";
import StudentHeader from "./StudentHeader";
import SubHeaderS from "./SubHeaderS";
const LoginActivityLog = () => {
  const [loading, setLoading] = useState(false);
  const toast = React.useRef(null);
const navigate=useNavigate();
  const [reportData, setReportData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [activities, setActivities] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = localStorage.getItem("userId");
        const authToken = localStorage.getItem("authToken");
       
        if (!userId) {
          console.error("User ID not found in localStorage");
          return;
        }
  
        if (!authToken) {
          console.error("Access token not found in localStorage");
          navigate("/commonlogin")
          return;
        }
  
        const response = await fetch(
          `${config.apiDomain}/api/common/get_profile_details`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${authToken}`
            },
            body: JSON.stringify({ user_id: userId }),
          }
        );
  
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
  
        if (data.st === 1) {
          setUserData(data.user);
      
        } else {
          console.error("Failed to fetch user profile:", data.message);
        }
      }catch (error) {
        console.error("Error fetching user profile:", error);
        // Check for 401 Unauthorized error
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate('/commonlogin'); // Navigate to common login screen on 401 error
        }
      }
    };
  
    fetchUserProfile();
  }, []); // Ensure the dependency array is empty
  
  

  const [showPopup, setShowPopup] = useState(false); // State for displaying the Popup component

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      // Check if it's 9:15 AM or 3:15 PM
      if ((hours === 9 && minutes === 15) || (hours === 15 && minutes === 15)) {
        setShowPopup(true);
      }
    };

    const interval = setInterval(() => {
      checkTime();
    }, 60000); // Every minute

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // Helper function to determine modal button variant
  const getButtonVariant = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours === 9) {
      return "success"; // Green color for 9:15 AM
    } else if (hours === 15) {
      return "danger"; // Red color for 3:15 PM
    }
    return "secondary"; // Default color
  };



 

  const formatDateTime = (datetime) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    return new Date(datetime).toLocaleTimeString("en-US", options);
  };

  const formatDateDay = (datetime) => {
    return new Date(datetime).getDate();
  };

  const formatDateMonth = (datetime) => {
    return new Date(datetime).toLocaleString("default", { month: "short" });
  };

  const formatDateYear = (datetime) => {
    return new Date(datetime).getFullYear();
  };

  const handleConnectionStatus = (status) => {
    console.log("Connection status:", status);
  };

  

  const [isLoading, setIsLoading] = useState(false);

  const generateReport = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
     const userId=localStorage.getItem('userId');
      if (!authToken) {
        console.error('Auth token not found in localStorage');
        return;
      }
  
      const response = await axios.post(`${config.apiDomain}/api/common/login_activity_log`, {
        user_id: userId,
      }, {
        headers: {
          'Authorization': `Bearer ${authToken}`, // Include the auth token in the headers
          'Content-Type': 'application/json',
        },
      });
  
      if (response.data.st === 1) {
        setReportData(response.data.activity_list);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Activity log fetched successfully.',
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response.data.msg,
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      }
    }
  };
  
  const itemTemplate = (item) => {
    return (
      <div className="mb-5">
        <small className={`mb-1 d-inline ${item.Message ? 'text-success' : 'text-danger'}`}>
          <small className="text-start text-muted">Status:</small> {item.Message}
        </small>
        <p className="mb-5 text-start">
          Datetime: {formatDateTime(item.datetime)} {formatDateDay(item.datetime)}-
          {formatDateMonth(item.datetime)}-{formatDateYear(item.datetime)}
        </p>
      </div>
    );
  };


  return (
    <div>
     <StudentHeader></StudentHeader>
     <SubHeaderS></SubHeaderS>
      <Toast ref={toast} />

      <Modal
        show={showPopup}
        onHide={handleClosePopup}
        dialogClassName={getColorModalClass()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{getModalBody()}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={getButtonVariant()} onClick={handleClosePopup}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="layout-navbar-full layout-horizontal layout-without-menu">
      <div className="layout-page">
        <div className="content-wrapper">
          <div className="container flex-grow-1 container-p-y">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb breadcrumb-style1 text-black">
                <li className="breadcrumb-item">
                  <Link to="/teacher/dashboard" className="text-black">
                    <i className="ri-home-7-line ri-lg"></i>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active text-secondary"
                  aria-current="page"
                >
                  Profile
                </li>
              </ol>
            </nav>
          
              {userData && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-body pt-0">
                      <div className="user-info  mt-3 text-center">
               <h5>{capitalizeFirstLetter(userData.name)}</h5>
               <span className="badge bg-label-info rounded-pill"> {capitalizeFirstLetter(userData.role)}</span>
             </div>
          
            
                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex flex-column align-items-start mb-4">
                            <span className="fw-medium fs-5">About</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Name:</strong>
                            <span className="ml-auto">
                              {capitalizeFirstLetter(userData.name)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Role:</strong>
                            <span className="ml-auto">
                              {" "}
                              {capitalizeFirstLetter(userData.role)}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Broker Connection:</strong>
                            <span className="text-success ml-auto">
                              <div className="ms-auto">
                                <div
                                  className={`text-success ml-auto ${
                                    userData.broker_conn_status ? 'text-success' : 'text-danger'
                                  }`}
                                  onClick={() => handleConnectionStatus(!userData.broker_conn_status)}
                                >
                                  {userData.broker_conn_status ? (
                                    <><i className="ri-shield-check-line"></i> Connected</>
                                  ) : (
                                    <><i className="ri-close-large-line"></i> Disconnected</>
                                  )}
                                </div>
                              </div>
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Commission:</strong>
                            <span className="ml-auto">
                              {userData.commission}%
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Broker Acc. Balance:</strong>
                            <span className="ml-auto">
                              {(userData.amount || 0).toFixed(2)} Rs.
                            </span>
                          </li>
                        </ul>
                        <hr />
                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex flex-column align-items-start mb-4">
                            <span className="fw-medium fs-5">Contacts</span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Email:</strong>
                            <span className="ml-auto">
                              {userData.email}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Mobile:</strong>
                            <span className="ml-auto">
                              {userData.mobile}
                            </span>
                          </li>
                        </ul>
                        <hr />
                        <ul className="list-unstyled my-3 py-1">
                          <li className="d-flex justify-content-between align-items-center mb-4">
                            <strong>Lot Size Limit:</strong>
                            <span className="ml-auto">
                              {userData.lot_size_limit} Lot
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="row">
                      <div className="col-md-8 col-sm-7">
                        <div className="nav-align-top">
                          <ul className="nav nav-pills flex-column justify-content-start flex-md-row flex-wrap mb-6 row-gap-1">
                            <li className="nav-item active">
                              <Link to="/app2/student_profile" className="nav-link">
                                <i className="ri-user-3-line me-1_5"></i>Profile
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link to="/student/report" className="nav-link ">
                                <i className="ri-team-line me-1_5"></i>Reports
                              </Link>
                            </li>
                           
                            <li>
                            <Link to="/student/login_activity_log" className="nav-link active btn btn-primary " >
                        <i className="ri-team-line me-1_5"></i>Activity log
                      </Link>
                    </li>
                          </ul>
                        </div>
                      </div>
                  
                    </div>
                    

                  <div className="col-xl-6 mb-6 mb-xl-0">
                    <div className="card">
                      <div className="d-flex justify-content-between align-items-center card-header">
                        <h5 className="text-center mb-0 flex-grow-1">Activity Log</h5>
                      </div>
                      <div className="card-body">
                        <button className="btn btn-primary active me-3 mb-3" onClick={generateReport}>
                          Activity Log
                        </button>
                        
                          <div style={{ height: '400px', overflowY: 'auto' }}>
                            <VirtualScroller
                              items={reportData}
                              itemSize={50}
                              itemTemplate={itemTemplate}
                              className="custom-scrollbar"
                            />
                          </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )}
              </div>
              <Footer />
            </div>
          </div>
          
        </div>
      </div>
   
  );
};

export default LoginActivityLog;
const getColorModalClass = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9 || hours === 15) {
    return hours === 9 ? "modal-green" : "modal-red"; // Apply custom modal background colors
  }
  return "";
};

const getModalTitle = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is Open!";
  } else if (hours === 15) {
    return "Market is Closed!";
  }
  return "";
};

const getModalBody = () => {
  const now = new Date();
  const hours = now.getHours();

  if (hours === 9) {
    return "Market is currently open. Take necessary actions.";
  } else if (hours === 15) {
    return "Market is currently closed. Come back tomorrow.";
  }
  return "";
};
