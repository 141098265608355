import React, { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import config from "../../../component/config";
import { Toast } from "primereact/toast";
import HeatmapCalendar from "./HeatmapCalender";
import CardLinks from "../../../component/CardLinks";

const AdminMyReportDatewise = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const [summary, setSummary] = useState({
    total_trades_count: 0,
    total_profitable_trades: 0,
    total_losing_trades: 0,
    total_commission: 0.0,
  });
  const [month, setMonth] = useState(""); // State for month

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

      if (!userId || !authToken) {
        throw new Error("User ID or auth token not found");
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/datewise_report`,
        {
          user_id: userId,
          month_name: month, // Pass month_name to the API
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_date);
        setSummary(response.data.completed_trades);
      } else {
        setError(new Error("No data found"));
      }
    } catch (error) {
      setError(new Error(error.message || "Failed to fetch data"));
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    setError(null);

    try {
      const userId = localStorage.getItem("userId"); // Fetch the user ID from local storage
      const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage

      if (!userId || !authToken) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "User ID or auth token not found",
          life: 3000,
        });
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${config.apiDomain}/api/common/datewise_report`,
        {
          user_id: userId,
          month_name: month, // Pass month_name to the API
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.data && response.data.st === 1) {
        setData(response.data.completed_trades_per_date);
        setSummary(response.data.completed_trades);

        const successMsg = response.data.msg || "Success";
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: toTitleCase(successMsg),
          life: 3000,
        });
      } else if (response.data && response.data.st === 2) {
        const warningMsg = response.data.msg || "Warning";
        setError(new Error(warningMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(warningMsg),
          life: 3000,
        });
      } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // This should ideally be called once when the component mounts
  }, [month]); // Dependency array to ensure it runs when the month changes

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const CommissionBodyTemplate = (rowData) => {
    return ` ${rowData.commission} Rs.`;
  };

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleViewReport = (rowData) => {
    navigate(`/admin/report_details`, { state: { userId, month: rowData.sell_date } });
  };

  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/profile" className="text-black">
                Profile
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              My Report
            </li>
          </ol>
        </nav>

        <HeatmapCalendar />

        <div className="card p-5">
          <div className="row align-items-center">
            <div className="col-auto text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-center mb-5">
              <h5 className="mb-0">My Report</h5>
            </div>
            <div className="col-auto text-end mb-5">
              <Link to="/admin/teacher_list">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-group-line me-1 ri-lg"></i>{" "}
                    <span>Teacher Report</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_trades_count || 0}</h4>
              <p className="mt-0">Total Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_profitable_trades || 0}</h4>
              <p className="mt-0">Profitable Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_losing_trades || 0}</h4>
              <p className="mt-0">Losing Trades</p>
            </div>
            <div className="col-md-3">
              <h4 className="mb-0">{summary.total_commission || 0}</h4>
              <p className="mt-0">Total Commission</p>
            </div>
          </div>

          <div className="row mb-5 mt-5">
            <div className="col-lg-3 col-md-4 col-12">
              <div className="d-flex align-items-center">
                <InputText
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  placeholder="Enter month"
                />
                <Button
                  label="Reload"
                  className="p-button-primary ms-3"
                  onClick={handleRefresh}
                />
              </div>
            </div>
          </div>

          {loading ? (
            <div className="text-center">
              <ProgressSpinner />
            </div>
          ) : error ? (
            <div className="alert alert-danger">{error.message}</div>
          ) : (
            <DataTable value={data} paginator rows={10} globalFilter={globalFilter}>
              <Column field="sell_date" header="Date" />
              <Column field="trades_count" header="Trades Count" />
              <Column field="profit" header="Profit" />
              <Column field="commission" header="Commission" body={CommissionBodyTemplate} />
              <Column body={(rowData) => (
                <Button label="View Report" onClick={() => handleViewReport(rowData)} />
              )} />
            </DataTable>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AdminMyReportDatewise;
