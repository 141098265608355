import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import config from "../../../component/config";
import axios from "axios";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";

const SubscriptionPlan = () => {
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");

  const [backClicked, setBackClicked] = useState(false);
  const { subscriptionId,subscriptionType } = useParams();


  const fetchAllSubscriptionPlans = async (subscriptionId,subscriptionType) => {
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/teacher/view_all_subscription_plans`,
        {
          teacher_id: userId,
          subscription_id: subscriptionId,
          subscription_type:subscriptionType
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.st === 1) {
        setSubscriptionPlans(response.data.all_subscription_plans_list || []);

      } else {
        console.error("Failed to fetch subscription plans:", response.data.msg);
      }
    } catch (error) {
      console.error("API request error:", error);
    }
  };
  useEffect(() => {
    fetchAllSubscriptionPlans(subscriptionId,subscriptionType);
  }, [subscriptionId,subscriptionType]);


  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
  const anyPlanSubscribed = subscriptionPlans.some(plan => plan.subscribed);
  const handleSubscribe = (subscriptionId, subscriptionPlanId,subscriptionType) => {
    navigate(`/teacher/payment_gateway/${subscriptionId}/${subscriptionPlanId}/${subscriptionType}`);
  };


  return (
    <div>
      <Header></Header>
      <SubHeader></SubHeader>
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/billing" className="text-black">
                Subscriptions
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Subscription Plan
            </li>
          </ol>
        </nav>
        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-auto text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-center mb-5">
              <h5 className="mb-0">Subscription Plan</h5>
            </div>
            <div className="col-auto text-end mb-5">
              <Link to="/teacher/payment_history">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-money-rupee-circle-line me-1 ri-lg"></i>{" "}
                    <span>Payment History</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>




          <div class="col-xl-12 col-md-8 col-12 mb-md-0 mb-0">
            <div className="text-center col-12">

              <div className="container">
                <div className="row justify-content-center">
                  <div class="col-9 mb-0 text-center">
                    <div class="bs-stepper wizard-modern wizard-modern-example  text-center">
                      <div class="bs-stepper-header">
                        <div
                          class="step"
                          data-target="#account-details-modern"
                        >
                          <button
                            type="button"
                            class="step-trigger d-flex flex-column align-items-center"
                          >
                            <span class="bs-stepper-circle bg-primary custom-stepper-circle"></span>
                            <span class="bs-stepper-label  text-center">
                              <span class="d-flex flex-column align-items-center">
                                <span class="bs-stepper-title">
                                  Subscription Plan
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>

                        <div
                          className="progress mb-1 text-center rounded mt-3 mx-auto"
                          style={{ height: "9px", width: "55%" }}
                        >
                          <div
                            className="progress-bar rounded"
                            role="progressbar"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div
                          class="step"
                          data-target="#personal-info-modern"
                        >
                          <button
                            type="button"
                            class="step-trigger d-flex flex-column align-items-center"
                          >
                            <span class="bs-stepper-circle bg-white custom-stepper-circle d-flex justify-content-center align-items-center"></span>
                            <span class="bs-stepper-label mt-2 text-center">
                              <span class="d-flex flex-column gap-1">
                                <span class="bs-stepper-title">
                                  Payment
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                      <p className="text-center mb-0 mx-auto w-100">
                        All plans include 40+ advanced tools and features
                        to boost your product. Choose the best plan to fit
                        your needs. All plans include 40+ advanced tools
                        and features to boost
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center gy-3">
              {subscriptionPlans.map((plan) => (
                
                <div
                  className="col-md-3 mb-6 "
                  key={plan.subscription_plan_id}
                >
                  <div
                    className={`card border ${plan.subscribed ? "border-info" : "shadow"
                      } equal-height`}
                  >
                    <div className="card-body pt-4 d-flex flex-column">
                      <h4 className="card-title text-center text-capitalize">
                        {plan.plan_name}
                      </h4>
                      <div className="text-center h-px-50">
                        <div className="d-flex justify-content-center">
                          <sup className="h6 text-body text-primary fs-4 pricing-currency mt-9 mb-0 me-1">
                            ₹
                          </sup>
                          <h1 className="mb-0 text-primary">
                            {plan.pricing}
                          </h1>
                          <sub className="h6 text-body pricing-duration mt-auto mb-1">
                            /month
                          </sub>
                        </div>
                        <div className="text-center mt-3 mb-2" style={{ minHeight: "4em", overflow: "hidden", textOverflow: "ellipsis" }}>
                          <small className="price-yearly price-yearly-toggle text-muted">
                            {plan.plan_description}
                          </small>
                        </div>
                      </div>
                      <ul className="list-group ps-6 my-5 pt-5 flex-grow-1 mt-12">
                        <li className="mb-2">
                          Copy trades on{" "}
                          <strong>{plan.max_user_limit} student</strong>{" "}
                          account.
                          <div className="text-muted">
                            (Excluding Master)
                          </div>
                        </li>
                        <li className="mb-2">
                          <strong>{plan.service_period} days free</strong>{" "}
                          service
                        </li>
                        <li className="mb-2">
                          Reporting: Daily, Weekly, Monthly
                        </li>
                      </ul>

                    
                      // Assuming you pass a prop like `anyPlanSubscribed` which is true if any plan is subscribed
{plan.subscribed ? (
  // Display the "Subscribed" button for plans the user is subscribed to
  <button
    type="button"
    className="btn btn-outline-info d-grid w-100 mt-auto d-flex align-items-center justify-content-center"
    disabled
  >
    <i className="ri ri-checkbox-circle-line ri-lg me-2"></i>
    <span>Subscribed</span>
  </button>
) : (
  // If no plan is subscribed, display the "Subscribe Now" button for free plans (pricing === "0")
  (!anyPlanSubscribed && plan.pricing === "0") || plan.pricing !== "0" ? (
    <button
      type="button"
      className="btn btn-primary d-grid w-100 mt-auto"
      onClick={() =>
        handleSubscribe(plan.subscription_id, plan.subscription_plan_id, plan.subscription_type)
      }
    >
      Subscribe Now
    </button>
  ) : null
)}



                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
 </div>
 <CardLinks></CardLinks>
 </div>
 <Footer></Footer>
    </div>
  )
}

export default SubscriptionPlan





  {/* Show button based on subscription status */}
                      {/* {plan.subscribed ? (
              <button
                type="button"
                className="btn btn-outline-info d-grid w-100 mt-auto  d-flex align-items-center justify-content-center"
                disabled
              >
                <i className="ri ri-checkbox-circle-line ri-lg me-2"></i>
                <span>Subscribed</span>
              </button>
            ) : (
              !anyPlanSubscribed && (
                <button
                  type="button"
                  className="btn btn-primary d-grid w-100 mt-auto"
                  onClick={() => handleSubscribe(plan.subscription_id, plan.subscription_plan_id)}
                >
                  Subscribe Now
                </button>
              )
            )} */}
                      {/* {plan.subscribed ? (
                        <button
                          type="button"
                          className="btn btn-outline-info d-grid w-100 mt-auto  d-flex align-items-center justify-content-center"
                          disabled
                        >
                          <i className="ri ri-checkbox-circle-line ri-lg me-2"></i>
                          <span>Subscribed</span>
                        </button>
                      ) : (

                        <button
                          type="button"
                          className="btn btn-primary d-grid w-100 mt-auto"
                          onClick={() => handleSubscribe(plan.subscription_id, plan.subscription_plan_id,plan.subscription_type)}
                        >
                          Subscribe Now
                        </button>

                      )} */}

                      // {plan.subscribed ? (
                      //   <button
                      //     type="button"
                      //     className="btn btn-outline-info d-grid w-100 mt-auto d-flex align-items-center justify-content-center"
                      //     disabled
                      //   >
                      //     <i className="ri ri-checkbox-circle-line ri-lg me-2"></i>
                      //     <span>Subscribed</span>
                      //   </button>
                      // ) : (
                      //   plan.pricing !== "0" && (
                      //     <button
                      //       type="button"
                      //       className="btn btn-primary d-grid w-100 mt-auto"
                      //       onClick={() =>
                      //         handleSubscribe(plan.subscription_id, plan.subscription_plan_id, plan.subscription_type)
                      //       }
                      //     >
                      //       Subscribe Now
                      //     </button>
                      //   )
                      // )}