import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import AdminHeader from './AdminHeader';
import AdminSubHeader from './AdminSubHeader';
import Footer from '../../../component/Footer';
import parse from 'html-react-parser'; // Import html-react-parser
import config from '../../../component/config';
import CardLinks from '../../../component/CardLinks';
import { Tooltip } from "primereact/tooltip";
const ViewFaq = () => {
  const { faqId } = useParams();
  const [faq, setFaq] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    const fetchFaqDetails = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Auth token not found' });
        return;
      }

      setLoading(true);

      try {
        const response = await axios.post(
          `${config.apiDomain}/api/admin/view_faq`,
          { faq_id: faqId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        setFaq(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch FAQ details' });
      }
    };

    fetchFaqDetails();
  }, [faqId]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

    const handleRefresh = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Auth token not found' });
        return;
      }

      setLoading(true);

      try {
        const response = await axios.post(
          `${config.apiDomain}/api/admin/view_faq`,
          { faq_id: faqId },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        setFaq(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch FAQ details' });
      }
    };

  
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_faq" className="text-black">
                Manage FAQ
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              FAQ Details
            </li>
          </ol>
        </nav>
        <div className="card p-5">
        <div className="row align-items-center mb-5">
  <div className="col-5 text-start">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
    </button>
  </div>
  <div className="col-5 text-start">
    <h5 className="mb-0">FAQ Details</h5>
  </div>
  <div className="col-2 text-end">
    {loading ? (
      <i className="custom-target-icon ri-loader-2-line ri-lg mt-1 p-text-secondary" />
    ) : (
      <div className="mt-1">
        <Tooltip target=".custom-target-icon" />
        <i
          className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary"
          data-pr-tooltip="Reload"
          onClick={handleRefresh}
          data-pr-position="top"
        />
      </div>
    )}
  </div>
</div>


          {loading ? (
            <div className="text-center">
              <i className="ri-loader-2-line ri-lg"></i> Loading...
            </div>
          ) : (
            faq && (
              <div>
              <p className='text-capitalize'><strong>Category:</strong> {faq.category}</p>
              <p className='text-capitalize'><strong>Question:</strong> {faq.question}</p>
              
              
              <div className='text-capitalize'>
                <strong>Answer:</strong> {parse(faq.answer)}
              </div>
            </div>
            
            )
          )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default ViewFaq;
