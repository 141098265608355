import { Bar } from "react-chartjs-2";
import config from "../../../component/config";
import { Button } from "primereact/button";

import AdminHeader from "./AdminHeader";
import { Toast } from "primereact/toast";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import CardLinks from "../../../component/CardLinks";
import { Tooltip } from "primereact/tooltip";
const ViewTeacher = () => {
  const [backClicked, setBackClicked] = useState(false);
  const toast = useRef(null);
  const navigate = useNavigate();
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const location = useLocation();
  const [data, setData] = useState([]);
  const teacher_id = location.state ? location.state.teacher_id : null;
  const { teacherId } = location.state;
  const [teacherData, setTeacherData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleToggleTrade = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        throw new Error("Auth token not found");
      }
  
      const response = await axios.post(
        `${config.apiDomain}/api/admin/teacher_trade_switch`,
        { teacher_id: teacherId },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
  
      if (response.data.st === 1) {
        // Toggle trade status in teacherData
        setTeacherData((prevData) => ({
          ...prevData,
          trade_status: !prevData.trade_status
        }));
  
        // Display success toast
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Trade status updated successfully!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("Error switching trade status:", error);
  
      if (error.response && error.response.status === 401) {
        console.log("Unauthorized access detected. Redirecting to login...");
        navigate("/commonlogin");
      }
  
      // Display error toast
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.msg || "Failed to update trade status",
        life: 3000,
      });
    }
  };

  const handleToggle = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
  
      if (!authToken) {
        throw new Error("Auth token not found");
      }
  
      const response = await fetch(
        `${config.apiDomain}/api/admin/teacher_active_switch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ teacher_id: teacherId }),
        }
      );
      const responseData = await response.json();
  
      if (response.ok && responseData.st === 1) {
        setTeacherData((prevData) => ({
          ...prevData,
          active_status: !prevData.active_status
        }));
        
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Active status updated successfully!',
          life: 3000,
        });
     
      }
    } catch (error) {
      console.error("Error fetching data:", error);
  
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access, redirecting to common login screen");
        navigate("/commonlogin");
      } else {
        const errorMsg = error.response
          ? error.response.data.msg || "Failed to fetch data"
          : error.message || "Failed to fetch data";
  
        // Display error toast
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const authToken = localStorage.getItem("authToken");
        const userId = localStorage.getItem("userId");

        if (!authToken) {
          throw new Error("Auth token not found");
        }

        const response = await axios.post(
          `${config.apiDomain}/api/admin/manage_teachers/view`,
          { teacher_id: teacherId,admin_id: userId},
          { headers: { Authorization: `Bearer ${authToken}` } }
        );

        if (response.data && response.data.st === 1) {
          setTeacherData(response.data.data);
        } else {
          setError(new Error(response.data.msg || "Failed to fetch data"));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response && error.response.status === 401) {
          console.error("Unauthorized access, redirecting to common login screen");
          navigate("/commonlogin");
        } else {
          const errorMsg = error.response
            ? error.response.data.msg || "Failed to fetch data"
            : error.message || "Failed to fetch data";
          setError(new Error(errorMsg));
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: errorMsg,
            life: 3000,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [teacherId]);

  const toLowerCase = (string) => {
    return string.toLowerCase();
  };
  const formatExchanges = (exchanges) => {
    if (!exchanges) return [];
    const formattedExchanges = [];
    for (const [key, value] of Object.entries(exchanges)) {
      if (Array.isArray(value)) {
        formattedExchanges.push(`${key}: ${value.join(", ")}`);
      } else {
        formattedExchanges.push(`${key}: ${value}`);
      }
    }
    return formattedExchanges;
  };

  const formattedExchanges =
    teacherData && teacherData.broker_account_exchanges
      ? formatExchanges(teacherData.broker_account_exchanges)
      : [];
     
        const handleRefresh = async () => {
          setLoading(true);
          setError(null);
    
          try {
            const authToken = localStorage.getItem("authToken");
    
            if (!authToken) {
              throw new Error("Auth token not found");
            }
    
            const response = await axios.post(
              `${config.apiDomain}/api/admin/manage_teachers/view`,
              { teacher_id: teacherId },
              { headers: { Authorization: `Bearer ${authToken}` } }
            );
    
            if (response.data && response.data.st === 1) {
              setTeacherData(response.data.data);
            } else {
              setError(new Error(response.data.msg || "Failed to fetch data"));
            }
          } catch (error) {
            console.error("Error fetching data:", error);
            if (error.response && error.response.status === 401) {
              console.error("Unauthorized access, redirecting to common login screen");
              navigate("/commonlogin");
            } else {
              const errorMsg = error.response
                ? error.response.data.msg || "Failed to fetch data"
                : error.message || "Failed to fetch data";
              setError(new Error(errorMsg));
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: errorMsg,
                life: 3000,
              });
            }
          } finally {
            setLoading(false);
          }
        };
    
      
  return (
    <>
      <Toast ref={toast} />
      <AdminHeader />
      <AdminSubHeader />

      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i class="ri-home-7-line ri-lg"></i>{" "}
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_teacher" className="text-black">
                Manage Teacher
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              View Teacher
            </li>
          </ol>
        </nav>
        <div className="card p-5">
        <div className="row align-items-center mb-5">
  {/* Back Button */}
  <div className="col-4 d-flex align-items-center">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
    </button>
  </div>
  {/* Center Title */}
  <div className="col-4 text-center">
    <h5 className="mb-0">View Teacher</h5>
  </div>
 
  <div className="col-4 d-flex justify-content-end">
   
    <div className="d-flex flex-row">
     
      {teacherData && teacherData.broker_status && (

       <div className="d-flex flex-column align-items-center mb-2 me-8">
       {/* Trade Status Toggle */}
       <label
         className={`switch switch-square ${teacherData.trade_status ? 'bg-success' : ''}`}
       >
         <input
           type="checkbox"
           className="switch-input"
           checked={teacherData.trade_status}
           onChange={() => handleToggleTrade(teacherData.teacher_id)}
         />
         <span className={`switch-toggle-slider ${teacherData.trade_status ? 'bg-success' : ''}`}>
           <span className="switch-on"></span>
           <span className="switch-off"></span>
         </span>
       </label>
       {/* Trade Status Label */}
       <div className="ms-2 mt-2 d-flex align-items-center">
         <i className="ri-arrow-left-right-fill ri-ms me-1"></i>
         <span className="text-black">Trade Status</span>
       </div>
     </div>
     
      )}
     
      {teacherData && (
        <div className="d-flex flex-column align-items-center">
          <label className={`switch switch-square ${teacherData.active_status ? 'bg-success' : ''}`}>
            <input
              type="checkbox"
              className="switch-input"
              checked={teacherData.active_status}
              onChange={() => handleToggle(teacherData.teacher_id)}
            />
            <span className={`switch-toggle-slider ${teacherData.active_status ? 'bg-success' : ''}`}>
              <span className="switch-on"></span>
              <span className="switch-off"></span>
            </span>
          </label>
          <div className="ms-2 mt-2">
            <i className="ri-user-3-line ri-ms me-1"></i>
            <span className="text-black">Account Status</span>
          </div>
        </div>
      )}
        {loading ? (
                <i className="custom-target-icon ri-loader-2-line ri-lg mt-1 ms-3 p-text-secondary" />
              ) : (
                <div className="mt-1">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                    data-pr-tooltip="Reload"
                    onClick={handleRefresh}
                    data-pr-position="top"

                  />
                </div>
              )} 
    </div>
  </div>
</div>


          {teacherData && (
            <div className="row">
              <div className="col-12">
                <div className="row mt-1">
                  <h4>
                    {" "}
                    <i className="ri-user-line ri-ms me-1 "></i>
                    Personal Information
                  </h4>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{toTitleCase(teacherData.name)}</strong>
                    </span>
                    <div>Name</div>
                  </div>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.mobile}</strong>
                    </span>
                    <div>Mobile</div>
                  </div>
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{toLowerCase(teacherData.email)}</strong>
                    </span>
                    <div>Email</div>
                  </div>
                  <div className="col-3">
                    <div
                      className={` ${
                        teacherData.broker_status
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {teacherData.broker_status ? (
                        <div className="d-flex justify-content-space align-items-center">
                          <i className="ri-shield-check-line me-1"></i>
                          <strong>Connected</strong>
                          {teacherData.broker_status &&
                            teacherData.broker_account_fund != null && (
                              <div className="text-muted ms-2">
                                {teacherData.broker_account_fund} Rs
                              </div>
                            )}
                        </div>
                      ) : (
                        <>
                          <i className="ri-close-large-line me-1"></i>
                          <strong>Not Connected</strong>
                        </>
                      )}
                    </div>

                    <div>Broker Conn. Status</div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 mb-5">
                <div className="row mt-1">
                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.lot_size_limit} Lot</strong>
                    </span>
                    <div>Lot Size Limit</div>
                  </div>

                  <div className="col-3">
                    <span className="text-black">
                      <strong>{teacherData.commission}%</strong>
                    </span>
                    <div>Commission</div>
                  </div>
                  {formattedExchanges.length > 0 && (
             

             <div className="col-3">
               <span className="text-black">
                 <strong>  {formattedExchanges.map((exchange, index) => (
                               <li key={index}>{exchange}</li>
                             ))}</strong>
               </span>
               <div>Segments</div>
             </div>
           
         )}
                </div>
              </div>
             
            
              <hr />
              {teacherData.active_subscription && (
                <>
                  <h4>
                    <i className="ri-medal-2-line ri-ms me-2"></i>
                    Subscription Information
                  </h4>
                  <div className="col-12  mb-5">
                    <div className="row mt-1">
                      <div className="col-3">
                        <span className="text-black text-capitalize">
                          <strong>
                            {teacherData.active_subscription.subscription_name}
                          </strong>
                        </span>
                        <div>Subscription Name</div>
                      </div>
                      <div className="col-3">
                        <span className="text-black text-capitalize">
                          <strong>
                            {teacherData.active_subscription.plan_name}
                          </strong>
                        </span>
                        <div>Plan Name</div>
                      </div>
                      <div className="col-3">
                        <span className="text-black">
                          <strong>
                            {teacherData.active_subscription.completed_days}{" "}
                            Days
                          </strong>
                        </span>
                        <div>Completed Days</div>
                      </div>
                      <div className="col-3">
                        <span className="text-black">
                          <strong>
                            {teacherData.active_subscription.remaining_days}{" "}
                            Days
                          </strong>
                        </span>
                        <div>Remaining Days</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <hr></hr>
              {teacherData.broker_status && (
                <>
                  <h4>
                    <i className="ri-group-line ri-ms me-2"></i>
                    Broker Information
                  </h4>{" "}
                  {teacherData.broker_details && (
                    <div className="col-12  mb-5">
                      <div className="row mt-1">
                        <div className="col-3">
                          <span className="text-black">
                            <strong>
                              {teacherData.broker_details.api_key}
                            </strong>
                          </span>
                          <div>Broker API Key</div>
                        </div>

                        <div className="col-3">
                          <span className="text-black">
                            <strong>
                              {teacherData.broker_details.client_id}
                            </strong>
                          </span>
                          <div>Broker Client ID</div>
                        </div>

                        <div className="col-3">
                          <span className="text-black">
                            <strong>
                              {teacherData.broker_details.password}
                            </strong>
                          </span>
                          <div>Broker Password</div>
                        </div>

                        <div className="col-3 ">
                          <span className="text-black">
                            <strong>
                              {teacherData.broker_details.qr_totp_token}
                            </strong>
                          </span>
                          <div>Broker QR TOTP Token</div>
                        </div>
                      </div>
                    </div>
                  )}
                  <hr></hr>
                </>
              )}
  {teacherData.student_count && (
             
                <div className="row mt-5">
                  <div className="col-4 text-center">
                    <span className="text-black">
                      <h1>
                        <strong>{teacherData.student_count.total_student_count || 0}</strong>
                      </h1>
                    </span>
                    <div>Total Students</div>
                  </div>
                  <div className="col-4 text-center">
                    <span className="text-black">
                      <h1>
                        <strong>{teacherData.student_count.active_student_count || 0}</strong>
                      </h1>
                    </span>
                    <div>Total Active Students</div>
                  </div>
                  <div className="col-4 text-center">
                    <span className="text-black">
                      <h1>
                        <strong>{teacherData.student_count.inactive_student_count || 0}</strong>
                      </h1>
                    </span>
                    <div>Total Inactive Students</div>
                  </div>
                </div>
              
  )}
            </div>
          )}
        </div>
        <CardLinks></CardLinks>
       
      </div>
   

      <Footer />
    </>
  );
};

export default ViewTeacher;
