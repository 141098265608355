import React from 'react'
import LandingHeader from './LandingHeader'
import LandingFooter from './LandingFooter'
import MetaTags from './MetaTags';

const TermsCondition = () => {
  return (
    <div>
      <MetaTags />
      <LandingHeader></LandingHeader>
      <div
        className="container-xxl  mb-5 mt-10  pt-10"
        style={{ backgroundColor: "#ffffff" }}
      >
        <div className="p-3 rounded">
          <div className="mb-3">
            <h3 className="m-0">Terms and Conditions</h3>
            <h5 className="mt-0">Last Updated May / 21 / 2024</h5>
          </div>
          <div>
            <p className="mx-0 mb-1 fs-5">
              Welcome to our website. By accessing and using our site, you agree
              to comply with and be bound by the following terms and conditions.
              You must not use our site for any illegal or unauthorized
              purposeYou must not use our site for any illegal or unauthorized
              purpose.
            </p>

            <p className="mx-0 mb-1 fs-5">
              These terms apply to all visitors, users, and others who access or
              use the service.You must not use our site for any illegal or
              unauthorized purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              You must not use our site for any illegal or unauthorized
              purpose.You must not use our site for any illegal or unauthorized
              purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              You agree to comply with all applicable laws and regulations while
              using our site.You must not use our site for any illegal or
              unauthorized purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              Welcome to our website. By accessing and using our site, you agree
              to comply with and be bound by the following terms and conditions.
              You must not use our site for any illegal or unauthorized purpose.
              You must not use our site for any illegal or unauthorized purpose.
            </p>

            <p className="mx-0 mb-1 fs-5">
              These terms apply to all visitors, users, and others who access or
              use the service.You must not use our site for any illegal or
              unauthorized purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              You must not use our site for any illegal or unauthorized
              purpose.You must not use our site for any illegal or unauthorized
              purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              You agree to comply with all applicable laws and regulations while
              using our site.You must not use our site for any illegal or
              unauthorized purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              Welcome to our website. By accessing and using our site, you agree
              to comply with and be bound by the following terms and conditions.
              You must not use our site for any illegal or unauthorized
              purposeYou must not use our site for any illegal or unauthorized
              purpose.
            </p>

            <p className="mx-0 mb-1 fs-5">
              These terms apply to all visitors, users, and others who access or
              use the service.You must not use our site for any illegal or
              unauthorized purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              You must not use our site for any illegal or unauthorized
              purpose.You must not use our site for any illegal or unauthorized
              purpose.{" "}
            </p>
            <p className="mx-0 mb-1 fs-5">
              You agree to comply with all applicable laws and regulations while
              using our site.You must not use our site for any illegal or
              unauthorized purpose.
            </p>
            <p className="mx-0 mb-1 fs-5">
              Welcome to our website. By accessing and using our site, you agree
              to comply with and be bound by the following terms and conditions.
              You must not use our site for any illegal or unauthorized
              purposeYou must not use our site for any illegal or unauthorized
              purpose.
            </p>
          </div>
        </div>
      </div>
      <LandingFooter></LandingFooter>
    </div>
  );
}

export default TermsCondition
