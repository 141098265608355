import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const StudentHeader = () => {
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.clear();
    // Optionally, redirect to login page or perform other actions after logout
  };
  const [currentTime, setCurrentTime] = useState(new Date());
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept", // Custom abbreviation
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsDate = { weekday: "short", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });
    
    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);

  useEffect(() => {
    // Retrieve the broker connection status from localStorage on component mount
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);
  return (
    <div>
      <nav
        className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
        id="layout-navbar"
      >
        <div className="container-xxl d-flex justify-content-between align-items-center">
          {/* Left Section: Logo and TradeMirror */}
          <div className="d-flex align-items-center">
            <div className="avatar me-2">
              <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="autoprofito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span
              className="app-brand-text demo menu-text fw-semibold"
              
            >
                AutoProfito
            </span>
          </div>

          {/* Center Section: Current Time */}
          <div className="d-none d-xl-block">
            <span className="text-secondary">
              {formattedDate}
            </span>
            <br></br>

            <strong className="fs-4">{formattedTime}</strong>
          </div>
          
          <div className="d-flex align-items-center">
          <div className="text-end me-3">
              <h6 className=" fs-5 mb-0 bold text-uppercase">
                {toTitleCase(userName)}
              </h6>
              <div className="position-relative d-inline-block my-2">
                <Link to="/teacher/user_profile">
                  <span className="badge rounded-pill bg-label-dark ">
                    {toTitleCase(userRole)}
                  </span>
                </Link>
              </div>
            </div>

            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  href="javascript:void(0);"
                  data-bs-toggle="dropdown"
                >
                  <div className="w-px-40 h-auto rounded-circle position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-info border-3  rounded-circle bg-label-info avatar-lg ">
                      <span className="avatar-initial rounded-circle bg-label-info fs-2">
                        {" "}
                        {userName && userName.charAt(0).toUpperCase()}

                      </span>
 
                    </div>
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end mt-3 py-2">
                  {/* <li>
                    <a className="dropdown-item" href="pages-account-settings-account.html">
                      <div className="d-flex align-items-center">
                        <div className="avatar me-2">
                          <img src={mirrorLogo} alt="Profile" className="w-px-40 h-auto rounded-circle" />
                        </div>
                        <div className="flex-grow-1">
                          <h6 className="mb-0 small">{toTitleCase(userName)}</h6>
                          <small className="text-muted">{toTitleCase(userRole)}</small>
                          
                        </div>
                      </div>
                    </a>
                  </li>
                  <li>
                    <div className="dropdown-divider"></div>
                  </li> */}
                  <li>
                    <Link to="/app2/student_profile" className="dropdown-item">
                      <i className="ri-user-line ri-22px me-2"></i>
                      <span className="align-middle">Profile</span>
                    </Link>
                  </li><li>
                    <Link to="/app2/student_my_report" className="dropdown-item">
                      <i className="ri-user-line ri-22px me-2"></i>
                      <span className="align-middle">My Report</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/student/feature_request" className="dropdown-item">
                      <i className="ri-sparkling-line ri-22px me-2"></i>
                      <span className="align-middle">Feature Request</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/student/chat" className="dropdown-item">
                      <i className="ri-sparkling-line ri-22px me-2"></i>
                      <span className="align-middle"> Chat</span>
                    </Link>
                  </li>
                
                  
                  <li>
                      <Link
                        to="/commonlogin"
                        className="dropdown-item "
                        onClick={handleLogout}
                      >
                          <i className="ri-logout-circle-r-line ri-lg me-1"></i>   <span className="align-middle me-2">Logout</span>
                   
                      </Link>
                    </li>
                  
                </ul>
              </li>
            </ul>
          </div>

          <div className="navbar-search-wrapper search-input-wrapper container-xxl d-none">
            <input
              type="text"
              className="form-control search-input border-0"
              placeholder="Search..."
              aria-label="Search..."
            />
            <i className="ri-close-fill search-toggler cursor-pointer"></i>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default StudentHeader;
