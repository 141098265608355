import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { Toast } from 'primereact/toast';
import AdminHeader from './AdminHeader';
import AdminSubHeader from './AdminSubHeader';
import Footer from '../../../component/Footer';
import config from '../../../component/config';
import CardLinks from '../../../component/CardLinks';
import { Tooltip } from "primereact/tooltip";
const ViewSubscription = () => {
  const { subscription_id } = useParams(); // Get subscription_id from URL
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useRef(null);
  const navigate = useNavigate();
  const [backClicked, setBackClicked] = useState(false);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.post(`${config.apiDomain}/api/admin/subscription_copy_trading/view`, {
          subscription_id: subscription_id
        }, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data.st === 1) {
          setSubscriptionPlan(response.data.subscription_plan_data);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response.data.msg
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/commonlogin');
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to fetch subscription data'
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [subscription_id, navigate]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };
 
    const handleRefresh = async () => {
      setLoading(true);
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.post(`${config.apiDomain}/api/admin/subscription_copy_trading/view`, {
          subscription_id: subscription_id
        }, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });

        if (response.data.st === 1) {
          setSubscriptionPlan(response.data.subscription_plan_data);
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response.data.msg
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/commonlogin');
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to fetch subscription data'
          });
        }
      } finally {
        setLoading(false);
      }
    };

  
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/manage_subscription" className="text-black">
                Manage Subscription
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              View Subscription
            </li>
          </ol>
        </nav>
        <div className="card p-5">
        <div className="row align-items-center mb-5">
  <div className="col-4 text-start">
    <button
      onClick={handleBack}
      className="btn rounded-pill btn-outline-secondary btn-xs"
    >
      <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
    </button>
  </div>
  <div className="col-6 text-start">
    <h5 className="mb-0">View Subscription</h5>
  </div>
  <div className="col-2 text-end">
    {loading ? (
      <i className="custom-target-icon ri-loader-2-line ri-lg mt-1 p-text-secondary" />
    ) : (
      <div className="mt-1">
        <Tooltip target=".custom-target-icon" />
        <i
          className="custom-target-icon ri ri-refresh-line ri-lg p-text-secondary"
          data-pr-tooltip="Reload"
          onClick={handleRefresh}
          data-pr-position="top"
        />
      </div>
    )}
  </div>
</div>

          {loading ? (
            <div className="text-center">
              <i className="ri-loader-2-line ri-lg"></i> Loading...
            </div>
          ) : (
            subscriptionPlan && (
              <div>
                <div className="col-12 mt-5 mb-5">
                  <div className='row'>
                    <div className="col-3">
                      <span className="text-black">
                        <strong className='text-capitalize'>{subscriptionPlan.subsciption_name}</strong>
                      </span>
                      <div>Subscription Name</div>
                    </div>
                    <div className='row mt-4'>
                    <div className="col-12">
                      <span className="">
                        <div className=''>Subscription Description</div>
                        <strong className='text-capitalize text-black'>{subscriptionPlan.subsciption_description}</strong>
                      </span>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                {subscriptionPlan.subscriptions.map((sub, index) => (
                  <div key={index} className="col-12">
                    <div className="mb-3 row">
                      <div className="col-3">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.plan_name}</strong>
                        </span>
                        <div>Plan Name</div>
                      </div>

                     

                      <div className="col-3">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.pricing} Rs.</strong>
                        </span>
                        <div>Pricing</div>
                      </div>
                      <div className="col-3">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.service_period} day</strong>
                        </span>
                        <div>Service Period</div>
                      </div>

                      <div className="col-3">
                        <span className="text-black">
                          <strong className='text-capitalize'>{sub.max_user_limit} user</strong>
                        </span>
                        <div>Max User Limit</div>
                      </div>
                    </div>
                    <div className="mt-4 row">
                    <div className="col-12">
                        <span className="">
                          <div>Description</div>
                          <strong className='text-capitalize text-black'>{sub.plan_description}</strong>
                        </span>
                      </div>
                      </div>
                    <hr className='mt-3' />
                  </div>
                ))}
              </div>
            )
          )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
    </div>
  );
};

export default ViewSubscription;
