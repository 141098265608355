import React from "react";

import edit from "../../../assets/img/front-pages/icons/transition-up.png";

import edit2 from "../../../assets/img/front-pages/icons/laptop-charging.png"
import MetaTags from "./MetaTags";

const ServicesOffered = () => {
  return (
    <div>
      <MetaTags />
      <div data-bs-spy="scroll" className="scrollspy-example">
        <section id="landingTeam" className="py-5 landing-team">
          <div className="container-xxl bg-icon-left position-relative">
            <div className="text-center">
              <div className="fs-4 text-dark mb-1 poppins-medium">
                Services we offer
              </div>
              <p className="mb-8">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>

            <div className="row gy-4 pt-lg-4 mt-6">
              {/* Card Wrapper */}
              {[
                {
                  id: 1,
                  title: "Trade Automation",
                  description:
                    "Automate trade from Master account to other child accounts with cross broker operation.",
                  icon: edit,
                },
                {
                  id: 2,
                  title: "Dynamic Multiplier",
                  description:
                    "Place trades in multiple custom quantities in child accounts.",
                  icon: edit,
                },
                {
                  id: 3,
                  title: "Automated Login",
                  description:
                    "Add your broker account and enjoy the automated login on every trading day.",
                  icon: edit2,
                },
                {
                  id: 4,
                  title: "Monitoring and Management",
                  description:
                    "Monitor child accounts from one single dashboard / Emergency exit feature.",
                  icon: edit2,
                },
                {
                  id: 5,
                  title: "Customised Plans",
                  description:
                    "Get your own custom plan based on your required number of accounts.",
                  icon: edit2,
                },
                {
                  id: 6,
                  title: "Favourite Plan",
                  description:
                    "Automate trading in multiple accounts with no software installation and VPS requirement.",
                  icon: edit2,
                },
              ].map((service) => (
                <div
                  key={service.id}
                  className="col-xl-4 col-lg-6 col-md-6 d-flex"
                >
                  <div className="card border-white border-2 shadow-md h-100 d-flex flex-column">
                    <div className="card-header border-0">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="features-icon mb-0 mt-5">
                          <img
                            src={service.icon}
                            className="image-rounded"
                            alt={service.title}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-body flex-grow-1">
                      <div className="d-flex mt-0 justify-content-center align-items-center flex-wrap">
                        <h4 className="mb-3 text-center">{service.title}</h4>
                        <p className="mb-3 text-center">
                          {service.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ServicesOffered;
