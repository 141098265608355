import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import CardLinks from "../../../component/CardLinks";

const ManageFaq = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const toast = useRef(null);

  useEffect(() => {
    const fetchFaqs = async () => {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        setError(new Error("Auth token not found"));
        setLoading(false);
        return;
      }
      setLoading(true);

      try {
        const response = await axios.get(
          "https://ghanish.in/api/admin/list_view_faq",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setFaqs(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch FAQs",
        });
        console.error("There was an error fetching the FAQs!", error);
      }
    };

    fetchFaqs();
  }, []);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleView = (faqId) => {
    navigate(`/admin/manage_faq/view_faq/${faqId}`);
  };
  const handleEdit = (faqId) => {
    console.log("Editing FAQ with ID:", faqId); // Debugging statement
    if (faqId) {
      navigate(`/admin/manage_faq/update_faq/${faqId}`);
    } else {
      console.error("FAQ ID is undefined");
    }
  };
  
  const handleDelete = async (faqId) => {
    // Show confirmation dialog
    confirmDialog({
      message: "Are you sure you want to delete this FAQ?",
      header: "Confirm",
      icon: "pi pi-info-circle",
      acceptLabel: "Yes",
      rejectLabel: "No",
      accept: async () => {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          setError(new Error("Auth token not found"));
          return;
        }

        try {
          console.log("Deleting FAQ with ID:", faqId); // Debugging statement
          await axios.delete("https://ghanish.in/api/admin/delete_faq", {
            data: { faq_id: faqId },
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });

          // Remove deleted FAQ from the state
          setFaqs(faqs.filter((faq) => faq.id !== faqId));
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "FAQ deleted successfully",
          });
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to delete FAQ",
          });
          console.error("There was an error deleting the FAQ!", error);
        }
      },
      reject: () => {
        toast.current.show({
          severity: "info",
          summary: "Cancelled",
          detail: "Delete action was cancelled",
        });
      },
    });
  };
  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const categoryBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.category)}</span>;
  };
  const questionBodyTemplate = (rowData) => {
    return <span>{toTitleCase(rowData.question)}</span>;
  };
  const handleRefresh = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
      setError(new Error("Auth token not found"));
      setLoading(false);
      return;
    }
    setLoading(true);

    try {
      const response = await axios.get(
        "https://ghanish.in/api/admin/list_view_faq",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFaqs(response.data.data);
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: response.data.message,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch FAQs",
      });
      console.error("There was an error fetching the FAQs!", error);
    }
  };

  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Manage FAQ
            </li>
          </ol>
        </nav>
        <div className="card p-5">
        <div className="row align-items-center mb-5">
        <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-4 text-center">
              <h5 className="mb-0">Manage FAQ</h5>
            </div>
            <div className="col-4"></div>

          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
  <div className="d-flex align-items-center">
    <IconField iconPosition="left">
      <InputIcon className="ri ri-search-line"></InputIcon>
      <InputText
        type="search"
        placeholder="Search"
        value={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="rounded"
      />
    </IconField>
    {loading ? (
      <i className="custom-target-icon ri-loader-2-line ri-lg ms-3"></i>
    ) : (
      <div className="ms-3">
        <Tooltip target=".custom-target-icon" />
        <i
          data-pr-tooltip="Reload"
          data-pr-position="top"
          className="ri ri-refresh-line ri-lg"
          onClick={handleRefresh}
        />
      </div>
    )}
  </div>
  <div className="ms-auto">
    <Link to="/admin/manage_faq/create_faq">
      <button className="btn btn-success">
        <i className="ri-add-circle-line ri-lg me-1"></i>
        <span>Create FAQ</span>
      </button>
    </Link>
  </div>
</div>

          <DataTable
            value={faqs}
            rows={20}
            paginator
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="p-datatable-rounded border border-1 text-center  "
          >
            <Column
  field="category"
  
  body={categoryBodyTemplate}
  header="Category"
   className=" border border-1"
></Column>

            
            <Column
              field="question"
              style={{
                wordBreak: "break-word",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "400px",
              }}
              align={"left"}
              className=" border border-1"
              body={questionBodyTemplate}
              header="Question "

            ></Column>
            <Column
              align={"center"}
              className=" border border-1"
              header="Actions"
              body={(rowData) => (
                <>
                  <button
                    className="btn btn-info me-3 custom-btn-action1"
                    onClick={() => handleView(rowData.faq_id)}
                  >
                    <i className="ri-gallery-view-2 ri-lg"></i>
                  </button>
                  <button
                    className="btn btn-warning me-3 custom-btn-action1"
                    onClick={() => handleEdit(rowData.faq_id)}
                  >
                    <i className="ri-edit-2-line ri-lg"></i>
                  </button>
                  <button
                    className="btn btn-danger active text-align custom-btn-action1"
                    onClick={() => handleDelete(rowData.faq_id)}
                  >
                    <i className="ri-close-large-line ri-lg"></i>
                  </button>
                </>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog /> 
    </div>
  );
};

export default ManageFaq;
