import React from "react";
import { Link } from "react-router-dom";
import footerbg from "../../../assets/img/front-pages/backgrounds/footer-bg.png";

import daisy from "../../../assets/img/illustrations/illustration-john-2.png";

import { useEffect, useState } from "react";
import axios from "axios";

import FaqSection from "./FaqSection";
import MetaTags from "./MetaTags";

const LandingFooter = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(
          "https://ghanish.in/api/website/view_all_category_faqs"
        );
        if (response.data.st === 1) {
          setFaqs(response.data.data.General);
        }
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    };

    fetchFaqs();
  }, []);
  return (
    <>
      <MetaTags />
      {/* padding 40px  */}
      <section
        id="landingReviews"
        className=" bg-body landing-reviews pt-10 mt-5"
      >
        <div className="container-xxl"></div>
      </section>
      <div data-bs-spy="scroll" className="scrollspy-example mt-10">
        {/* new card daisy */}
        <div className="pt-5">
          <div className="container-xxl">
            <div className="row">
              <div className="col-xl-12 col-lg-12 align-self-center mt-md-7 mt-lg-4 pt-md-2 pt-lg-0">
                <div className="card">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="card-body ">
                        <h4 className="card-title mb-2 text-truncate ">
                          Start your free account today
                          <span className="fw-bold"></span>
                        </h4>
                        <p className="mb-5">
                          Explore the best trading platform in India, supporting
                          major Indian brokers like Zerodha, Angle Broking, Dhan
                          & more across NSE , BSE, and MCX exchanges.
                        </p>
                        <div
                          className="d-flex flex-column align-items-left mt-1"
                          style={{ width: "fit-content" }}
                        >
                          <a
                            to="*"
                            className="btn btn-sm btn-info rounded-pill waves-effect waves-light text-white"
                          >
                            Start your free account now
                          </a>
                          <p className="mt-2 text-center mb-0">
                            No hidden charges
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 position-relative text-center d-none d-md-block ">
                      <img
                        src={daisy}
                        alt="View Profile"
                        style={{
                          width: "auto",
                          height: "260px",
                          position: "absolute",
                          bottom: "0",
                          right: "0",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* new card daisy */}

        {/* padding 40px  */}
        <section
          id="landingReviews"
          className=" bg-body landing-reviews pt-10 mt-5"
        >
          <div className="container-xxl"></div>
        </section>

        <FaqSection />
        {/* padding 40px  */}
        <section
          id="landingReviews"
          className=" bg-body landing-reviews pt-10 mt-5"
        >
          <div className="container-xxl"></div>
        </section>

        <div className="container-xxl bg-body">
          <section
            id="landingFunFacts"
            className="section-py landing-fun-facts py-12 rounded-5"
            style={{ backgroundColor: "rgb(172 218 255)" }}
          >
            <div className="container-xxl d-flex flex-column align-items-center justify-content-center text-center mx-auto ">
              <div className="text-center">
                <div className="fs-4 text-dark mb-1 poppins-medium">
                  Still have questions?
                </div>
                <p className="mb-8">
                  Please describe your case to receive the most accurate advice.
                </p>
              </div>
              <Link
                to="/contact"
                className="btn btn-info text-center rounded-pill"
              >
                Contact us
              </Link>
            </div>
          </section>
        </div>
        {/* padding 40px  */}
        <section
          id="landingReviews"
          className=" bg-body landing-reviews pt-10 mt-5"
        >
          <div className="container-xxl"></div>
        </section>

        <div className="landing-footer">
          <div className="footer-top position-relative overflow-hidden">
            <img
              src={footerbg}
              alt="footer bg"
              className="footer-bg banner-bg-img"
            />
            <div className="container-xxl position-relative">
              <div className="row gx-0 gy-7 gx-sm-6 gx-lg-12">
                <div className="col-lg-4">
                  <div className="app-brand-link mb-6">
                    <div className="">
                      <img
                        src={process.env.PUBLIC_URL + "/favicon.png"}
                        alt="Favicon"
                        width="44px"
                      />
                    </div>
                    <span className="app-brand-text demo footer-link fw-semibold ms-1 px-3">
                      AutoProfito
                    </span>
                  </div>
                  <div className="footer-text px-0 footer-logo-description">
                    <p>
                      {" "}
                      AutoProfito (Product of Shekru Labs India Pvt. Ltd.) is a
                      technology provider and not a wealth manager, investment
                      advisor, or portfolio management service. All transactions
                      through AutoProfito are the user's responsibility, and we
                      cannot be held liable for any user actions.
                    </p>
                    <p>
                      We strive for transparency, but the responsibility for any
                      selection or transaction rests with the user.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-between">
                  {/* <div className="row"> */}
                  <div className="col-lg-2 col-md-6 col-6 d-inline">
                    <h6 className="footer-title mb-4 mb-lg-6 fs-4">Pages</h6>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-4">
                        <Link to="/" className="footer-link">
                          Home
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link to="/login" className="footer-link">
                          Login
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link to="/landing_signup" className="footer-link">
                          Signup
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link to="/help_center" className="footer-link">
                          FAQ
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link to="/pricing" className="footer-link">
                          Pricing
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md- col-6 offset-lg-1 d-inline">
                    <h6 className="footer-title mb-4 mb-lg-6 fs-4 ">Legal</h6>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-4">
                        <Link to="/privacy_policy" className="footer-link">
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link to="/cookies_policy" className="footer-link">
                          Cookies Policy
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link to="/cancellation_policy" className="footer-link">
                          Cancellation Policy
                        </Link>
                      </li>
                      <li className="mb-4">
                        <Link
                          to="/refund_policy"
                          className="footer-link refundpolicy"
                        >
                          Refund Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/terms_condition" className="footer-link">
                          Terms and Condition
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* </div> */}
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                  <div className="text-center d-none d-md-none">
                    <a
                      href="https://www.facebook.com/autoprofito"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-facebook-circle-fill ri-3x"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-instagram-line ri-3x"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-youtube-line ri-3x"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-youtube-line ri-3x"></i>
                    </a>
                  </div>

                  {/* footer upside  */}
                  <div className="container-xxl">
                    {/* <div className="row">
                      <div className="col-lg-1 col-md-1 col-1">
                        <p className="footer-text px-0 footer-logo-description">
                          <i className="ri-map-pin-line ri-lg text-white"></i>
                        </p>
                      </div>
                      <div className="col-lg-11 col-md-8 col-8">
                        <p className="footer-text px-0 footer-logo-description">
                          Shekru Labs India, 2nd Floor, Manogat Appt Muktangan
                          English School & Jr College, Sahakar Nagar, Pune,
                          411009
                        </p>
                      </div>
                    </div> */}


                    {/* ---------------- */}

                    <div className="row">
                      <div className="col-lg-1 col-md-8 col-1">
                        <p className="footer-text px-0 footer-logo-description">
                          <i className="ri-map-pin-line ri-lg text-white"></i>
                        </p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-8">
                        <p className="footer-text px-0 footer-logo-description">
                          Shekru Labs India Pvt. Ltd., 2nd Floor, Manogat Appt
                          Muktangan English School & Jr College, Sahakar Nagar,
                          Pune, 411009
                        </p>
                      </div>
                    </div>{" "}
                    <div className="row">
                      <div className="col-lg-1 col-md- col-1">
                        <p className="footer-text px-0 footer-logo-description">
                          <i className="ri-mail-line ri-lg text-white"></i>
                        </p>
                      </div>
                      <div className="col-lg-8 col-md-8 col-8">
                        <p className="footer-text px-0 footer-logo-description">
                          shekrulabs@gmail.com
                        </p>
                      </div>
                    </div>{" "}
                  </div>

                  <div className="text-center d-none d-md-block py-5">
                    <a
                      href="https://www.facebook.com/autoprofito"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-facebook-circle-fill ri-3x fs-2"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-instagram-line ri-3x fs-2"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-youtube-line ri-3x fs-2"></i>
                    </a>
                    <a
                      href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                      className="footer-link me-4"
                      target="_blank"
                    >
                      <i className="ri-youtube-line ri-3x fs-2"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom bg-body">
            <div className="container-xxl d-flex flex-wrap justify-content-center flex-md-row flex-column text-center text-md-center">
              <div className="text-center mt-2">
                <p className="text-black mb-1 fs-8">
                  Shekru Labs India Pvt. Ltd., 2nd Floor, Manogat Appt.,
                  Muktangan English School & Jr College, Sahakar Nagar, Pune,
                  411009
                </p>
                <p className=" text-black mb-1 fs-8 d-inline-block">
                  Email: shekrulabs@gmail.com
                </p>
                <p className="text-black fs-8 d-inline-block mx-3">|</p>
                <p className="text-black fs-8 d-inline-block">
                  CIN: U74999PN2018PTC174371
                </p>
              </div>
            </div>
          </div>

          <div className="footer-bottom py-5">
            <div className="container-xxl d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">
              <div className="mb-2 mb-md-0">
                <span className="footer-text">
                  <p> &copy; Copyright All Rights Reserved.</p>
                </span>
              </div>
              <div>
                <a
                  href="https://www.facebook.com/autoprofito"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-facebook-circle-fill ri-xl"></i>
                </a>
                <a
                  href="https://www.instagram.com/"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-instagram-line ri-xl"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A "
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-youtube-line ri-xl"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A "
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-youtube-line ri-xl s"></i>
                </a>
                {/* <a
                  href="https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A"
                  className="footer-link me-4"
                  target="_blank"
                >
                  <i className="ri-youtube-line ri-xl"></i>
                </a> */}
              </div>

              <div className="mb-2 mb-md-0">
                <span className="footer-text">
                  <p>
                    Powered by{" "}
                    <a href="https://www.shekruweb.com" target="_blank">
                      Shekru Labs India Pvt. Ltd.
                    </a>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingFooter;
