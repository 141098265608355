


import React, { useState, useEffect, useRef } from "react";
import { Link,useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Button } from 'primereact/button';
const AdminHeader = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleLogout = () => {
    confirmDialog({
      message: 'Are you sure you want to logout?',
      header: 'Logout Confirmation',
      icon: 'ri ri-feedback-line',
     
      accept: () => {
        localStorage.removeItem("authToken");
        localStorage.clear();
        navigate("/commonlogin"); // Navigate to the commonlogin page after logout
      },
      reject: () => {
      
      }
    });
  };
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");
  const userRole = localStorage.getItem("userRole");
  const [brokerConnectionStatus, setBrokerConnectionStatus] = useState(null);

  const toTitleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  useEffect(() => {
    const status = JSON.parse(localStorage.getItem("brokerConnectionStatus"));
    setBrokerConnectionStatus(status);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const closeDropdown = () => setDropdownOpen(false);

  const monthAbbreviations = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept", // Custom abbreviation
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const formatTime = (date) => {
    const optionsDate = { weekday: "short", day: "numeric" };
    const optionsTime = { hour: "2-digit", minute: "2-digit", hour12: true };

    const day = date.toLocaleDateString("en-US", { day: "numeric" });
    const month = monthAbbreviations[date.getMonth()];
    const weekday = date.toLocaleDateString("en-US", { weekday: "short" });
    
    const formattedDate = `${weekday}, ${day} ${month}`;
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);

    return { formattedDate, formattedTime };
  };

  const { formattedDate, formattedTime } = formatTime(currentTime);


  return (
    <div>
        <ConfirmDialog/>
      <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
        <div className="container-xxl d-flex justify-content-between align-items-center">
          {/* Left Section: Logo */}
          <div className="d-flex align-items-center">
            <div className="avatar me-2">
              <img
                src={`${process.env.PUBLIC_URL}/favicon.png`}
                alt="AutoProfito Logo"
                className="w-px-40 h-auto rounded-circle"
              />
            </div>
            <span className="app-brand-text demo menu-text fw-semibold">
              AutoProfito
            </span>
          </div>

          {/* Center Section: Current Time */}
          <div className="d-none d-xl-block">
            <span className="text-secondary">
            {formattedDate}
            </span>
            <br />
            <strong className="fs-4">  {formattedTime}</strong>
          </div>

          {/* Right Section: User Info and Dropdown */}
          <div className="d-flex align-items-center">
            <div className="text-end me-3">
              <h6 className="mb-1 fs-5 semi-bold text-uppercase">
                <strong>{toTitleCase(userName)}</strong>
              </h6>
              <div className="position-relative d-inline-block">
               
              <Link to="/admin/user_profile">
                  <span className="badge rounded-pill bg-label-dark ">
                    {toTitleCase(userRole)}
                  </span>
                </Link>
              </div>
            </div>
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item navbar-dropdown dropdown-user dropdown" ref={dropdownRef}>
                <a
                  className="nav-link dropdown-toggle hide-arrow p-0"
                  href="#!"
                  onClick={toggleDropdown}
                >
                  <div className="w-px-40 h-auto rounded-circle position-relative">
                    <div className="d-flex justify-content-center align-items-center border border-success border-3 rounded-circle bg-label-success avatar-lg">
                      <span className="avatar-initial rounded-circle bg-label-success fs-2">
                        {userName && userName.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </a>
                
                {dropdownOpen && (
                  <ul className="dropdown-menu dropdown-menu-end mt-3 py-2 show" style={{ right: '0', left: 'auto' }}>
                    <li>
                      <Link to="/admin/profile" className="dropdown-item" onClick={toggleDropdown}>
                        <i className="ri-user-line ri-22px me-2"></i>
                        <span className="align-middle">Profile</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/my_report" className="dropdown-item" onClick={toggleDropdown}>
                        <i className="ri-bar-chart-box-line ri-22px me-2"></i>
                        <span className="align-middle">My Reports</span>
                      </Link>
                    </li>
                    <li>
                    <div className="dropdown-item" onClick={() => { handleLogout(); closeDropdown(); }}>
                        <i className="ri-logout-circle-r-line ri-lg me-1"></i>
                        <span className="align-middle me-2">Logout</span>
                      </div>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminHeader;
