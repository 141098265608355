import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="mt-12">
      <footer className="content-footer footer bg-footer-theme ">
        <div className="container-xxl mt-12">
          <div className="text-body mb-2 mb-md-0 d-flex justify-content-between align-items-center footer-container py-4 flex-md-row ">
            <div>&copy; Copyright. All Rights Reserved.</div>
            <div>
              <a
                href="https://www.facebook.com/autoprofito "
                className="footer-link me-4"
                target="_blank"
              >
                <i className="ri-facebook-circle-fill ri-xl"></i>
              </a>
              <a
                href="https://www.instagram.com/"
                className="footer-link me-4"
                target="_blank"
              >
                <i className="ri-instagram-line ri-xl"></i>
              </a>
              <a
                href=" https://www.youtube.com/channel/UCgfTIIUL16SyHAQzQNmM52A "
                className="footer-link me-4"
                target="_blank"
              >
                <i className="ri-youtube-line ri-xl"></i>
              </a>
            </div>
            <div>
              Powered by{" "}
              <a href="https://www.shekruweb.com/" target="_blank">
                Shekru Labs India Pvt. Ltd.
              </a>
            </div>
          </div>
          <div className="d-none d-lg-inline-block">
            {/* <Link to="/landing_home_page">Website</Link> */}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;

