import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import SubHeader from "../component/SubHeader";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../component/config";
import Footer from "../../../component/Footer";
import CardLinks from "../../../component/CardLinks";
const Billing = () => {
  const [backClicked, setBackClicked] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const authToken = localStorage.getItem("authToken");
  const [filter, setFilter] = useState("all");

  const handleFilterChange = (filterType) => {
    setFilter(filterType);
  };

  const filteredSubscriptions = subscriptionData.filter(
    (subscription) =>
      filter === "all" || (filter === "active" && subscription.active)
  );
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await axios.post(
          `${config.apiDomain}/api/teacher/view_all_subscription`,
          {
            teacher_id: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.data.st === 1) {
          setSubscriptionData(response.data.all_subscription_list || []);
        } else {
        
        }
      } catch (error) {
      }
    };

    fetchSubscriptionData();
  }, [userId, authToken]);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleViewDetails = (subscriptionId,subscriptionType) => {
    navigate(`/teacher/subscription_plan/${subscriptionId}/${subscriptionType}`);
  };

  return (
    <div>
      <Header />
      <SubHeader />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/teacher/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>

            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Subscriptions
            </li>
          </ol>
        </nav>

        <div className="card p-6">
          <div className="row align-items-center">
            <div className="col-auto text-start mb-5">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col text-center mb-5">
              <h5 className="mb-0">Subscriptions</h5>
            </div>
            <div className="col-auto text-end mb-5">
              <Link to="/teacher/payment_history">
                <button className="btn rounded-pill btn-outline-secondary btn-xs">
                  <span className="text-black">
                    <i className="ri-money-rupee-circle-line me-1 ri-lg"></i>{" "}
                    <span>Payment History</span>
                  </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-12  text-center">
            Listed here are all your active subscriptions
          </div>
          <div>
            <div className="col-md-12   text-center">
              <div className="mt-4">
                <div className="btn-group" role="group" aria-label="Basic example">
                  <button
                    type="button"
                    onClick={() => handleFilterChange("all")}
                    className={`btn ${filter === "all"
                        ? " btn-outline-success"
                        : " btn-outline-secondary"
                      }`}
                  >
                    All
                  </button>

                  <button
                    type="button"
                    onClick={() => handleFilterChange("active")}
                    className={`btn ${filter === "active"
                        ? " btn-outline-info"
                        : " btn-outline-secondary"
                      }`}
                  >
                    Active
                  </button>
                </div>
              </div>
            </div>
          </div>
          {filteredSubscriptions && subscriptionData.length > 0 ? (
            <div className="row">
              {filteredSubscriptions.map((subscription) => (
                <div
                  className="col-6 mt-4 p-5"
                  key={subscription.subscription_id}
                
                    onClick={() => handleViewDetails(subscription.subscription_id, subscription.subscription_type)}
                
                >
                  <div
                    className="card mb-6 border border-2 card-hover1  rounded"
                    role="button"
                  >
                    <div className="card-body">
                      <div className="column">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h4 className="text-start mb-0 text-uppercase">
                            {subscription.subscription_name}
                            {subscription.active && (
                              <i className="ri-verified-badge-line text-info ms-2"></i>
                            )}
                          </h4>
                          <div className="d-flex justify-content-end">
                            {subscription.plans.map((plan, index) => {
                              const planName = Object.keys(plan)[0];
                              const isActive = plan[planName];

                              return (
                                <span
                                  key={index}
                                  className={`badge ${isActive ? "bg-label-info" : "bg-label-dark"
                                    } me-2`}
                                >
                                  {isActive && (
                                    <i className="ri-checkbox-circle-line text-info ri-lg me-1"></i>
                                  )}
                                  {planName.charAt(0).toUpperCase() +
                                    planName.slice(1)}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <small>{subscription.description}</small>
                        </div>

                        <div>
                          <div className="column mt-1">
                            <div className="row">
                              {subscription.charges && (
                                <>
                                  <hr className="text-black" />

                                  <div className="col-5">
                                    <span className="fs-5 d-block">
                                      <strong>
                                        RS. {subscription.charges}
                                      </strong>
                                    </span>
                                    <span className="d-block">
                                      <small>
                                        Current Subscription charges
                                      </small>
                                    </span>
                                  </div>
                                </>
                              )}
                              {subscription.expiry_date && (
                                <div className="col-7 text-end">
                                  <span className="fs-5 d-block">
                                    <strong>{subscription.expiry_date}</strong>
                                  </span>
                                  <span className="d-block">
                                    <small>Current plan expires on</small>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          {(subscription.remaining_days !== null ||
                            subscription.completed_days !== null) && (
                              <div
                                className="progress mb-1 rounded mt-3"
                                style={{ height: "18px" }}
                              >
                                {subscription.completed_days > 0 && (
                                  <div
                                    className="progress-bar bg-success rounded-start"
                                    role="progressbar"
                                    style={{
                                      width: `${(subscription.completed_days /
                                          subscription.service_period) *
                                        100
                                        }%`,
                                      height: "18px",
                                    }}
                                    aria-valuenow={subscription.completed_days}
                                    aria-valuemin="0"
                                    aria-valuemax={subscription.service_period}
                                  >
                                    {subscription.completed_days} days Completed
                                  </div>
                                )}
                                {subscription.remaining_days > 0 && (
                                  <div
                                    className="progress-bar bg-primary rounded-end"
                                    role="progressbar"
                                    style={{
                                      width: `${(subscription.remaining_days /
                                          subscription.service_period) *
                                        100
                                        }%`,
                                      height: "18px",
                                    }}
                                    aria-valuenow={subscription.remaining_days}
                                    aria-valuemin="0"
                                    aria-valuemax={subscription.service_period}
                                  >
                                    {subscription.remaining_days} days Remaining
                                  </div>
                                )}
                                {subscription.completed_days === 0 &&
                                  subscription.remaining_days === 0 && (
                                    <div
                                      className="progress-bar bg-secondary"
                                      role="progressbar"
                                      style={{ width: "100%", height: "18px" }}
                                    >
                                      No Data
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center mt-5">
              <p className=" mt-6 fs-3">
                No Active Subscription.
                <i className="ri-emotion-unhappy-line"></i>
              </p>
              <p>
                <Link to="">
                  View all subscriptions & offers here{" "}
                  <i className="ri-external-link-line"></i>
                </Link>
              </p>
            </div>
          )}
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Billing;
