import React, { useEffect, useState,useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Footer from "../../../component/Footer"
import { Link, useNavigate ,useParams} from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import config from "../../../component/config";
import AdminHeader from "./AdminHeader";
import AdminSubHeader from "./AdminSubHeader";
import CardLinks from "../../../component/CardLinks";
const AdminOrderBookDetails = () => {
  const {  uniqueorderid } = useParams();
  const [data, setData] = useState([]);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const userId = localStorage.getItem("userId");

  const fetchOrderDetails = async () => {
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId) {
      setError(new Error("User ID not found"));
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_details`,
        {
          user_id: userId,
          uniqueorderid: uniqueorderid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data.st === 1 && response.data.data) {
        const orderDetails = response.data.data;
        setData([orderDetails]); // Set the order details data
      } else {
        setError(new Error("No data found"));
      }
    }catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleRefresh = async () => {
    const authToken = localStorage.getItem("authToken"); // Fetch the auth token from local storage
  
    if (!userId) {
      setError(new Error("User ID not found"));
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    try {
      const response = await axios.post(
        `${config.apiDomain}/api/common/order_details`,
        {
          user_id: userId,
          uniqueorderid: uniqueorderid,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
  
      if (response.data && response.data.st === 1) {
        const orderDetails = response.data.data;
        setData([orderDetails]); // Set the order details data
        const errorMsg = response.data.msg || "Success";
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && response.data.st === 2) {
        const errorMsg = response.data.msg || "Warning";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else if (response.data && (response.data.st === 3 || response.data.st === 4)) {
        const errorMsg = response.data.msg || "Danger: Server Error";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      } else {
        const errorMsg = response.data.msg || "Failed to fetch data";
        setError(new Error(errorMsg));
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: toTitleCase(errorMsg),
          life: 3000,
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Check for 401 Unauthorized error
      if (error.response && error.response.status === 401) {
        console.error('Unauthorized access, redirecting to common login screen');
        navigate('/commonlogin'); // Navigate to common login screen on 401 error
      } else {
        const errorMsg = error.response ? error.response.data.msg || 'Failed to fetch data' : error.message || 'Failed to fetch data';
        setError(new Error(errorMsg));
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: errorMsg,
          life: 3000,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchOrderDetails();
  }, [uniqueorderid]); // Dependency array to re-fetch data when uniqueorderid changes
  
  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const rowClassName = (rowData, rowIndex) => {
    return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
  };

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };
  const renderTransactionType = (rowData) => {
    const style = {
      color: rowData.transactiontype === 'BUY' ? 'green' : 'orange'
    };
    return <span style={style}>{rowData.transactiontype}</span>;
  };

  const renderorderstatus = (rowData) => {
    const style = {
      color: rowData.orderstatus === 'complete' ? 'green' : 'orange'
    };
    return <span style={style}>{rowData.orderstatus}</span>;
  };
  const formatExpiryDate = (dateString) => {
    const monthMap = {
      JAN: 'Jan',
      FEB: 'Feb',
      MAR: 'Mar',
      APR: 'Apr',
      MAY: 'May',
      JUN: 'Jun',
      JUL: 'Jul',
      AUG: 'Aug',
      SEP: 'Sep',
      OCT: 'Oct',
      NOV: 'Nov',
      DEC: 'Dec'
    };

    const day = dateString.slice(0, 2);
    const monthAbbr = dateString.slice(2, 5).toUpperCase();
    const year = dateString.slice(5);

    const month = monthMap[monthAbbr];

    if (day && month && year) {
      return `${day} ${month} ${year}`;
    }
    return dateString;
  };

  const renderExpiryDate = (rowData) => {
    return formatExpiryDate(rowData.expirydate);
  };
  return (
    <>
     <AdminHeader></AdminHeader>
<AdminSubHeader></AdminSubHeader>
<Toast ref={toast} />
      <div className="container-xxl container-p-y">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/order_book" className="text-black">
                Order Book
              </Link>
            </li>
            <li className="breadcrumb-item active text-secondary" aria-current="page">
              Order Book Details
            </li>
          </ol>
        </nav>

        <div className="card p-5">
          <div className="d-flex justify-content-between align-items-center mb-5">
            <button
              onClick={handleBack}
              className="btn rounded-pill btn-outline-secondary btn-xs"
            >
              <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
            </button>

            <h5 className="mb-0 mx-auto">Order Book Details</h5>
            <div></div>
          </div>
          <div className="d-flex justify-content-start mb-3">
           
            <IconField iconPosition="left">
              <InputIcon className="ri ri-search-line"></InputIcon>
              <InputText
                type="search"
                placeholder="Search"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
                className="rounded"
              />
            </IconField>
            {loading ? (
                                   <i className=" custom-target-icon ri-loader-2-line ri-lg ms-3 mt-3 p-text-secondary"

               
              />
            ) : (
              <div className="mt-3">
                <Tooltip target=".custom-target-icon" />
                <i className="custom-target-icon ri ri-refresh-line ri-lg ms-3 p-text-secondary"
                   data-pr-tooltip="Reload"
                   data-pr-position="top"
                   
                   onClick={handleRefresh} // Adjust unique order ID dynamically if needed
                >
                </i>
              </div>
            )}
          </div>
          <DataTable
           
            className="custom-column-border text-center"

            value={data}
            paginator
            rows={20}
            showGridlines
            loading={loading}
            globalFilter={globalFilter}
            emptyMessage="No records found"
            rowClassName={rowClassName}
          >
            <Column align="center"               className="custom-column-border"
 field="variety" header="Variety" ></Column>
            <Column align="center"               className="custom-column-border"
 field="ordertype" header="Order Type"></Column>
            <Column align="center"               className="custom-column-border"
 field="producttype" header="Product Type"></Column>
            <Column align="center"               className="custom-column-border"
 field="quantity" header="Quantity"></Column>
            <Column align="center"               className="custom-column-border"
 field="lotsize" header="Lot Size"></Column>
            <Column align="center"               className="custom-column-border"
 field="tradingsymbol" header="Symbol"></Column>
            <Column align="center"               className="custom-column-border"
 field="transactiontype" header="Transaction Type"    body={renderTransactionType}></Column>
            <Column align="center"               className="custom-column-border"
 field="exchange" header="Exchange"></Column>
            <Column align="center"               className="custom-column-border"
 field="instrumenttype" header="Instrument Type"></Column>
            <Column align="center"               className="custom-column-border"
 field="strikeprice" header="Strike Price"></Column>
            <Column align="center"               className="custom-column-border"
 field="optiontype" header="Option Type"></Column>
            <Column align="center"               className="custom-column-border"
 field="expirydate" header="Expiry Date"body={renderExpiryDate}></Column>
            <Column align="center"               className="custom-column-border text-capitalize"
 field="orderstatus" header="Order Status"   body={renderorderstatus}></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>

      <Footer />
    </>
  );
};
export default AdminOrderBookDetails;
