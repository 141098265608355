import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link, useNavigate } from "react-router-dom";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import AdminHeader from "./AdminHeader";
import Footer from "../../../component/Footer";
import AdminSubHeader from "./AdminSubHeader";
import config from "../../../component/config";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import CardLinks from "../../../component/CardLinks";

const AdminFeatureRequest = () => {
  const [loading, setLoading] = useState(false);
  const [backClicked, setBackClicked] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [featureData, setFeatureData] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const toast = useRef(null);
const [error,setError]=useState(false);
  useEffect(() => {
    // Fetch feature requests on component mount
    const fetchFeatureRequests = async () => {
      setLoading(true);
      const authToken = localStorage.getItem("authToken");

      try {
        const response = await axios.get(`${config.apiDomain}/api/admin/feature_request/list`, {
          headers: {
            'Authorization': `Bearer ${authToken}`
          },
        });

        if (response.data.st === 1) {
          setFeatureData(response.data.data);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch data', life: 3000 });
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/commonlogin'); // Navigate to login page on 401 Unauthorized
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'An error occurred while submitting the request.' });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeatureRequests();
  }, []);

  const handleBack = () => {
    if (!backClicked) {
      setBackClicked(true);
      navigate(-1);
    }
  };

  const handleView = (id) => {
    navigate(`/admin/feature_request/Feature_view/${id}`);
  };

  const handleRefresh = async () => {
    setLoading(true);
    const authToken = localStorage.getItem("authToken");

    try {
      const response = await axios.get(`${config.apiDomain}/api/admin/feature_request/list`, {
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
      });

      if (response.data.st === 1) {
        setFeatureData(response.data.data);
        toast.current.show({ severity: 'success', summary: 'Success', detail: response.data.msg || 'Data fetched successfully!', life: 3000 });
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.msg || 'Failed to fetch data', life: 3000 });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/commonlogin'); // Navigate to login page on 401 Unauthorized
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: error.response?.data?.msg || 'An error occurred while submitting the request.', life: 3000 });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (rowData) => {
    try {
        const authToken = localStorage.getItem("authToken");

        if (!authToken) {
            throw new Error("Auth token not found");
        }

        const response = await fetch(
            `${config.apiDomain}/api/admin/feature_request/delete`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify({ feature_request_id: rowData.feature_request_id }),
            }
        );
        const responseData = await response.json();

        if (response.ok && responseData.st === 1) {
            // Update the state to remove the deleted feature request
            const updatedFeatureData = featureData.filter(
                (item) => item.feature_request_id !== rowData.feature_request_id
            );

            setFeatureData(updatedFeatureData); // Update the state with the new data

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Feature Request deleted successfully!',
                life: 3000,
            });
        } else {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: responseData.msg || 'Failed to delete feature request',
                life: 3000,
            });
        }
    } catch (error) {
        console.error("Error deleting feature request:", error);

        if (error.response && error.response.status === 401) {
            console.log("Unauthorized access detected. Redirecting to login...");
            navigate("/commonlogin");
        }

        toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: error.response?.data?.msg || 'Failed to delete feature request',
            life: 3000,
        });
    }
};


  const confirmDelete = (rowData) => {
    confirmDialog({
      message: 'Are you sure you want to delete this Feature Request?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-info',
      accept: () => handleDelete(rowData),
    });
  };
  return (
    <div>
      <AdminHeader />
      <AdminSubHeader />
      <div className="container-xxl container-p-y" align="center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb breadcrumb-style1 text-black">
            <li className="breadcrumb-item">
              <Link to="/admin/dashboard" className="text-black">
                <i className="ri-home-7-line ri-lg"></i>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-secondary"
              aria-current="page"
            >
              Feature Request
            </li>
          </ol>
        </nav>
        <div className="card p-5">
          <div className="row align-items-center mb-5">
            <div className="col-4 text-start">
              <button
                onClick={handleBack}
                className="btn rounded-pill btn-outline-secondary btn-xs"
              >
                <i className="ri-arrow-left-circle-fill me-1 ri-md"></i> Back
              </button>
            </div>
            <div className="col-4 text-center">
              <h5 className="mb-0">Feature Request</h5>
            </div>
            <div className="col-4"></div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="d-flex align-items-center">
              <IconField iconPosition="left">
                <InputIcon className="ri ri-search-line"></InputIcon>
                <InputText
                  type="search"
                  placeholder="Search"
                  value={globalFilter}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  className="rounded"
                />
              </IconField>
              {loading ? (
                <i className="custom-target-icon ri-loader-2-line ri-lg ms-3"></i>
              ) : (
                <div className="ms-3">
                  <Tooltip target=".custom-target-icon" />
                  <i
                    data-pr-tooltip="Reload"
                    data-pr-position="top"
                    className="ri ri-refresh-line ri-lg"
                    onClick={handleRefresh}
                  />
                </div>
              )}
            </div>
          </div>

          <DataTable
            value={featureData}
            rows={20}
            paginator
            showGridlines
            globalFilter={globalFilter}
            emptyMessage="No records found"
            className="p-datatable-rounded border border-1 text-center"
          >
            <Column
              field="name"
              align={"center"}
              header="Name"
              className="border border-1 text-capitalize"
            ></Column>

          
            <Column
              field="product"
              align={"center"}
              className="border border-1 text-capitalize"
              header="Product"
            ></Column>
            <Column
              field="feature"
              align={"left"}
              className="border border-1 text-capitalize"
              header="Feature"
            ></Column>
            <Column
              align={"center"}
              className="border border-1"
              header="Actions"
              body={(rowData) => (
                <>
                  <button
                    className="btn btn-info me-3 custom-btn-action1"
                    onClick={() => handleView(rowData.feature_request_id)}
                  >
                    <i className="ri-gallery-view-2 ri-lg"></i>
                  </button>

                  <button
                    className="btn btn-danger active text-align custom-btn-action1"
                    onClick={() => confirmDelete(rowData)}

                  >
                    <i className="ri-close-large-line ri-lg"></i>
                  </button>
                </>
              )}
            ></Column>
          </DataTable>
        </div>
        <CardLinks></CardLinks>
      </div>
      <Footer />
      <Toast ref={toast} />
      <ConfirmDialog />
    </div>
  );
};

export default AdminFeatureRequest;
